/*================================================================================
LINKS
================================================================================*/

a {
    color: $link;
    // text-decoration: none;
    transition: background 0.3s ease, color 0.3s ease, border 0.3s ease;

    &:hover,
    &:active,
    &:focus {
        color: $link-hover;
        // text-decoration: underline;
    }

    [class^='icon-'],
    [class*=' icon-'] {
        text-decoration: none;
    }
}
