.advice {
    background-color: #fcf7f2;
    padding-bottom: 0;
}

.advice__container {
    text-align: center;
}

.advice__title {
    @include font-size($h1-font-size);
    color: $theme-color-one;
    text-align: center;

    @include media-query(min, $desk-start) {
        padding-top: 0;
    }
}

.advice__sub-title {
    @include font-size($lede-font-size);
    margin-bottom: $base-spacing;
}

.advice__items {
    @include list-reset;
    margin-bottom: 0;

    @include media-query(min, $lap-start) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
}

.advice__item {
    width: 100%;
    margin-bottom: $base-spacing;
    text-align: center;
    border-radius: $base-radius * 2;
    background-color: $white;
    border: 2px solid $cream;
    overflow: hidden;

    @include media-query(min, $lap-start) {
        width: calc(33.33% - (#{$half-spacing} * 4 / 3));
        margin-right: $half-spacing;
        margin-bottom: 0;

        &:last-child {
            margin-right: 0;
        }
    }

    @include media-query(min, $desk-start) {
        width: calc(33.33% - (#{$base-spacing} * 4 / 3));
        margin-right: $base-spacing * 2;
    }
}

.advice__header {
    padding: $base-spacing;
    background-color: $theme-color-one;
}

.advice__header-title {
    margin-bottom: 0;
    color: $white;
    margin-top: $base-spacing;
}

.advice__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: $base-spacing * 4;
    height: $base-spacing * 4;
    border-radius: 100%;
    color: $blue-lt;
    background-color: $white;

    .svg-icon {
        width: 3em;
        height: 3em;
    }
}

.advice__body {
    padding: $base-spacing;
}

.advice__desc {
    margin-bottom: $half-spacing;
}

.advice__link {
    display: block;
    width: 100%;
    margin-bottom: $half-spacing / 2;

    .svg-icon {
        width: $half-spacing;
        height: $half-spacing;
    }
}
