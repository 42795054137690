// ----------------------
// Mini cart wrapper
// Use this for positioning only
// ----------------------
.minicart {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 7;
    padding: 0;

    > .container {
        padding: 0;
        z-index: 7;
    }

    .minicart--open & {
        opacity: 1;
        visibility: visible;

        @include media-query(min, $lap-start) {
            background: none;
            top: $base-spacing * 7;
            max-width: $page-width;
            left: 50%;
            transform: translateX(-50%);

            > .container {
                background: $white;
                width: 320px;
                border: 1px solid $silver-lt;
                position: absolute;
                //right: 30px;

                right: 38px;
                top: -80px;
            }
        }
    }
}
