// --------------------------
// Page seen in account areas
// --------------------------
.pager {
    @include clearfix;

    .toolbar-amount {
        width: 50%;
        float: left;
    }

    .limiter {
        width: 50%;
        float: right;
        text-align: right;
    }
}
