.item-confirmation {
    min-height: auto;
    padding: 0;
    border-radius: 6px;
    border: 3px $theme-color-one solid;
    overflow: hidden;

    .mfp-close {
        top: 8px;
        right: $half-spacing;
        color: $white;
        border-radius: 6px;
        opacity: 1;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: rgba($white, 0.2);
        }
    }
}

.item-confirmation__message {
    margin: 0;
    padding: $base-spacing;
    background-color: $theme-color-one;
    font-weight: bold;
    color: $white;

    .svg-icon {
        position: relative;
        top: -1px;
        width: 1.2em;
        height: 1.2em;
    }
}

.item-confirmation__content {
    padding: $base-spacing;
}

.item-confirmation__title {
    padding: 0;
    line-height: 1.3;
    color: $base-color;
}

.item-confirmation__qty {
    margin: 0;
}

.item-confirmation__actions {
    border-top: 1px solid $silver;
    padding: $base-spacing;

    @include media-query(min, $lap-start) {
        display: flex;
        justify-content: space-between;
    }

    .button {
        width: 100%;
        margin-left: 0;
        margin-bottom: $half-spacing;

        @include media-query(min, $lap-start) {
            width: auto;
            margin-bottom: 0;
        }
    }
}
.item-confirmation__options {
    margin: 0;
    list-style: none;
}
