/*========================================
BREADCRUMBS
========================================*/

.breadcrumbs {
  @include list-reset();
  @include font-size(14px);

  min-height: 21px;
  max-width: 1200px;
  margin: 0 auto;

  .items {
    margin: 0 0 5px 0;
  }

  li {
    display: inline-block;
    white-space: nowrap;

    &.item.product {
      display: none;
    }

    + li:before {
      content: '/';
      display: inline-block;
      width: 1em;
      text-align: center;
    }

    strong {
      font-weight: 100;
    }

    &:last-of-type {
      display: none;
    }
  }

  a {
    display: inline-block;
    text-decoration: none;
    color: $theme-color-one;

    @include hover() {
      text-decoration: underline;
    }
  }

  @include media-query(max, $lap-start) {
    li {
      //display: none;

      &:first-child,
      &:nth-last-child(2) {
        display: inline-block;
      }

      // + li {
      //     &:before {
      //         content: "/\00a0\2026\00a0/";
      //     }
      // }
    }
  }
}
