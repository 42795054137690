/*================================================================================
MAIN
================================================================================*/

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    @if $border-box == true {
        box-sizing: border-box;
    }
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 100%;
    font: #{($base-font-size/16px) * 100%}/#{$base-line-height} $base-font-family;
}

body {
    color: $base-color;
    text-rendering: optimizeLegibility;

    /* Conditional CSS http://adactio.com/journal/5429/*/
    &:after {
        display: none;

        @include media-query(palm) {
            content: 'palm';
        }
        @include media-query(lap) {
            content: 'lap';
        }
        @include media-query(desk) {
            content: 'desk';
        }
        @include media-query(wide) {
            content: 'wide';
        }
    }
}
