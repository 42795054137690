.block.filter {
  margin: -.5em -.5em 1em;
  user-select: none;
}

.filter-current {
  background: $cat-filter-current-bg;
  //margin-bottom: $half-spacing;
  border-radius: $double-radius;
  @include clearfix;
}

.filter-current-subtitle {
  float: left;
  width: 70%;
  color: $theme-color-three-dark;
  font-family: $sans-title;
  font-size: 1.1em;
  letter-spacing: 1px;
  padding: $half-spacing $base-spacing;
  outline: 0;
  display: block;

  @include media-query(min, $palm-start) {
    font-size: 1.3em;
  }
}


.filter-current.active + .filter-actions {
  display: none;
}

.filter-actions {
  //@include clearfix();
  padding: $half-spacing * 1.5 0;
  padding-right: $base-spacing;
  //width: 30%;
  //float: right;
  text-align: right;
}

.filter-clear {
  color: $white;
  @include font-size(14px);
  border-radius: $base-spacing;
  background: $cat-filter-clear-bg;
  text-decoration: none;
  display: inline-block;
  padding: 2px 10px;
  font-weight: bold;
  font-family: $sans-title;
  box-shadow: 0 1px 6px #ccc;

  &:hover,
  &:focus {
    color: $white;
    background: $cat-filter-clear-hover-bg;
  }

  &:active {
    color: $white;
    background: $cat-filter-clear-active-bg;
  }
}

.filter-items {
  list-style: none;
  margin: 0;
  padding: $base-spacing;
  //float: left;
  width: 100%;

  border-top: 1px solid #317369;
  border-bottom: 1px solid #317369;

  display: flex;
  flex-flow: row wrap;
  gap: 0.5em;

  border-top: 1px solid $cat-filter-border-color;
  border-bottom: 1px solid $cat-filter-border-color;

  .item {
    color: $cat-selected-filter-text-color;
    gap: 0.5em;
    align-items: center;
    padding: .25em .75em;
    background: white;
    border-radius: 1em;
    box-shadow: 0 1px 6px #ccc;
    //background: $white;
    //border: 2px solid $cat-filter-border-color;
    //margin: 6px 0;
    //padding: $half-spacing/2 $base-spacing/2;
    //border-radius: $base-spacing;
    //position: relative;

    display: flex;
    justify-content: flex-start;

    .filter-label,
    .filter-value {
      display: block;
    }

    .filter-label {
      font-weight: bold;
    }
  }

  .remove {
    display: block;
    color: $white;
    background: $theme-color-one;
    text-decoration: none;
    padding: 1px $half-spacing/2;
    border-radius: $base-spacing * 2;
    //position: absolute;
    //top: 35%;
    //right: $half-spacing;
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-left: auto;

    background: $theme-color-three-dark url(../img/svg/ic_clear.svg) no-repeat center center;
    background-size: 15px 15px;

    &:hover,
    &:focus {
      background: lighten($theme-color-one, 20%) url(../img/svg/ic_clear.svg) no-repeat center center;
      background-size: 15px 15px;
    }

    &:active {
      background: lighten($theme-color-one, 50%) url(../img/svg/ic_clear.svg) no-repeat center center;
      background-size: 15px 15px;
    }

    > span {
      display: none;
    }
  }
}

.filter-wrapper {
  margin-right: $base-spacing;
  padding: $base-spacing;
  background: lighten($theme-color-two, 25%);
  border-radius: $half-spacing;
}

.filter-title {
  display: none !important;
}

.filter-options-title {
  background: $cat-filter-bg;
  color: $cat-filter-title-color;
  font-family: $sans-title;
  font-weight: bold;
  padding: $half-spacing $base-spacing;
  border-top: 1px solid $cat-filter-border-color;
  cursor: pointer;
  position: relative;
  user-select: none;

  .filter-options-item.active & {
    &:after {
      transform: scaleY(-1);
    }
  }

  &:after {
    content: ' ';
    width: 20px;
    height: 20px;
    position: absolute;
    top: $half-spacing + 4px;
    right: $half-spacing;
    background-color: $theme-color-one;
    -webkit-mask-image: url(../img/svg/ic_expand_more.svg);
    mask-image: url(../img/svg/ic_expand_more.svg);
  }

  &:focus {
    outline: 0;
  }
}

.filter-options-item {
  .item label {
    font-weight: normal;
  }

  &:last-child {
    &.active {
      .filter-options-title {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .filter-options-title {
      border-bottom-right-radius: $double-radius;
      border-bottom-left-radius: $double-radius;
    }
  }

  input[type='checkbox'] {
    display: none;
  }
}

.filter-subtitle {
  @include block-collapsible-nav-title;
  color: $theme-color-three-dark;
  font-size: 1.1em;

  &:after {
    top: 14px;
    right: 14px;
  }

  @include media-query(min, $palm-start) {
    font-size: 1.3em;
  }

  @include media-query(min, $desk-start) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    cursor: default;

    &:after {
      display: none;
    }
  }
}

.filter-options-content {
  @include block-collapsible-nav-content;

  .swatch__border {
    position: relative;
    top: 2px;
    height: 16px;
    width: 16px;
    background: $grey;
    display: inline-block;
    border-radius: 2px;
  }

  .item__swatch {
    position: absolute;
    height: 14px;
    width: 14px;
    border: 1px solid $white;
    top: 1px;
    left: 1px;
  }

  .item {
    a:not(.info-tool-tip) {
      color: $theme-color-three;
    }

    .info-tool-tip {
      margin-left: $half-spacing/2;
    }

    > a:not(.info-tool-tip) {
      padding: 2px 0 2px 22px;
      display: inline-block;
      text-decoration: none;
      position: relative;

      input {
        position: absolute;
        width: 16px;
        height: 16px;
        background: $white;
        top: 6px;
        left: 0;
        display: inline-block;
        border-radius: 2px;
        background: #fff;
        border: 1px solid $cat-filter-border-color;
        box-shadow: inset 0 0 0 1px #fff;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:checked {
          background: $theme-color-one;
        }
      }

      label {
        margin-bottom: 0;
      }

      &:hover {
        input {
          background: $theme-color-one;
        }
      }

      > span.count {
        &:before {
          content: '(';
        }

        &:after {
          content: ')';
        }
      }
    }
  }
}

.filter_nav_colour .filter-options-content .item > a:not(.info-tool-tip) {
  display: flex;
  align-items: center;

  label {
    margin-right: 5px;
  }

  .filter-colour-swatch {
    border-radius: 100%;
    max-height: 16px;
    max-width: 16px;
    margin-right: 5px;
  }
}

.page-layout-1column {
  .filter-options {

    @include media-query(min, $desk-start) {
      display: none;
    }

    &.active {

      @media (min-width: $lap-start) {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .filter-options-title {
          cursor: default;

          &::after {
            display: none !important;
          }
        }

        .filter-options-content[aria-hidden='true'] {
          display: block !important;
        }
      }

      @include media-query(min, 800px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @include media-query(min, 1000px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }

  .block-subtitle.filter-subtitle {
    cursor: pointer;

    &::after {
      display: block;
    }
  }
}

.filter-options {
  display: none;

  @include media-query(min, $desk-start) {
    display: block;
  }

  &.active {
    display: block;

    @media (min-width: $lap-start) and (max-width: $desk-start) {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .filter-options-title {
        cursor: default;

        &::after {
          display: none;
        }
      }

      .filter-options-content[aria-hidden='true'] {
        display: block !important;
      }
    }

    @include media-query(min, 800px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.filter-info-block {
  margin-top: $half-spacing;
  border-top: 1px solid $cat-filter-border-color;
  padding-top: $base-spacing;
}

.filter-info {
  @include font-size(14px);
  margin-bottom: 0;
}

// search filter
.search-filter {
  padding-right: 20px;

  .filter {
    border-radius: $base-radius;
    background-color: $cream;
    padding: $base-spacing;
  }

  .items {
    list-style: none;
    margin: 0;
  }

  .item {
    padding: 2px 0;
    font-size: 0.9em;
  }

  .subtitle, .title {
    color: $cat-filter-title-color;
    font-family: $heading-font-family;
    font-size: 1.5em;
    letter-spacing: 1px;
  }
}

.block-subtitle.filter-subtitle {
  &:hover {
    color: lighten($theme-color-one, 20%);
  }

  @include media-query(max, $desk-start) {
    cursor: pointer;
  }
}

.block-content.filter-content {
  background: $theme-color-three-light;
  border-radius: 0.75em;
  overflow: hidden;

  .block-actions.filter-actions {
    display: none;
  }
}
