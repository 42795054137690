/*================================================================================
Product Page
================================================================================*/

.catalog-product-view {

  .columns {
    padding: 10px;
  }

  .column.main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-basis: 100%;
    flex-grow: 1;
    order: 1;
    @include clearfix;

    @include media-query(min, $lap-start) {
      flex-direction: row;
    }
  }

  .product.media {
    order: 2;
    position: relative;
    margin-bottom: $base-spacing;
    width: 100%;

    @include media-query(min, $lap-start) {
      order: 1;
      width: 45%;
      //float: left;
    }

    /*@include media-query(min, $desk-start) {
      width: 32%;
    }*/
  }

  .product-info-wrapper {
    display: contents;

    @include media-query(min, $lap-start) {
      order: 2;
      display: initial;
      width: 55%;
    }
  }

  .product-info-top {
    order: 1;

    @include media-query(min, $lap-start) {
      padding: 0 $base-spacing;
    }

    .page-title-wrapper.product {
      padding: 0;

      .page-title {
        color: #07436b;
        font-size: 1.25em;
        padding: 0;
        margin: 0.5em 0;

        @include media-query(min, $lap-start) {
          font-size: 1.35em;
        }

        @include media-query(min, $desk-start) {
          font-size: 1.5em;
        }
      }
    }
  }

  .product-info-main {
    order: 3;

    .breadcrumbs {
      display: none;
    }

    @include media-query(min, $lap-start) {
      padding: 0 $base-spacing;
      //float: right;
    }

    /*@include media-query(min, $desk-start) {
      width: 68%;
    }*/

    .price-box {
      line-height: 1em;
    }
  }

  .block.related {
    width: 100%;
    float: right;
  }

  .block.upsell {
    width: 100%;
    float: right;
  }

  .popup-authentication {
    display: none;
  }

  .messages {
    .message {
      margin-left: 0;
      margin-right: 0;

      &:first-child {
        margin-top: $base-spacing;
        @include media-query(min, $desk-start) {
          margin-top: $base-spacing * 1.5;
        }
      }
    }
  }

  .product-info-main > .stock,
  .configurable-variation-qty {
    display: none !important;
  }
}

/*========================================
Gallery
========================================*/

.product.media {
}

/*========================================
Summary
========================================*/

.product-summary {
  margin-bottom: $half-spacing;
}

/*========================================
Info
========================================*/
.product-info-main {
  .product-cms {
    padding-bottom: $base-spacing;
    margin-bottom: $half-spacing;

    ul {
      @include list-reset;
    }

    li {
      @include font-size(20px);
      position: relative;
      margin-bottom: $half-spacing;

      &:before {
        content: ' ';
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: $product-short-desc-icon-color url(../img/svg/ic_done.svg) no-repeat center center;
        background-size: 90%;
        display: inline-block;
        margin-right: $half-spacing;
        vertical-align: top;
        position: relative;
        top: 7px;
      }
    }
  }
}

.product-info-price {
  @include font-size(24px);
  padding-top: $half-spacing;
  padding-bottom: $half-spacing;

  @include media-query(min, $lap-start) {
    padding-top: $base-spacing;
    //padding-bottom: $base-spacing;
  }

  .price-final_price .price {
    font-size: 1em;
  }

  .price-box {
    display: flex;
    justify-content: left;

    > * {
      margin-right: $half-spacing;
    }
  }
}

.page-product-configurable {
  .product-add-form {
    margin-bottom: $base-spacing;
  }

  .product-add-form.retail form:not(.product-add-form--oos) {
    position: relative;
    min-height: 100px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: $white;
    }

    &:after {
      content: 'Loading Options';
      position: absolute;
      top: 0;
      height: $base-spacing;
      z-index: 2;
      padding-left: $base-spacing + ($half-spacing / 2);
      background: url(../img/opc-ajax-loader.gif) no-repeat left center;
      background-size: $base-spacing;
      line-height: $base-spacing;
    }
  }

  .product-add-form--loaded form {
    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }
}

.product-options-wrapper {
  .fieldset {
    outline: 0;
  }
}

.product-options-bottom {
  margin-bottom: $base-spacing;
  @include media-query(min, $lap-start) {
    margin-bottom: 0;
  }
}

.product-info-stock {
  @include font-size(14px);

  p {
    margin-bottom: 0;
  }

  .svg-icon {
    color: $theme-color-one;
    @include font-size(18px);
    position: relative;
    top: -2px;
  }
}

.product-info-extra {
  @include font-size(14px);

  p {
    margin-bottom: 0;
  }
}

@include media-query(max, $lap-start) {
  .product-info-main {
    margin-bottom: 0.25em;
  }
}

.product.info.detailed {
  clear: both;
  margin-bottom: $base-spacing;
  border-bottom: 2px solid $pale-blue;

  .item.title {
    color: $blue;
    font-weight: bold;

    &:focus {
      outline: 0;
    }

    .switch {
      position: relative;
      border: 2px solid $pale-blue;
      border-bottom: 0;
      text-decoration: none;
      @include font-size(20px);
      background: lighten($pale-blue, 2%);
      padding: 0.5em;

      display: flex;
      flex-direction: row;
      gap: 0.2em;
      justify-content: center;
      align-items: center;

      &:focus {
        outline: 0;
      }
    }

    &.active {
      .switch {
        background: $white;
      }
    }
  }

  .item.content {
    display: none;
  }
}

/*========================================
Options
========================================*/

.product-options {
  border: 1px solid $silver;
  border-bottom: 0;
  padding: $half-spacing;
}

/*========================================
Actions
========================================*/

.product-actions {
  margin-bottom: $half-spacing;
  border: 1px solid $silver;
  padding: $half-spacing;

  .price-box {
    display: none;
  }
}

.add-to-cart {
}

.add-to {
}

.field.qty {
  .control {
    display: flex;
  }

  position: relative;
  display: flex;

  .label {
    position: absolute;
    top: -$base-spacing * 1.5;
    padding-right: 5px;
  }

  .input-text {
    border-radius: 0;
    height: 35px;
    border: 0;
    border-top: 1px solid $grey-lt;
    border-bottom: 1px solid $grey-lt;
    text-align: center;
    width: 35px;
    @include font-size(16px);
    line-height: 1;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .button--qty {
    height: 35px;
    width: 35px;
    background: $white;
    border-radius: 0;
    border: 1px solid $grey-lt;
    color: $black;
    padding: 0;
    position: relative;

    &:first-child {
      border-top-left-radius: $base-radius;
      border-bottom-left-radius: $base-radius;
    }

    &:last-child {
      border-top-right-radius: $base-radius;
      border-bottom-right-radius: $base-radius;
    }

    .svg-icon {
      @include font-size(26px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.box-tocart {
  padding-top: $base-spacing * 1.5;

  .fieldset {
    @include clearfix;

    .field.qty {
      margin-bottom: $base-spacing;
    }
  }

  .actions {
    .button {
      height: 42px;
      margin-bottom: $half-spacing;
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      &.prescription {
        display: $product-prescription-btn-display;
        background-color: white;
      }
    }
  }

  @include media-query(min, $desk-start) {
    .field.qty {
      float: left;
      margin-right: $half-spacing;
      margin-bottom: 0;
    }

    .actions {
      float: left;
      @include clearfix;

      > * {
        float: left;
      }

      .button {
        padding-left: $base-spacing * 2;
        padding-right: $base-spacing * 2;
        width: auto;
        margin-bottom: 0;
      }

      .button--subtle {
        margin-left: $half-spacing;
        padding-left: $base-spacing;
        padding-right: $base-spacing;
      }
    }
  }
}

.sharing-links {
  li {
    display: inline-block;
  }
}

.product-social-links {
  margin-bottom: $base-spacing;
  @include media-query(min, $lap-start) {
    margin-bottom: $base-spacing * 2;
  }

  .action {
    text-decoration: none;
    margin-right: $half-spacing;

    span {
      text-decoration: underline;
    }
  }
}

/*========================================
Related
========================================*/

.product-related {
}

/*========================================
Related
========================================*/

.product-upsell {
}

/*========================================
Bullets
========================================*/

.product-bullets {
}

/*========================================
Delivery
========================================*/
.product-delivery-tick {
}

.catalog-product-view {
  .banner-item {
    margin-right: $half-spacing;
    margin-bottom: $half-spacing;
  }

  .box-tocart {
    padding-top: $base-spacing * 1.5;

    .fieldset {
      @include clearfix;

      .field.qty {
        margin-bottom: $base-spacing;
      }
    }

    .actions {
      .button {
        height: 42px;
        margin-bottom: $half-spacing;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }

    @include media-query(min, $desk-start) {
      .field.qty {
        float: left;
        margin-right: $half-spacing;
        margin-bottom: 0;

        .control {
          display: flex;
        }

        position: relative;

        .label {
          position: absolute;
          top: -$base-spacing * 1.5;
        }

        .input-text {
          border-radius: 0;
          height: 35px;
          border: 0;
          border-top: 1px solid $grey-lt;
          border-bottom: 1px solid $grey-lt;
          text-align: center;
          width: 60px;
          @include font-size(18px);

          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .button--qty {
          height: 35px;
          width: 35px;
          background: $white;
          border-radius: 0;
          border: 1px solid $grey-lt;
          color: $black;
          padding: 0;
          position: relative;

          &:first-child {
            border-top-left-radius: $base-radius;
            border-bottom-left-radius: $base-radius;
          }

          &:last-child {
            border-top-right-radius: $base-radius;
            border-bottom-right-radius: $base-radius;
          }

          .svg-icon {
            @include font-size(26px);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
        }
      }

      .actions {
        float: left;
        @include clearfix;

        > * {
          float: left;
        }

        .button {
          padding-left: $base-spacing * 2;
          padding-right: $base-spacing * 2;
          width: auto;
          margin-bottom: 0;
        }

        .button--subtle {
          margin-left: $half-spacing;
          padding-left: $base-spacing;
          padding-right: $base-spacing;
        }
      }
    }
  }
}

#prescription-popup {
  line-height: 1.2;

  hr {
    margin-bottom: 10px;
  }

  .flex-wrapper {
    display: flex;
    align-items: center;

    span.or {
      font-weight: bold;
      color: white;
      background: gold;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin: 0 1em;
    }
  }

  .address {
    margin-left: 0.5em;
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    flex-shrink: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .link {
    color: #2b73c1;
    font-weight: bold;
    text-decoration: underline;
  }

  .link-block {
    display: flex;
    flex-direction: row;
    align-items: center;

    h3 {
      margin: 0 0 5px 0;
      padding: 0;
    }

    .link {
      display: flex;
      flex-shrink: 0;
    }
  }
}

.product.info.detailed {
  .product.attribute {
    &.description {
      border-left: 2px solid $pale-blue;
      border-right: 2px solid $pale-blue;
    }

    .content.inner {
      padding: $base-spacing / 2;

      @include media-query(min, $lap-start) {
        padding: $base-spacing;
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.product.attribute.description {
  width: 100%;
}

.product.data.items {
  display: grid;
  grid-template-columns: 1fr 1fr;

  border-radius: 0.25em;
  overflow: hidden;

  .data.item {

    &.title.active {

    }

    &.content {
      grid-column: span 2;

      &.active {
        display: flex;
      }
    }
  }

  @include media-query(min, $lap-start) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0 0.5em;

    .data.item {
      &.title .switch {
        border-radius: .35em .35em 0 0;
      }

      &.content {
        grid-column: span 4;
      }
    }
  }
}

@include media-query(min, $desk-start) {
  .catalog-product-view .product.description {
    .compression-info {
      margin-bottom: $base-spacing;
    }
  }
}

.data.item.content:not(#description, #reviews) {
  .product.attribute.description {
    padding: $base-spacing / 2;

    @include media-query(min, $lap-start) {
      padding: $base-spacing;
    }
  }
}

#tab-label-description {
  order: 1;
}

#tab-label-reviews {
  order: 2;
}

#tab-label-size-guide {
  order: 3;
}

#tab-label-delivery {
  order: 4;
}

#description {
  order: 5;
}

#reviews {
  order: 6;
}

#size-guide {
  order: 7;
}

#delivery {
  order: 8;
}

.product-social-links {
  display: none;
}
