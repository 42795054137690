// ------------------------------------
// Styles that affect the product view,
// but only on the category page
// ------------------------------------

.products {
  padding-bottom: 0;

  .product-items {
    @include list-reset;
  }

  .product-item {
    @include clearfix;
    width: 100%;
    padding-bottom: $base-spacing;
    margin-bottom: $base-spacing;
    border-bottom: 2px solid $sky-lt;
    position: relative;

    @include media-query(min, $lap-start) {
      padding-bottom: $base-spacing * 1.5;
      margin-bottom: $base-spacing * 1.5;
    }

    @include media-query(min, $desk-start) {
      padding-top: $half-spacing;
    }
  }

  .product-item-info {
    @include clearfix;
    position: relative;
  }

  .product-item-photo {
    position: relative;
    display: block;
    @include clearfix;
    margin-bottom: $base-spacing;
  }

  .product-item-details {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;

    .price-wrapper {
      display: flex;
      justify-content: space-between;

      .product-reviews-summary {
        order: 2;
      }

      .price-box {
        order: 1;
        margin-bottom: 0;
      }
    }

    .product-item-link {
      //order: 1;
    }

    .product-flag {
      //order: 2;
      align-self: flex-start;
      margin-bottom: $half-spacing;
    }

    .product-image-container {
      margin-bottom: 0;

      img {
        border: none;
      }
    }
  }

  .product-item-name {
    padding-top: 0;
  }

  .product-item-name,
  .product-bullets,
  .product-cms {
    margin-bottom: $half-spacing/2;
  }

  .banner-item {
    p {
      font-size: $mono-font-size;
      line-height: 1em;
      margin: 5px 0;
    }

    .banner-item__icon {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
  }

  .info-tool-top {
    @include font-size(10px);
    color: $white;
    background: $blue;
    text-decoration: none;
    padding: $half-spacing/2 $base-spacing/2;
    border-radius: 50px;
  }

  .product-image-container {
    display: block;
    width: 100%;
    float: left;
  }
}

.product-cms {
  @include font-size($mono-font-size);

  @include media-query(min, $lap-start) {
    @include font-size($base-font-size);
  }

  ul,
  p {
    list-style: disc;
    margin-bottom: 0;
  }
}

.price-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  position: relative;
  //padding-top: ($half-spacing/2);
  //order: 5;

  > .special-price,
  > .old-price {
    margin-right: $half-spacing;
  }

  .price-label {
    display: none;
  }

  .special-price .price {
    color: #d73232;
  }

  .old-price .price {
    text-decoration: line-through;
    font-family: $sans;
    font-weight: 100;
  }

  .save-price .price {
    display: none;
    color: darkgreen;
    font-family: $sans;
    font-weight: 100;
  }

  @include media-query(min, $lap-start) {
    .minimal-price-link {
      @include font-size(14px);
      display: block;
    }
  }
}

.product-reviews-summary {
  margin-bottom: 0;
}

//=================================
// List view
//=================================

.products-grid {
  .product-items {
    @include media-query(min, 600px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .product-item {
    @include media-query(min, 600px) {
      @include flex-gutters($base-spacing, 2, $desk-start);
    }

    @include media-query(min, $desk-start) {
      @include flex-gutters(($base-spacing * 2), 2, $page-width);
    }

    @include media-query(min, $page-width) {
      @include flex-gutters(($base-spacing * 2), 3);
    }
  }
}

.products-list {

  .product-item:last-of-type {
    margin-bottom: $base-spacing * 1.5;
  }

  @include media-query(min, $lap-start) {
    .product-image-container {
      margin-bottom: $base-spacing;
    }

    .product-item-photo {
      width: 40%;
      float: left;
      margin-bottom: 0;
    }

    .product-item-details {
      width: 60%;
      float: right;
      padding-left: $base-spacing;
    }

    .product-image-container {
      width: 100%;
    }

    .swatches {
      float: none;
      position: inherit;

      .swatches__swatch {
        width: auto;
        height: auto;
      }
    }
  }

  @include media-query(min, $desk-start) {
    .product-item-photo {
      width: 18%;
    }

    .product-item-details {
      width: 82%;
      padding-right: 120px;
    }

    .product-image-container {
      margin-bottom: $half-spacing;
    }

    .price-box {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      display: block;
      line-height: 1.3;

      .price {
        font-size: 1.2em;
      }

      .old-price {
        margin-right: 0;
        margin-bottom: 10px;

        .price-wrapper {
          display: block;
        }
      }

      .special-price {
        display: block;
        margin-right: 0;
        margin-bottom: 10px;
      }

      .old-price,
      .save-price {
        .price {
          font-size: 1.1em;
          line-height: 0.9em;
        }
      }
    }
  }
}

.catalog-category-view {
  &.page-layout-1column,
  &.page-layout-subcategories {
    .columns .sidebar.sidebar-main {
      display: none;
    }
  }
}

.compression-iconography {
  display: flex;
  justify-content: space-between;
  gap: 0.5em;

  @include media-query(max, $palm-start) {
    .mmhg svg {
      margin-top: -5px;
    }
  }

  @include media-query-bracket(600px, 750px){
    flex-direction: column;
  }

  @include media-query(min, $desk-start) {
    .mmhg svg {
      margin-top: -2px;
    }
  }
}
