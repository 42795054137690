// Font stacks
$sans-title: 'Museo Sans Rounded', Helvetica, Arial, sans-serif;
$sans: 'Proxima Nova Soft', Helvetica, Arial, sans-serif;

$serif: Georgia, serif;
$mono: Consolas, Monaco, monospace;

@font-face {
    font-family: 'Museo Sans Rounded';
    src: url('/static/frontend/Daylong/default/en_GB/assets/fonts/museo/MuseoSansRounded-900.woff2')
        format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Soft';
    src: url('/static/frontend/Daylong/default/en_GB/assets/fonts/proxima/Proxima-Nova-Regular.woff2')
        format('woff2');
    font-weight: normal;
    font-style: normal;
}

// Variables
@import 'vars';

// colors
$theme-color-one-dark: darken(#1a4b78, 10%);
$theme-color-one: #1a4b78;
$theme-color-one-light: #edf6f9;

$theme-color-two-dark: darken(#175bb4, 10%);
$theme-color-two: #175bb4;
$theme-color-two-light: #bfdbff;

$theme-color-three-dark: darken(#317369, 5%);
$theme-color-three: #317369;
$theme-color-three-light: #EBFAF5;

$theme-color-four-dark: darken(#5c507f, 10%);
$theme-color-four: #5c507f;
$theme-color-four-light: #f8f7fd;

// vars
$heading-color: $theme-color-one;
$button-radius: 20px;

// magento/minicart
$minicart-items-display: none;

// theme/page-top
$page-top-bg: lighten($theme-color-two-light, 58%);
$page-top-link-color: $theme-color-two-light;

// theme/site-nav
$nav-wrapper-bg: $theme-color-one;
$nav-link-bg: $theme-color-one;
$nav-link-hover-bg: lighten($theme-color-one, 10%);
$nav-link-active-bg: white;
$nav-link-active-text: $theme-color-one;
$nav-link-text-color: white;

$subnav-wrapper-bg: white;
$subnav-link-bg: none;
$subnav-link-text-color: darken($theme-color-two, 10%);
$subnav-link-hover-bg: lighten($theme-color-two, 20%);
$subnav-link-active-bg: lighten(black, 20%);

// theme/product-banner
$product-icon-bar-bg: lighten($theme-color-one, 45%);

// theme/page-hero
$page-hero-bg: $theme-color-one-light;
$page-hero-spacing: $base-spacing * 2;
$page-hero-font-size: 20px;

// theme/product-page-hero
$product-page-hero-bg: $theme-color-one-light;
$product-page-hero-spacing: $double-spacing;

// theme/page-product
$product-prescription-btn-display: inline-block;
$product-short-desc-icon-color: #1a1a1a;

// theme/gallery
$gallery-bg: lighten($theme-color-two-light, 58%);
$gallery-selected-border-color: $theme-color-two;
$gallery-video-btn-bg: $theme-color-two-light;

// theme/site-usp
$usp-text-color: $theme-color-two-light;
$usp-bg: white;

// theme/tile
$tile-border-color: $cream;

// theme/skip
$skip-svg-color: $theme-color-one;
$skip-icon-badge-bg: $theme-color-one;
$skip-text-color: $theme-color-one;

// theme/related-products
$related-bg: $theme-color-four-light;
$related-border-color: $theme-color-four;
$related-title-color: darken($theme-color-four, 5%);

// theme/site-footer
$footer-bg: $theme-color-one;
$footer-text-color: white;
$footer-border-color: lighten($theme-color-one, 10%);
$footer-copyright-text-color: white;
$footer-bar-bg: darken($theme-color-one, 5%);

// theme/category-filters
$cat-filter-title-color: darken($theme-color-three, 5%);
$cat-filter-bg: $theme-color-three-light;
$cat-filter-border-color: $theme-color-three;
$cat-filter-current-bg: $theme-color-three-light;
$cat-selected-filter-text-color: $theme-color-three;

$cat-filter-clear-bg: $theme-color-three;
$cat-filter-clear-hover-bg: lighten($theme-color-one, 20%);
$cat-filter-clear-active-bg: $theme-color-three;

// modules/mixins
$mixin-block-title-color: $theme-color-three;
$block-collapsible-nav-title-color: $theme-color-three;
$block-collapsible-nav-title-bg: $theme-color-three-light;
$block-collapsible-nav-content-bg: $theme-color-three-light;

// theme/page-basket
$basket-summary-bg: $theme-color-one-light;

// objects/price
$price-saved-color: $theme-color-one;

// object/buttons
$search-btn-border-color: darken($theme-color-two, 10%);
$search-btn-bg: $theme-color-two;
$search-btn-txt-color: white;
$search-btn-hover-border-color: darken($theme-color-two, 10%);
$search-btn-hover-bg: darken($theme-color-two, 10%);

@import '../../../node_modules/normalize.css/normalize.css';

// Modules
@import 'modules/mixins';
@import 'modules/reset';
@import 'modules/helpers';

// Reset and reboot
@import 'base/global';
@import 'base/links';
@import 'base/sectioning';
@import 'base/grouping';
@import 'base/headings';
@import 'base/paragraphs';
@import 'base/quotes';
@import 'base/lists';
@import 'base/media';
@import 'base/inline';
@import 'base/preformatted';
@import 'base/tables';
@import 'base/forms';

// Generic objects
@import 'objects/buttons';
@import 'objects/flyout';
@import 'objects/grid';
@import 'objects/icons';
// @import "objects/label";
@import 'objects/media';
// @import "objects/modal";
@import 'objects/nav';
// @import "objects/notice";
// @import "objects/panel";
// @import "objects/stats";
// @import "objects/tooltip";
@import 'objects/promo-icon';
@import 'objects/company-links';

// Magento objects
@import 'objects/cookies';
@import 'objects/messages';
@import 'objects/breadcrumbs';
@import 'objects/price';
@import 'objects/ratings';
@import 'objects/availability';
@import 'objects/toolbar';
@import 'objects/pager';
@import 'objects/viewer';
@import 'objects/listing';
@import 'objects/swatches';
@import 'objects/product';

// Layout and pages
@import 'theme/page-top';
@import 'theme/site-header';
@import 'theme/site-minicart';
@import 'theme/site-nav';
@import 'theme/user-nav';
@import 'theme/site-search';
@import 'theme/site-usp';
@import 'theme/skip';

// Magento 2.1.0 CSS - HACK/REMOVE as you need to, here to provide a base only.
@import 'magento/minicart';
@import 'theme/magento-minicart';

@import 'magento/_modals.scss';
@import 'magento/_braintree.scss';

@import 'vendor/flickity';
@import 'theme/cookies';
@import 'theme/gallery';
@import 'theme/photoswipe';
@import 'theme/site-footer';
@import 'theme/layout';
@import 'theme/accordion';
@import 'theme/popups';
@import 'theme/misc';
@import 'theme/sprites';
@import 'theme/page-product';
@import 'theme/page-category';
@import 'theme/page-basket';
@import 'theme/page-send-to-a-friend';
//@import "theme/page-wishlist";
//@import "theme/page-magento-wishlist";
@import 'theme/page-checkout';
@import 'theme/related-products';
@import 'theme/product-banner';
@import 'theme/info-banner';
@import '../../../node_modules/magnific-popup/src/css/main.scss';
@import 'theme/popups';
@import 'theme/prescription-popup';
@import 'theme/site-support';
@import 'theme/page-hero';
@import 'theme/reviews';
@import 'theme/review-items';
@import 'theme/category-filter';
@import 'theme/advice';
@import 'theme/social';
@import 'theme/tile';
@import 'theme/featured-tile';
@import 'theme/shop-by';
@import 'theme/prescription';
@import 'theme/hero';
@import 'theme/downloads';
@import 'theme/account-layout';
@import 'theme/account-login';
@import 'theme/account-register';
@import 'theme/account-orders';
@import 'theme/wp-featured-articles';
@import 'theme/item-confirmation';
@import 'theme/product-tile';
@import 'theme/remember-me';
@import 'theme/grid-view';
@import 'theme/product-page-hero';

@import 'vendor/tablesaw';

[hl] {
    outline: 1px solid red;
}

[hl2] {
    outline: 1px solid green;
}

[hl3] {
    outline: 1px solid blue;
}

#__bs_notify__ {
    top: auto !important;
    bottom: 0 !important;
    padding: 2em !important;
}
