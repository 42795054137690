/*========================================
LISTS
========================================*/

// Bullets and numbers

ul,
ol {
    margin-left: $base-spacing;

    ul,
    ol {
        margin-bottom: 0;
    }

    &.unstyled {
        margin-left: 0;
        list-style: none;

        ul,
        ol {
            margin-left: 0;
            list-style: none;
        }
    }

    &.inline {
        margin: 0;
        padding: 0;
        list-style: none;

        ul,
        ol {
            display: inline-block;
            margin: 0;
            list-style: none;
        }

        li {
            display: inline-block;
        }
    }
}

ul {
    list-style: disc;

    ul {
        list-style: circle;
    }
}

ol {
    list-style: decimal;

    ol {
        list-style: lower-alpha;
    }
}

// Definition lists

dl {
    &.aligned {
        @include clearfix();

        dt {
            clear: left;
            float: left;
            margin-right: $half-spacing/2;

            &:after {
                content: ': ';
            }
        }

        dd {
            + dt {
                padding-top: 0;
            }
        }
    }
}

dt {
    font-weight: bold;
}

dd + dt {
    padding-top: $half-spacing;
}

// Lists in navigation

nav {
    ul {
        margin: 0;
        list-style: none;
    }
}

dt {
    &:after {
        content: ': ';
    }
    font-weight: bold;
}

dt,
dd {
    display: inline;
    margin: 0;
}

dd {
    font-weight: normal;
}

dd + dt::before {
    content: '\A';
    white-space: pre;
}

dd + dd::before {
    content: ', ';
    font-weight: normal;
    margin-left: -0.25em;
}

// Styled lists for CMS content

// .cms ul,
// .ul {
//     margin-left: $base-spacing;
//     list-style: disc;

//     ul {
//         list-style: circle;
//     }
// }

// .cms ol,
// .ol {
//     margin-left: $base-spacing;
//     list-style: decimal;

//     ol {
//         list-style: lower-alpha;
//     }
// }
