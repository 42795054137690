/*========================================
TABLES
========================================*/

$table-border-color: $silver;
$table-fill-color: $smoke;

table {
    margin-top: $half-spacing;
    width: 100%;

    @include media-query(palm) {
        @include font-size($small-font-size);

        col {
            width: auto !important;
        }
    }
}

caption {
    caption-side: bottom;
    padding-top: $half-spacing;
    margin-bottom: $half-spacing;
    @include font-size($small-font-size);
}

th,
td {
    padding: ($half-spacing / 2) $half-spacing;
    border-bottom: 1px solid $table-border-color;
    text-align: left;
    vertical-align: top;

    // @include media-query(max, $lap-start) {
    //     padding: $half-spacing;
    // }
}

th {
    font-weight: bold;
}

thead {
    th {
        border-bottom: 2px solid $table-border-color;
    }
}

[colspan] {
    text-align: center;
}

[colspan='1'] {
    text-align: left;
}

[rowspan] {
    vertical-align: middle;
}

[rowspan='1'] {
    vertical-align: top;
}

.numerical {
    text-align: right;
}

/**
 * Column widths
 */
$i: 5;
@while $i < 100 {
    .w#{$i} {
        width: $i * 1%;
    }
    $i: $i + 5;
}

/**
 * Plain
 */
.table--plain {
    &,
    th,
    td,
    thead th {
        border: none;
        background: none;
        padding-left: 0;
    }
}

/**
 * Boxed
 */
.table--boxed {
    border: 1px solid $table-border-color;
    border-bottom: 0;
    border-collapse: separate;
}

/**
 * Bordered
 */
.table--bordered {
    border: 1px solid $table-border-color;

    th,
    td {
        border-left: 1px solid $table-border-color;
    }
}

/**
 * Striped
 */
.table--striped {
    tbody tr:nth-of-type(even) {
        th,
        td {
            background: $table-fill-color;
        }
    }
}

/**
 * Hover Rows
 */
.table--hover {
    tbody {
        tr:hover {
            td,
            th {
                background: $table-fill-color;
            }
        }
    }
}

/**
 * Data Table
 */
.table--compact {
    width: auto;
    @include font-size($small-font-size);

    th,
    td {
        padding: ($half-spacing / 2);
    }
}

/**
 * Responsive Table
 */
.table--rwd {
    @include media-query(palm) {
        @include clearfix();
        display: block;
        border-top: 1px solid $table-border-color;

        thead,
        caption {
            display: none;
        }

        tfoot,
        tbody,
        tr,
        th,
        td {
            display: block;
        }

        tr {
            overflow: auto;
        }

        th,
        td {
            float: left;
            width: 100%;
        }

        tfoot {
            tr {
                border-bottom: 1px solid $table-border-color;

                &:last-child {
                    border-bottom-width: 3px;
                }
            }

            th,
            td {
                border-bottom: 1px solid $table-fill-color;
                text-align: left !important;

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid $table-border-color;
            }

            th,
            td {
                position: relative;
                border: none;
                border-bottom: 1px solid $table-fill-color;
                padding-left: 50%;
                text-align: left !important;

                &:last-child {
                    border-bottom: 0;
                }

                &:empty {
                    display: none;
                }

                &:before {
                    content: attr(data-title);
                    position: absolute;
                    top: ($half-spacing / 2);
                    left: $half-spacing;
                    width: 45%;
                    padding-right: ($half-spacing / 2);
                    white-space: nowrap;
                    font-weight: bold;
                    text-align: left;
                }
            }
        }
    }
}

// Size Guide

$sizing-border: $sky-lt;
$sizing-fill: $sky-lt;

.sizing {
    border-collapse: collapse;
    margin: $base-spacing 0;
    border: 2px solid $blue;

    // Undo greedy M2 styles
    caption {
        border: initial;
        clip: initial;
        height: initial;
        margin: 0;
        overflow: auto;
        padding: initial;
        position: static;
        width: initial;

        caption-side: top;
        padding: $half-spacing;
        // background: $table-fill-color;
        background: $blue;
        color: $white;
        font-size: inherit;
        text-align: left;
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
    }

    th,
    td {
        padding: $half-spacing;
        border-bottom: 1px solid $sizing-border;
        text-align: right;
    }

    th {
        font-weight: bold;

        &:first-child {
            text-align: left;
        }
    }

    [colspan] {
        text-align: center;
    }

    thead {
        th,
        td {
            font-weight: bold;
        }

        th {
            // border-bottom-width: 2px;
            // border-bottom: 2px solid $sizing-border;
            background: $sizing-fill;
            // color: $blue;
        }
    }

    .size:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;
        margin-left: 5px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
    }

    @include media-query(palm) {
        margin: $half-spacing 0;
        @include font-size($small-font-size);

        caption,
        th,
        td {
            padding: ($half-spacing / 2);
        }
    }
}

.sizing--compact {
    @include media-query(min, $lap-start) {
        width: auto;
        min-width: 50%;
    }
}

.sizing--reflow {
    @include media-query(palm) {
        @include clearfix();
        display: block;
        empty-cells: hide;

        thead {
            display: none;
        }

        caption,
        tfoot,
        tbody,
        tr,
        th,
        td {
            display: block;
        }

        tr {
            overflow: auto;
        }

        th,
        td {
            float: left;
            width: 100%;
        }

        // th{
        //     &:first-child {
        //         text-align: right;
        //     }
        // }

        tfoot {
            tr {
                border-bottom: 1px solid $sizing-border;

                &:last-child {
                    border-bottom-width: 3px;
                }
            }

            th,
            td {
                border-bottom: 1px solid $sizing-fill;
                text-align: right;
                // text-align: left!important;

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        tbody {
            border-top: 1px solid $sizing-border;

            tr {
                border-bottom: 2px solid $blue;

                &:last-child {
                    border-bottom: 0;
                }
            }

            th,
            td {
                position: relative;
                border: none;
                border-bottom: 1px solid $sizing-fill;
                // padding-left: 50%;
                // text-align: right;
                // text-align: left!important;

                &:last-child {
                    border-bottom: 0;
                }

                &:empty {
                    display: none;
                }

                &:before {
                    // content: attr(data-title);
                    // position: absolute;
                    // top: ($half-spacing / 2);
                    // left: $half-spacing;
                    // width: 45%;
                    padding-right: ($half-spacing / 2);
                    white-space: nowrap;
                    font-weight: bold;
                    text-align: left;
                }
            }

            th {
                background: $sky-lt;

                &:before {
                    content: attr(data-title) ':';
                }
            }

            td {
                padding-left: 50%;
                text-align: right;

                &:before {
                    content: attr(data-title);
                    position: absolute;
                    top: ($half-spacing / 2);
                    left: ($half-spacing / 2);
                    width: 45%;
                    // padding-right: ($half-spacing / 2);
                    // white-space: nowrap;
                    // font-weight: bold;
                    // text-align: left;
                }
            }

            [colspan] {
                padding-left: ($half-spacing / 2);

                &:before {
                    display: none;
                }
            }
        }
    }
}

.sizing--scroll {
    [colspan] {
        &,
        &:first-child {
            text-align: left;
        }
    }
}

// http://colorbrewer2.org/#type=diverging&scheme=RdYlBu&n=6
.tint--1 .size:after {
    background: #d73027;
}

.tint--2 .size:after {
    // border-right: 5px solid green;
    // background: lighten(green, 50%);
    background: #fc8d59;
}

.tint--3 .size:after {
    // border-right: 5px solid orange;
    // background: lighten(orange, 40%);
    background: #fee090;
}

.tint--4 .size:after {
    // border-right: 5px solid red;
    // background: lighten(red, 40%);
    background: #e0f3f8;
}

.tint--5 .size:after {
    // border-right: 5px solid pink;
    // background: lighten(pink, 20%);
    background: #91bfdb;
}

.tint--6 .size:after {
    // border-right: 5px solid pink;
    // background: lighten(pink, 20%);
    background: #4575b4;
}

.scroller {
    position: relative;
    overflow: hidden;
    margin: $base-spacing 0;

    > div {
        overflow-x: auto;
        overflow-y: hidden;

        // &::-webkit-scrollbar {
        //     height:12px;
        // }
        // &::-webkit-scrollbar-track {
        //     box-shadow:0 0 2px rgba(0,0,0,0.15) inset;
        //     background:#f0f0f0;
        // }
        // &::-webkit-scrollbar-thumb {
        //     border-radius:6px;
        //     background:#ccc;
        // }

        > * {
            // display: inline-block;
            margin-top: 0;
            margin-bottom: 0;
            white-space: nowrap;
        }
    }

    &.is-scrolling {
        // margin: ($half-spacing / -2) 0;
        // margin-bottom: $half-spacing;

        > div {
            // padding: ($half-spacing / 2) 0;
            -webkit-overflow-scrolling: touch;

            > * {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 100%;
            margin-left: -1px;
            border-left: 1px solid rgba($black, 0.5);
            width: 50px;
            height: 100%;
            // border-radius: 10px 0 0 10px / 50% 0 0 50%;
            box-shadow: -5px 0 10px rgba($black, 0.5);
        }
    }

    @include media-query(palm) {
        margin: $half-spacing 0;
    }
}

.unit-toggle {
    @include clearfix;
    padding-top: ($half-spacing / 2);
}

.unit-toggle__button {
    float: left;
    border: 2px solid $white;
    padding: ($half-spacing / 2 - 2px) ($half-spacing - 2px);
    background: $blue;
    color: $white;

    &:first-child {
        border-right: 0;
        border-radius: $base-spacing 0 0 $base-spacing;
    }

    &:last-child {
        border-left: 0;
        border-radius: 0 $base-spacing $base-spacing 0;
    }

    &.active {
        background: $white;
        color: $blue;
        cursor: default;
    }
}

.sizing--units {
    // @include media-query(palm) {
    //     tbody {
    //         th {
    //             background: $sky-lt;
    //         }
    //     }
    // }
}

.sizing--met {
    .unit--imp {
        display: none;
    }

    &.sizing--reflow {
        @include media-query(palm) {
            tbody {
                td {
                    &:before {
                        content: attr(data-title-met);
                    }
                }
            }
        }
    }
}

.sizing--imp {
    .unit--met {
        display: none;
    }

    &.sizing--reflow {
        @include media-query(palm) {
            tbody {
                td {
                    &:before {
                        content: attr(data-title-imp);
                    }
                }
            }
        }
    }
}
