.form.send.friend {
    .legend {
        display: block;
        width: 100%;
        @include font-size(18px);
        border-bottom: 2px solid $cream;
    }

    .fieldset.sender {
        @include clearfix;

        @include media-query(min, $lap-start) {
            .field.sender {
                float: left;
                width: 49%;
            }
            .field.email {
                float: left;
                width: 49%;
                margin-left: 2%;
            }
            .field.text {
                width: 100%;
                float: left;
            }
        }
    }
    .fieldset.recipients {
        .fieldset {
            @include clearfix;
            margin-bottom: $half-spacing;
            @include media-query(min, $lap-start) {
                > * {
                    float: left;
                    width: 49%;
                }
                .field.email {
                    margin-left: 2%;
                }
            }
        }
    }
    .actions-toolbar {
        .secondary {
            text-align: right;
            .action.add {
                @include button;
                @include button--subtle;
            }
            .action.remove {
                padding-left: 0;
                padding-right: 0;
                -webkit-appearance: none;
                border: 0;
                color: $link;
                background: none;
            }
        }
    }
}
