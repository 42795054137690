// ------------------------------
// Common styles for all toolbars
// ------------------------------

.toolbar {
  @include clearfix();
  margin-bottom: $half-spacing;
  padding: $half-spacing;
  @include media-query(min, $lap-start) {
    margin-bottom: $base-spacing * 1.5;
  }

  .modes {
    float: left;
  }
}

// -----------
// Top toolbar
// -----------

.toolbar-products {
  @include font-size(14px);
  display: flex;
  align-items: center;
  padding: 1em;
  color: $theme-color-one;
  background: $theme-color-one-light;
  border-radius: .75em;
  margin: 0 -.5em 1em;
  justify-content: space-between;

  &:not(:has(~ .toolbar-products)) {
    margin: -1em -.5em 1em -0.5em;
    justify-content: center;

    @include media-query(min, $lap-start) {
      margin-top: -1.5em;
    }
  }

  &:before,
  &:after {
    display: none;
  }

  .pages {
    display: flex;
  }

  .modes {
    display: none;

    @include media-query(min, $lap-start) {
      display: flex;
      margin-right: 20px;
    }

    .modes-mode {
      display: inline-block;
      color: $theme-color-one;;
      cursor: pointer;

      &.active {
        color: $black;
        cursor: default;
      }
    }

    .modes-label {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
    }

    span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    .svg-icon {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      border: solid 1px $smoke;
      margin-top: -5px;
    }
  }

}

.toolbar-amount, .toolbar-sorter .sorter-label {
  font-weight: bold;
  color: $theme-color-one;
  font-family: Museo Sans Rounded, Helvetica, Arial, sans-serif;
  font-size: 1.1em;

  @include media-query(min, $palm-start){
    font-size: 1.3em;
  }
}

// ----------------------
// Text eg: Items 1-20 of 30
// ----------------------

.toolbar-amount {
  //float: left;
  //width: 33%;
  margin-bottom: 0;

  font-weight: bold;
  padding-left: .25em;

  @include media-query(min, $lap-start) {
    //width: 33.3333%;
  }
}

// ----------------------
// The select box for
// "Show 12 per page"
// ----------------------

.limiter {
  display: none;
  width: 33.3333%;
  margin-bottom: 0;
  text-align: center;
  position: relative;
  top: -4px;

  .control {
    display: inline-block;
  }

  .limiter-text {
    margin-left: $half-spacing/2;
  }

  .limiter-options {
    max-width: 50px;
  }

  .limiter-label {
    font-weight: normal;
  }

  .label {
    > span {
      font-weight: normal;
    }
  }

  @include media-query(min, $lap-start) {
    display: inline-block;
  }
}

// ----------------------
// Sorter
// ----------------------
.toolbar-sorter {
  font-size: $small-font-size;
  position: relative;
  //top: -4px;
  //width: 60%;
  //float: right;
  //text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5em;

  .action.sorter-action {
    display: none;
    //@include media-query(min, 480px) {
    //    display: inline-block;
    //}
  }

  .sorter-label {
    margin-bottom: 0;
    font-weight: normal;
  }

  .sorter-options {
    width: auto;
  }

  @include media-query(min, 480px) {
    @include font-size(14px);
  }
  @include media-query(min, $lap-start) {
    //width: 33.3333%;
  }
}

.sorter-action > span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sort-asc {
  .svg-icon {
    height: 24px;
    width: 24px;
  }
}

.sort-desc {
  .svg-icon {
    height: 24px;
    width: 24px;
    transform: rotate(180deg);
  }
}

// ---------------------------------------
// Toolbar that follows the product wrapper
// ---------------------------------------
.products.products-grid ~ .toolbar-products,
.products.products-list ~ .toolbar-products {

  .toolbar-amount {
    display: none !important;
  }

  .limiter {
    display: none !important;
  }

  .toolbar-sorter {
    display: none !important;
  }

  .pages-label {
    display: none !important;
  }

  .pages {
    display: flex;
    justify-content: center;
  }

  .pages-items {
    margin-bottom: 0;
  }
}

//----------------------
// Pagination
//----------------------
.pages {
  display: block;
  text-align: center;
  position: relative;

  .sorter {
    display: none;
  }

  .pages-label {
    display: none;
  }

  .page {
    .label {
      display: none;
    }
  }

  .pages-items {
    @include list-reset;
    @include clearfix;
    text-align: center;
    display: inline-block;

    @include media-query(min, $desk-start) {
      margin-bottom: 0;
      vertical-align: middle;
    }
  }

  .item {
    float: left;

    > * {
      text-decoration: none;
      width: 40px;
      height: 40px;
      line-height: 38px; // font offset
      text-align: center;
      display: inline-block;
      border-radius: 50%;
      background: $white;
      border: 2px solid $sky-lt;
      padding: 0;
      color: $link;
      margin: 0 $half-spacing / 2;

      &:hover {
        background: $link;
        color: $white;
        border-color: $link;
      }
    }

    &.current {
      > * {
        background: $link;
        border-color: $link;
        color: $white;
        font-weight: normal;
      }
    }
  }

  .action {
    background: none;
    color: $link;

    .label {
      display: none;
    }

    span {
      display: none;
    }

    .svg-icon {
      height: 24px;
      width: 24px;
      position: relative;
      top: -2px; // font offset
    }

    &.previous {
      .svg-icon {
        transform: rotate(180deg);
      }
    }
  }

  .pages-view-all {
    text-align: center;
    @include media-query(min, $desk-start) {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }
  }
}

// -----------
// Bottom Toolbar
// -----------

.toolbar-products ~ .toolbar .modes {
  display: none;
}
