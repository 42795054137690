.company-links {
  display: flex;
  gap: 0.5em;
  align-items: center;

  a {
    font-weight: bold;
    background-color: #f1f1f1;
    padding: 0 10px;
    border-radius: 2em;
    text-decoration: none;

    &:hover {
      background-color: aliceblue !important;
    }
  }
}
