// -----------------------------------------
// Page main stuff - this appears on every
// page and wraps all content
// -----------------------------------------
.page-main {
    @include clearfix;
    position: relative;
}

// --------------------------------------
// This wraps page content, but will NOT
// include things such as HEROS or page-specific
// stuff that goes full-width
// --------------------------------------
.columns {
    @include clearfix;
    @include container;

    padding-top: $base-spacing;
    padding-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
        padding-top: $half-spacing;
        padding-bottom: $half-spacing;
    }
}

// ----------------------------------
// Pages that only contain a single
// centered layout block
// ----------------------------------
.page-layout-1column {
    .page-title-wrapper {
        @include container;
    }

    .page-title {
        padding-top: $half-spacing;
        margin-bottom: 0;
    }

    .columns {
        //@include media-query(min, $lap-start) {
         //   padding-top: $base-spacing;
          //  padding-bottom: $base-spacing;
        //}

        padding: 0 1em;
    }
}

// -------------------------------------
// 2 col left layout - this is
// seen on the category + account pages
// -------------------------------------
.page-layout-2columns-left {
    .column.main {
        @include clearfix;
        @include media-query(min, $desk-start) {
            width: 75%;
            float: right;
            padding-left: $base-spacing * 2;
            margin-top: -.5em;
        }
    }

    .sidebar {
        @include media-query(min, $desk-start) {
            width: 25%;
            float: left;
        }
    }

    .sidebar-additional {
        @include container;
    }
}

// ----------------------
// 2 col right layout
// ----------------------
.page-layout-2columns-right {
    .column.main {
        @include media-query(min, $desk-start) {
            width: 75%;
            float: left;
        }
    }

    .sidebar {
        @include media-query(min, $desk-start) {
            width: 25%;
            float: right;
            padding-left: $base-spacing;
        }
    }

    .sidebar-additional {
        @include container;
    }
}

//---------------------------
// Hide main title on homepage
//---------------------------
.cms-index-index {
    .page-title-wrapper {
        display: none;
    }
}
