/*========================================
BUTTONS
========================================*/

//----------------------
// Default button
//----------------------
.button {
  @include button;
}

/**
 * Button Styles
 */
@mixin button--bold {
  border-color: $theme-color-one;
  background: $theme-color-one;
  color: $white;
  box-shadow: inset 0px -2px 0px 0px darken($theme-color-one, 10%);

  &:hover,
  &:focus {
    border-color: darken($theme-color-one, 10%);
    background: darken($theme-color-one, 10%);
    color: $white;
  }

  &:active {
    border-color: darken($theme-color-one, 20%);
    background: darken($theme-color-one, 20%);
  }
}

.button--bold {
  @include button--bold;
}

@mixin button--subtle {
  border: 2px solid $theme-color-one;
  background: $white;
  color: $theme-color-one;

  &:hover,
  &:focus {
    border-color: darken($theme-color-one, 10%);
    background: $white;
    color: darken($theme-color-one, 10%);
  }

  &:active {
    border-color: darken($theme-color-one, 20%);
    background: $white;
  }
}

.button--subtle {
  @include button--subtle;
}

@mixin button--trans {
  border: 2px solid $white;
  background: transparent;
  color: $white;

  &:hover,
  &:focus {
    border-color: $white;
    background: $white;
    color: $blue;
  }
}

.button--trans {
  @include button--trans;
}

.button--clear {
  border: 1px solid $silver;
  background: $white;
  color: $black;

  &:hover,
  &:focus {
    border-color: darken($black, 10%);
    background: $white;
    color: darken($black, 10%);
  }

  &:active {
    border-color: $theme-color-one;
    background: $white;
    color: $theme-color-one;
  }
}

.button--full-palm {
  @include media-query(max, $desk-start) {
    width: 100%;
    margin-left: 0;
    + .button--full-palm {
      margin-top: $half-spacing;
      margin-left: 0;
    }
  }
}

.button--round-left {
  border-radius: 5px 0 0 5px;
}

.button--round-right {
  border-radius: 0 5px 5px 0;
}

.button--icon {
  padding: ($half-spacing - 1px);
  height: $button-height;
  margin: -5px;
}

.button--social {
  border-color: $blue-lt;
  background: $blue-lt;
  color: $white;

  &:hover,
  &:focus {
    border-color: darken($blue, 10%);
    background: darken($blue, 10%);
    color: $white;
  }

  &:active {
    border-color: darken($blue, 20%);
    background: darken($blue, 20%);
  }
}

/**
 * Button Sizes
 */
.button--micro {
  padding: $half-spacing/4 $half-spacing/2;
  @include font-size($micro-font-size);
}

.button--small {
  padding: $half-spacing/2 $half-spacing;
  @include font-size($small-font-size);
}

.button--large {
  padding: $base-spacing $base-spacing * 2;
  @include font-size($lede-font-size);
  border-radius: 40px;
}

.button--full {
  display: block;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

/**
 * Conjoined Buttons
 */

.button-group {
  @extend .nav;
  @extend .nav--float;
  white-space: nowrap;

  > li + li {
    margin-left: -1px;

    .button {
      border-left: 1px solid $white;
    }
  }

  > li .button {
    border-radius: 0;
  }

  > li:first-child .button {
    border-radius: $button-radius 0 0 $button-radius;
  }

  > li:last-child .button {
    border-radius: 0 $button-radius $button-radius 0;
  }
}

/**
 * Sibling Button Bars
 */

.button-bar {
  @include clearfix();

  .button-group {
    float: left;
    margin-bottom: 0;

    + .button-group {
      margin-left: $half-spacing;
    }
  }
}

/**
* Special buttons
*/

.button--search {
  border-color: $search-btn-border-color;
  background: $search-btn-bg;
  color: $search-btn-txt-color;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    border-color: $search-btn-hover-border-color;
    background: $search-btn-hover-bg;
  }
}

#search {
  &:hover,
  &:focus,
  &:active {
    border-color: $search-btn-hover-border-color;
  }
}
