.site-support {
    background-color: $cream-light;
    padding-bottom: 0;
    padding-top: 0;

    @include media-query-bracket($lap-start, $desk-start) {
        padding-top: $base-spacing;
    }
    @include media-query-bracket($desk-start, $wide-start) {
        padding-top: $base-spacing + $half-spacing;
    }
}

.site-support__container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @include media-query(min, $desk-start) {
        align-items: center;
    }

    @include media-query(min, $wide-start) {
        margin-left: -12.5%;
        width: 125%;
    }
}

.site-support__image {
    display: block;
    width: 50%;

    @include media-query(min, $desk-start) {
        width: 35%;
        align-self: flex-end;
        &:last-of-type {
            order: 3;
        }
    }
}

.site-support__content {
    text-align: center;
    width: 100%;

    @include media-query(min, $desk-start) {
        //padding-bottom: $base-spacing;
        //padding-left: $half-spacing;
        //padding-right: $half-spacing;
        margin-bottom: 0;
        width: 30%;
        order: 2;
    }
}

.site-support__title {
    @include font-size($h1-font-size);
    color: $base-color;
    @include media-query(min, $desk-start) {
        padding-top: 0;
    }
}

.site-support__phone {
    @include font-size(35px);
    font-weight: bold;
    line-height: 1;

    @include media-query(min, $wide-start) {
        @include font-size(40px);
    }
}

.site-support__desc {
}
