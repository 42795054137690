.minicart-wrapper {
    .block-minicart {
        margin: 0;
        list-style: none none;
        min-width: 100%;
        z-index: 100;
        box-sizing: border-box;
        top: 100%;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
        background: $white;
        padding: $base-spacing;
        right: 0;
        width: 100%;

        &.empty {
            //padding: $base-spacing $base-spacing 1px $base-spacing;
        }

        li {
            margin: 0;
            position: relative;

            &:hover {
                cursor: pointer;
            }
        }

        @include mage-arrow;

        .block-title {
            display: none;
        }
    }

    &.active {
        overflow: visible;

        .block-minicart {
            display: block;
            position: absolute;
        }
    }

    .product {
        .remove {
            display: inline;
            position: absolute;
            top: 0;
            right: 40px;
            margin-top: 20px;

            a:after {
                content: '';
                position: absolute;
                top: 0;
                right: -20px;
                width: 15px;
                height: 15px;
                background-image: url('../img/close_blue.png');
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    .product .actions > .secondary {
        display: inline;
        position: absolute;
        top: 0;
        right: 40px;
        margin-top: $base-spacing;

        .action.delete {
            &:after {
                content: '';
                position: absolute;
                top: 5px;
                right: -20px;
                width: 15px;
                height: 15px;
                background-image: url('../img/close_blue.png');
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    .product .actions > .primary:not(:last-child),
    .product .actions > .secondary:not(:last-child) {
        margin-right: 15px;
    }

    .action.close {
        display: none;
    }
}

.minicart-items-wrapper {
    display: $minicart-items-display;
    position: relative;
    border: 1px solid #ccc;
    margin-left: -$base-spacing;
    margin-right: -$base-spacing;
    border-left: 0;
    border-right: 0;
    padding: 0;
    clear: both;

    &.overflowed {
        .minicart-items {
            overflow: auto;
        }
    }
}

.minicart-items {
    margin: 0;
    padding: 0;
    list-style: none none;
    height: auto !important;

    .field.qty {
        .label {
            position: relative;
            top: 2px;
        }
    }

    .product-item {
        padding: $base-spacing;
    }

    .product-item:not(:first-child) {
        border-top: 1px solid #ccc;
    }

    .product-item-pricing {
        padding-top: $half-spacing;
        margin-bottom: $half-spacing;

        .label {
            display: inline-block;
        }
    }

    .price-minicart {
        margin-bottom: 5px;
    }

    .product-item-name {
        //font-weight: 400;
        //margin: 0 0 10px
        display: block;
        padding: $half-spacing/2 0;
        margin-bottom: 0;

        a {
            color: #1979c3;
            text-decoration: none;
        }
    }

    .product-item-details {
        padding-left: 90px;

        .price {
            font-weight: 700;
        }

        .price-including-tax,
        .price-excluding-tax {
            margin: 5px 0;
        }

        .weee[data-label] {
            font-size: 1.1rem;
        }

        .details-qty {
            margin-top: 10px;
        }
    }

    .product > .product-item-photo,
    .product > .product-image-container {
        float: left;
        max-width: 78px;
    }

    .product .toggle {
        border: 0;
        //padding: 0 40px 5px 0;
        outline: 0;
    }

    .product .toggle:after {
        color: #8f8f8f;
        margin: 0 0 0 5px;
        position: static;
    }

    .product .active > .toggle:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid transparent;
        border-bottom: 6px solid #000;
        position: relative;
        top: -3px;
    }

    .product.pricing {
        margin-top: 3px;
    }

    .product {
        &.options {
            font-size: 15px;

            &.list {
                margin-left: 10px;

                dt::after {
                    content: '';
                }
            }

            .toggle.tooltip {
                display: inline-block;
                text-decoration: none;

                &::after {
                    -webkit-font-smoothing: antialiased;
                    font-size: 12px;
                    line-height: 12px;
                    color: inherit;
                    content: '\e622';
                    margin: -3px 0 0 7px;
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                }

                > span {
                    border: 0;
                    clip: rect(0, 0, 0, 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }
            }

            .details {
                display: none;
            }
        }
    }

    .item-qty {
        text-align: center;
        width: $base-spacing * 2;
        height: $base-spacing * 2;
    }

    .update-cart-item {
        @include button;
    }

    .subtitle {
        display: none;
    }

    .action.edit,
    .action.delete {
        display: inline-block;
        text-decoration: none;
    }

    .action.edit:hover:before,
    .action.delete:hover:before {
        color: #333;
    }

    .action.edit:active:before,
    .action.delete:active:before {
        color: #8f8f8f;
    }

    .product-image-wrapper {
        height: auto;
        padding: 0 !important;
    }

    .product-image-wrapper .product-image-photo {
        position: static;
    }

    .product-item > .product:before,
    .product-item > .product:after {
        content: '';
        display: table;
    }

    .product-item > .product:after {
        clear: both;
    }

    .product.options .toggle > span {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

.abs-cart-block > .title,
.abs-discount-block .block > .title,
.cart-summary .block > .title,
.paypal-review-discount .block > .title,
.cart-discount .block > .title {
    //border-top: 1px solid #ccc;
    cursor: pointer;
    margin-bottom: 0;
    position: relative;
    padding: 10px 0;
    display: block;
    text-decoration: none;
    outline: 0;
}

.abs-toggling-title > span,
.abs-cart-block > .title > span,
.minicart-items .product .toggle > span,
.abs-discount-block .block > .title > span,
.cart-summary .block > .title > span,
.paypal-review-discount .block > .title > span,
.cart-discount .block > .title > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.abs-toggling-title:after,
.abs-cart-block > .title:after,
.minicart-items .product .toggle:after,
.abs-discount-block .block > .title:after,
.cart-summary .block > .title:after,
.paypal-review-discount .block > .title:after,
.cart-discount .block > .title:after {
    content: ' ';
    vertical-align: middle;
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #000;
    position: relative;
    left: 3px;
}

.minicart-items .product-item-details .weee[data-label] .label {
    display: none;
}

.block-minicart .paypal,
.cart-summary .paypal {
    display: block;
}

.block-minicart .items-total {
    float: left;
    margin: 0;
    width: 30%;
}

.block-minicart .items-total .count {
    font-weight: 700;
}

.block-minicart .subtotal {
    margin: 0 0 17px;
    text-align: right;
    float: right;
    width: 70%;
}

.block-minicart .subtotal .amount {
    display: inline-block;
}

.block-minicart .amount .price-wrapper:first-child .price {
    font-size: 18px;
    font-weight: 700;
}

.block-minicart .subtitle {
    display: none;
}

.block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    text-align: center;
}

.block-minicart .text.empty {
    text-align: center;
}

.block-minicart .block-content > .actions > .secondary {
    text-align: center;

    .viewcart {
        display: block;
        padding: 9px 19px;
        width: 100%;
        vertical-align: middle;
        color: #fff;
        font-family: $sans;
        font-weight: 700;
        font-size: $base-font-size;
        line-height: 1;
        text-align: center;
        transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
        text-decoration: none;
        border-radius: $button-radius;
        border: 1px solid $theme-color-two;
        background: $theme-color-two;
        cursor: pointer;
        box-shadow: inset 0 -2px 0 0 darken($theme-color-two, 10%);

        &:hover,
        &:focus {
            border-color: darken($theme-color-two, 10%);
            background: darken($theme-color-two, 10%);
        }
    }
}

.block-minicart .block-content > .actions > .secondary {
    margin-top: $base-spacing/2;
}

.block-minicart .block-content > .actions > .primary .action.primary {
    width: 100%;
}

.block-minicart .block-content > .actions .paypal-logo {
    margin-top: 15px;
    text-align: center;
}

.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
    margin: 15px 0 0;
    text-align: center;
}

@include media-query(min, $lap-start) {
    .minicart-wrapper .block-minicart {
        width: 100%;
    }

    .minilist .action.delete:before,
    .minicart-wrapper .action.edit:before {
        //font-size: 16px;
        //line-height: inherit
    }
}

.block.items-in-cart {
    user-select: none;

    &.active {
        .content.minicart-items,
        .minicart-items-wrapper {
            display: block !important;
        }
    }
}
