.product-flag {
    display: block;
    position: absolute;
    top: $half-spacing;
    left: 0;
    padding: 6px 14px 4px 10px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1em;
    border-radius: 0 $base-spacing $base-spacing 0;
    @include font-size(14px);
    background-color: $yellow;
    color: $blue;
    z-index: 1;
}

.product.media .product-flag {
    top: $base-spacing;
    font-size: 1.1em;
}
