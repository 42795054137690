// ----------------------
//
// ----------------------
.compression-info {
    margin-bottom: 0.5em;
    border-radius: 0.5em;
    overflow: hidden;
}

.info-banner {
    background: $yellow-lt;
    padding: $base-spacing;
    padding-bottom: 0;
    @include clearfix;

    .mfp-content & {
        max-width: 600px;
        margin: 0 auto;
    }
}

// ----------------------
//
// ----------------------
.info-banner__title {
    @include font-size($h2-font-size);
    font-weight: $heading-font-weight;
    margin-bottom: 0;

    span {
        display: block;
        font-weight: normal;

        @include media-query(min, $lap-start) {
            display: inline;
        }
    }
}

// ----------------------
//
// ----------------------
.info-banner__summary {
    margin-bottom: $half-spacing * 1.5;
    a {
        color: $black;
        @include hover {
            text-decoration: none;
        }
    }
}

// ----------------------
//
// ----------------------
.info-banner__img {
    width: 60px;
    height: 60px;
    float: right;
    margin-left: $base-spacing;
}

// ----------------------
//
// ----------------------
.info-banner__cta {
    margin-bottom: 0;
    margin-left: -$base-spacing;
    margin-right: -$base-spacing;
    background: $blue;
    padding: $half-spacing $base-spacing;
    @include clearfix;
    color: $yellow;
}

// ----------------------
//
// ----------------------
.info-banner__cta-link {
    color: $white;
    display: block;
    text-align: right;

    @include media-query(min, $lap-start) {
        float: right;
        clear: both;
    }

    @include hover() {
        color: $white;
        text-decoration: none;
    }
}
