/*================================================================================
MEDIA
================================================================================*/

img,
video,
embed {
    border: none;
    max-width: 100%;
    height: auto;
}

/*
 * Figures
 */
figure {
    img {
        display: block;
    }
}

figcaption {
    clear: left;
    padding: $half-spacing;
    @include font-size($small-font-size);
}

/*
 * Positioning
 */
.img--right {
    float: right;
    margin-bottom: $base-spacing;
    margin-left: $base-spacing;
}

.img--left {
    float: left;
    margin-right: $base-spacing;
    margin-bottom: $base-spacing;
}

.img--center {
    display: block;
    margin-right: auto;
    margin-bottom: $base-spacing;
    margin-left: auto;
}
