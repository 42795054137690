$stars-width: 75px;

.product-reviews-summary {
    margin-bottom: $half-spacing/2;
    display: flex;
    align-items: center;
}

.product-reviews-summary .rating-summary {
    display: inline-block;
    vertical-align: middle;
    margin-top: 0;
}

.rating-summary {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.product-reviews-summary .rating-summary .label,
.table-reviews .rating-summary .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.rating-summary .rating-result:before {
    content: '';
    display: block;
    width: $stars-width;
    height: 15px;
    background: url('../img/stars.svg') no-repeat;
    background-size: contain;
}

.rating-summary .rating-result > span:before {
    content: '';
    display: block;
    width: $stars-width;
    height: 15px;
    background: url('../img/stars-filled.svg') no-repeat;
    background-size: contain;
}

.rating-summary .rating-result > span span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.reviews-actions {
    @include font-size(14px);
    color: $black;
    margin-left: 5px;
}

.rating-summary .rating-result span {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.rating-summary .rating-result {
    width: $stars-width;
    display: block;
    position: relative;
    vertical-align: middle;
}

.rating-summary {
    margin-right: 4px;
}

// Review Stars
.review-stars-summary {
    font-weight: bold;
    display: inline-block;
    //margin-left: $half-spacing/2;
}

.review-stars-cta {
    margin-left: $half-spacing;
    white-space: nowrap;
}

.feefo-review-widget-product.loading {
    position: relative;
}

.feefo-review-widget-product.loading:empty::before {
    content: 'Loading reviews...';
    position: absolute;
    top: 0;
    left: 10px;
    font-weight: bold;
    color: #3a4450;
}
