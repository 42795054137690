/**
 * MISC STYLES
 */

// BACKGROUND FILLS

.fill--blue {
    background: $blue;
    color: $white;
}

.fill--blue-dk {
    background: $blue-dk;
    color: $white;
}

.fill--flame {
    background: $theme-color-one;
}

.fill--sky-lt {
    background: $sky-lt;
}

.fill--cream {
    background: $cream;
}

.fill-padding {
    padding: $base-spacing;
}

// Colouring SVGs
.clr-blue {
    color: $blue;
}
.clr-flame {
    color: $theme-color-one;
}

.text--white {
    color: $white;

    a,
    h1,
    h2 {
        color: $white;
    }
}

.seporator {
    display: block;
    height: 2px;
    width: 100%;
    background: $cream;
}

.section-title {
    color: $theme-color-one;
    text-align: center;
    @include font-size(24px);
    margin-bottom: $base-spacing * 1.2;
}

.popup-header {
    position: relative;
    display: block;
    width: 100%;
    min-height: 40px;
    background: #e6dca6;

    max-width: 600px;
    margin: 0 auto;

    button {
        color: $blue;
    }
}

.mc-banner iframe {
    height: auto !important;
}
