/*================================================================================
Mixins
================================================================================*/

/*========================================
Utilities
========================================*/

/**
 * Output hover rules
 *
   `@include hover();`
 *
 */
@mixin hover {
    .has-mouse & {
        &:hover,
        &:focus {
            @content;
        }
    }
}

/**
 * Output clearfix rules
 *
   `@include clearfix();`
 *
 */
@mixin clearfix {
    &:before,
    &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
    zoom: 1;
}

/**
 * Output media query block
 *
   `@include media-query(min, 640px);`
 *
 */
@mixin media-query($type, $breakpoint: $lap-start) {
    @if $type == 'min' {
        @media only screen and (min-width: $breakpoint) {
            @content;
        }
    } @else if $type == 'max' {
        @media only screen and (max-width: $breakpoint - 1px) {
            @content;
        }
    } @else if $type == 'palm' {
        @media only screen and (max-width: $lap-start - 1px) {
            @content;
        }
    } @else if $type == 'lap' {
        @media only screen and (min-width: $lap-start) and (max-width: $desk-start - 1px) {
            @content;
        }
    } @else if $type == 'desk' {
        @media only screen and (min-width: $desk-start) {
            @content;
        }
    } @else if $type == 'wide' {
        @media only screen and (min-width: $wide-start) {
            @content;
        }
    } @else if $type == 'retina' {
        @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (min--moz-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-device-pixel-ratio: 2),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
            @content;
        }
    }
}

/**
 * Output media query block with min and max limits
 *
   `@include media-query-bracket(640px, 960px);`
 *
 */
@mixin media-query-bracket($from: 0, $to: 0) {
    @if $to > $from {
        @media only screen and (min-width: $from) and (max-width: $to - 1px) {
            @content;
        }
    }
}

/**
 * Output rule for offscreen image replacement
 *
   `@include offscreen();`
 *
 */
@mixin offscreen() {
    text-indent: -9999px;
    overflow: hidden;
    text-decoration: none;
    display: block;
    font-size: 0;
    text-align: start;
}

/**
 * Hide from both screenreaders and browsers: h5bp.com/u
 *
   `@include hidden();`
 *
 */
@mixin hidden {
    display: none !important;
    visibility: hidden;
}

/**
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 *
   `@include visuallyhidden();`
 *
 */
@mixin visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/**
 * Prevent text selection (for buttons, toggles, etc) https://developer.mozilla.org/en-US/docs/Web/CSS/user-select
 *
   `@include unselectable();`
 *
 */
@mixin unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/**
 * Output color using rgba and rgb fallback
 *
   `@include alpha(border-color, rgba(black,0.1), white);`
 *
 */
@mixin alpha($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
}

/**
 * Output vertical gradient with flat color fallback
 *
   `@include background-gradient(#fc0);`
 *
 */
@mixin background-gradient($color) {
    background: $color;
    background-image: -webkit-gradient(
        linear,
        0% 0%,
        0% 100%,
        from(lighten($color, 5%)),
        to(darken($color, 5%))
    );
    background-image: -webkit-linear-gradient(
        top,
        lighten($color, 5%),
        darken($color, 5%)
    );
    background-image: -moz-linear-gradient(
        top,
        lighten($color, 5%),
        darken($color, 5%)
    );
    background-image: -ms-linear-gradient(
        top,
        lighten($color, 5%),
        darken($color, 5%)
    );
    background-image: -o-linear-gradient(
        top,
        lighten($color, 5%),
        darken($color, 5%)
    );
}

@mixin caret($color: black) {
    content: ' ';
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    top: 6px;
    border-top: 6px solid $color;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    filter: alpha(opacity=100);
}

@mixin caret-invert($color: black) {
    top: -6px;
    border: 6px solid transparent;
    border-bottom-color: $color;
}

/*========================================
Styling
========================================*/

/**
 * Output font size using rem and px fallback
 *
   `@include font-size(10px);`
 *
 */
@mixin font-size($font-size) {
    font-size: $font-size;
    font-size: ($font-size / $base-font-size) * 1rem;
    // line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
}

/**
 * Output base font properties
 *
   `@include font-reset();`
 *
 */
@mixin font-reset() {
    @include font-size($base-font-size);
    line-height: $base-line-height;
    font-family: $base-font-family;
    text-transform: none;
    letter-spacing: 0;
}

/**
 * Output uppercase with some letter spacing
 *
   `@include uppercase(0.1em);`
 *
 */
@mixin uppercase($spacing: $uppercase-spacing) {
    text-transform: uppercase;
    letter-spacing: $spacing;
}

/**
 * Reset text casing
 *
   `@include reset-case();`
 *
 */
@mixin case-reset() {
    text-transform: none;
    letter-spacing: 0;
}

/**
 * Output unstyled list properties
 *
   `@include list-reset();`
 *
 */
@mixin list-reset() {
    margin-left: 0;
    list-style: none;
}

@mixin container {
    max-width: $page-width;
    margin: 0 auto;
    padding-right: $base-spacing;
    padding-left: $base-spacing;
}

@mixin antialias {
    -webkit-font-smoothing: antialiased;
}

@mixin block-title {
    font-weight: bold;
    @include font-size(24px);
    display: block;
    width: 100%;
    color: $mixin-block-title-color;
    line-height: 1;
}

@mixin button-override($border, $color: $white) {
    display: inline-block;
    border: 1px solid $border;
    padding: ($half-spacing - 1px) ($base-spacing - 1px);
    width: auto;
    vertical-align: middle;
    background: $border;
    color: $white;
    font-family: $sans;
    font-weight: bold;
    @include font-size($base-font-size);
    line-height: 1;
    text-align: center;
    cursor: pointer;
    border-radius: $button-radius;
    transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
    text-decoration: none;

    &:hover,
    &:focus {
        border-color: darken($border, 10%);
        background: darken($border, 10%);
        color: $white;
        text-decoration: none;
    }

    &:active {
        border-color: darken($border, 20%);
        background: darken($border, 20%);
    }
}

// Jodi's mixin for the pagination (can't get this to all work toegther)
@mixin putInCircle($colour, $spacing: $base-spacing * 2) {
    display: block;
    border: 2px solid $colour;
    height: $spacing;
    width: $spacing;
    line-height: calc(#{$spacing} - 0.25em);
    border-radius: 100%;
    text-align: center;
    text-decoration: none;
}

@mixin mage-arrow {
    &:before,
    &:after {
        border-bottom-style: solid;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        width: 0;
    }

    &:before {
        border: 6px solid;
        border-color: transparent transparent #fff transparent;
        z-index: 99;
    }

    &:after {
        border: 7px solid;
        border-color: transparent transparent #bbb transparent;
        z-index: 98;
    }

    &:before {
        left: 10px;
        top: -12px;
    }

    &:after {
        left: 9px;
        top: -14px;
    }

    &:after {
        left: auto;
        right: 25px;
    }

    &:before {
        left: auto;
        right: 26px;
    }

    &:before {
        border-color: $white $white $white $white;
        @include media-query(min, $lap-start) {
            border-color: transparent transparent #fff transparent;
        }
    }
}

@mixin block-collapsible-nav-title {
    color: $block-collapsible-nav-title-color;
    font-family: $sans-title;
    @include font-size(24px);
    letter-spacing: 1px;
    display: block;
    padding: $half-spacing $base-spacing;
    background: $block-collapsible-nav-title-bg;

    border-radius: $double-radius;
    position: relative;
    cursor: pointer;

    &:after {
        content: ' ';
        width: 20px;
        height: 20px;
        position: absolute;
        top: $half-spacing + 8px;
        right: $half-spacing;
        background-color: $theme-color-one;
        -webkit-mask-image: url(../img/svg/ic_expand_more.svg);
        mask-image: url(../img/svg/ic_expand_more.svg);
    }

    &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:after {
            transform: scaleY(-1);
        }
    }

    //@include media-query(min, $desk-start) {
        //border-bottom-left-radius: 0;
        //border-bottom-right-radius: 0;
        //cursor: default;

        //&:after {
        //    display: none;
       //}
   //}
}

@mixin block-collapsible-nav-content {
    background: $block-collapsible-nav-content-bg;
    padding-left: $base-spacing;
    padding-right: $base-spacing;
    padding-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
        display: block;
    }

    .items {
        list-style: none;
        margin: 0;
    }
}

@mixin button-reset {
    border: 0;
    outline: 0;
    box-shadow: 0 0 0 0;
}

@mixin button {
    display: inline-block;
    border: 1px solid $blue;
    padding: ($half-spacing - 1px) ($base-spacing - 1px);
    width: auto;
    vertical-align: middle;
    background: $blue;
    color: $white;
    font-family: $sans;
    font-weight: bold;
    @include font-size($base-font-size);
    line-height: 1;
    text-align: center;
    cursor: pointer;
    border-radius: $button-radius;
    transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
    text-decoration: none;
    user-select: none;

    &:hover,
    &:focus {
        border-color: darken($blue, 10%);
        background: darken($blue, 10%);
        color: $white;
        text-decoration: none;
    }

    &:active {
        border-color: darken($blue, 20%);
        background: darken($blue, 20%);
    }

    + .button,
    + a {
        margin-left: $half-spacing;
    }
}

$end: 9999px;

@mixin flex-gutters($spacing, $columns, $breakpoint: $end) {
    $column-width: 100% / $columns;
    $spacing-total: $spacing * ($columns - 1);
    $gutter-size: $spacing-total/$columns;
    $width: calc(#{$column-width} - #{$gutter-size});
    width: $width;
    margin-left: 0;
    margin-right: $spacing;

    @include media-query(max, $breakpoint) {
        &:nth-child(#{$columns}n + #{$columns}) {
            margin-right: 0;
        }
    }
}
