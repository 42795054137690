/*========================================
PARAGRAPHS
========================================*/

.lede {
    @include font-size($lede-font-size);
}

.small {
    @include font-size($small-font-size);
}

.micro {
    @include font-size($micro-font-size);
}

.caption {
    padding-top: $half-spacing / 2;
    margin-bottom: $half-spacing;
    @include font-size($small-font-size);
}

address {
    font-style: normal;
}
