.page-hero--product-page {
  position: relative;
  width: 100%;
  padding: $base-spacing 0 0;
  background-color: $product-page-hero-bg;

  max-width: 1200px;
  margin: 0 auto 0.5em;
  border-radius: 0.5em;

  .container {
    position: relative;
    z-index: 2;
  }

  .page-title {
    padding-top: 0;
    @include font-size(28px);
    line-height: 1.2;

    @media (min-width: $lap-start) {
      @include font-size(32px);
    }
  }
}

.page-hero--category {
  width: 100%;

  max-width: 1200px;
  border-radius: 0.5em;

  .container {
    position: relative;
    z-index: 2;
  }
}

.product__border--top {
  position: absolute;
  width: 60%;
  left: 0;
  top: 0;
}

.product__border--bottom {
  position: absolute;
  width: 60%;
  right: 0;
  bottom: 0;
}
