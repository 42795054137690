.accordion {
    margin-bottom: $base-spacing;
    @include unselectable;
    border-bottom: 1px solid $silver !important;
}

.accordion__header {
    position: relative;
    cursor: pointer;
    margin: 0;
    padding: $half-spacing;
    border: 1px solid $silver;
    border-bottom: 0;

    &:after {
        @include caret();
        right: $half-spacing;
        top: 20px;
    }

    &.active {
        border-bottom: 1px solid $silver;
        &:after {
            @include caret-invert();
            top: 14px;
        }
    }
}

.accordion__body {
    display: none;
    padding: $half-spacing;
    background: lighten($silver, 10%);
    border-left: 1px solid $silver;
    border-right: 1px solid $silver;

    &.active {
        display: inherit;
    }
}
