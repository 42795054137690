.login-container {
    @include media-query(min, $lap-start) {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .fieldset.login {
        margin-bottom: 0;
    }

    .block-title {
        font-size: 1.5em;
        color: $theme-color-one;
        font-weight: 900;
        line-height: 1;
        font-family: $heading-font-family;
        @include antialias;
        margin-bottom: $half-spacing;
    }

    .block-customer-login {
        margin-bottom: $base-spacing * 2;
    }

    .block-new-customer {
        text-align: center;
        max-width: 30em;
    }

    .form-login {
        @include form--stacked;
    }

    .actions-toolbar {
        padding: $base-spacing 0;
        padding-bottom: 0;

        .primary {
            display: inline-block;

            .action {
                margin-right: $half-spacing;
                margin-bottom: 0;
                width: auto;
            }
        }
        .secondary {
            position: relative;
            top: 3px;
            display: inline-block;
        }
        .action {
            width: auto;
        }
    }
}
