/**
 * HELPERS
 */

/*================================================================================
VISIBILITY
================================================================================*/

/**
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
.hidden,
.no-display {
    display: none !important;
    visibility: hidden;

    &.active {
        display: inherit !important;
        visibility: visible;
    }
}

/**
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/**
 * Extends the .visuallyhidden class to allow the element to be focusable
 * when navigated to via the keyboard: h5bp.com/p
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/**
 * Hide visually and from screenreaders, but maintain layout
 */
.invisible {
    visibility: hidden;
}

.visible {
    display: block !important;
    visibility: visible;
}

/**
 * For menu toggling
 */
.is-closed {
    display: none !important;
    // margin: 0!important;
    // border: 0!important;
    // padding: 0!important;
    // height: 0!important;
    // overflow: hidden;
}
.is-open {
    display: inherit !important;
    // height: auto;
    // overflow: visible;
}

/**
 * Breakpoint based hiding
 */
@include media-query(palm) {
    .not-palm {
        display: none !important;
    }
    .lap {
        display: none !important;
    }
    .desk {
        display: none !important;
    }
}
@include media-query(lap) {
    .palm {
        display: none !important;
    }
    .not-lap {
        display: none !important;
    }
    .desk {
        display: none !important;
    }
}
@include media-query(desk) {
    .palm {
        display: none !important;
    }
    .lap {
        display: none !important;
    }
    .not-desk {
        display: none !important;
    }
}

/*================================================================================
POSITIONING
================================================================================*/

/**
 * Clearfix
 */
.clearfix,
.cf {
    @include clearfix();
}

/**
 * Hacks? Lets call them overrides.
 */
.clear--both {
    clear: both !important;
}
.clear--right {
    clear: right !important;
}
.clear--left {
    clear: left !important;
}
.fr,
.float--right {
    float: right !important;
}
.fl,
.float--left {
    float: left !important;
}

.flush {
    margin: 0 !important;
}
.flush--top {
    margin-top: 0 !important;
}
.flush--right {
    margin-right: 0 !important;
}
.flush--bottom {
    margin-bottom: 0 !important;
}
.flush--left {
    margin-left: 0 !important;
}
.flush--ends {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.flush--sides {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

/*================================================================================
TYPOGRAPHY
================================================================================*/

.font-sans {
    font-family: $sans;
}
.font-serif {
    font-family: $serif;
}
.font-sans-title {
    font-family: $sans-title;
}

.weight--light {
    font-weight: 300 !important;
}
.weight--normal {
    font-weight: 400 !important;
}
.weight--bold {
    font-weight: 700 !important;
}

.text--left {
    text-align: left !important;
}
.text--center {
    text-align: center !important;
}
.text--right {
    text-align: right !important;
}

.ucase {
    text-transform: uppercase !important;
}
.lcase {
    text-transform: lowercase !important;
}
.caps {
    text-transform: capitalize !important;
}

/*================================================================================
JS VISIBILITY
================================================================================*/

.no-js .no-js--hidden,
/* Hide if Js is disabled cause it's JS dependant */ .js .js--hidden {
    /* Hide if JS is enabled so there's no flicker of hiding/showing */
    display: none !important;

    &.is-open {
        display: block !important; /* reverse hiding styling when open (this is helpful for toggles as we use 'is-open') */
    }
}

.no-js .no-js--visible {
    /* When JS is disabled make visible as it's still important/readable content */
    display: block !important;
}

/*================================================================================
PATTERNS PAGE
================================================================================*/
.patterns-icons-list {
    list-style: none;
}

.patterns-code {
    font-family: $sans;
    font-weight: normal;
}
