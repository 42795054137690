/*========================================
AVAILABILITY
========================================*/

.availability {
    .label {
    }

    .value {
        font-weight: bold;
    }
}

.out-of-stock {
    .value {
        color: red;
    }
}

.in-stock {
    .value {
        color: green;
    }
}
