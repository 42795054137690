//----------------------
// Adjust global elements
// on basket page only
//----------------------
.checkout-cart-index {
    .page-title-wrapper {
        margin-bottom: .5em;
    }

    .cart {
        width: 100%;
    }

    .fieldset.estimate {
        .legend {
            display: none;
        }

        .field.note {
            display: none;
        }
    }
}

//----------------------
// The main form element
//----------------------
.form-cart {
    @include clearfix;

    width: 100%;
    max-width: none;

    .table-caption {
        display: none;
    }

    .cart.main.actions {
        text-align: right;
        margin-bottom: $base-spacing;
        @include media-query(min, $desk-start) {
            padding-top: $half-spacing;
            margin-bottom: 0;
        }

        .action.update {
            @include button;
        }
    }

    .cart.items.table {
        margin-bottom: 0;
    }

    thead {
        th {
            @include media-query(min, $desk-start) {
                border-bottom: 2px solid darken($cream, 5%);
            }
        }
    }

    // On mobile, just make everything blocks
    @include media-query(max, $desk-start) {
        thead,
        tbody,
        th,
        tr,
        td,
        tfoot {
            display: block;
            width: 100%;
            padding: 0;
            border: 0;
        }

        thead {
            display: none;
        }
    }

    // On desktop, float left
    @include media-query(min, $desk-start) {
        margin-bottom: $base-spacing;
        float: left;
        width: 65%;
    }

    // Each item
    .item-info {
        @include media-query(max, $desk-start) {
            @include clearfix;
            margin-bottom: $half-spacing;
        }

        @include media-query(min, $desk-start) {
            .col.item {
                padding-bottom: 0;
                padding-left: 0;
            }
            td {
                padding-top: $base-spacing;
            }
            td,
            th {
                border-bottom: 0;
            }
        }
    }

    .cart.item {
        position: relative;
        margin-bottom: $base-spacing;
        border-bottom: 2px solid $cream;
    }

    .product-item-photo {
        display: block;
        width: 70px;
        font-size: 0;
        @include media-query(min, $desk-start) {
            float: left;
            width: 20%;
        }
    }

    .product-item-details {
        @include media-query(min, $desk-start) {
            float: left;
            width: 80%;
        }
        padding-left: $half-spacing;

        @include media-query(min, $desk-start) {
            padding-left: 50px;
        }

        .item-options {
            margin-bottom: 0;
        }
    }

    .product-item-name {
        @include font-size(16px);

        @include media-query(min, $desk-start) {
            font-weight: normal;
            @include font-size(20px);
        }

        a {
            display: inline-block;
        }
    }

    .col.item {
        z-index: 1;
        margin-bottom: $base-spacing;

        @include media-query(max, $desk-start) {
            display: flex;
            position: relative;
        }

        @include media-query(min, $desk-start) {
            @include clearfix;
            padding-left: 0;
            .product-item-photo {
                float: left;
            }
            .product-item-details {
                float: left;
            }
        }
    }

    .col.price,
    .col.qty,
    .col.subtotal {
        @include media-query(max, $desk-start) {
            float: left;
            width: 33.3333333%;
            text-align: center;
            @include font-size(16px);

            &:before {
                display: block;
                font-weight: normal;
                content: attr(data-th) ':';
                margin-bottom: $half-spacing;

                @include media-query(min, $desk-start) {
                    display: none;
                }
            }
        }
    }

    .col.qty {
        @include media-query(max, $desk-start) {
            &:before {
                margin-bottom: $half-spacing/2;
            }
        }
        @include media-query(min, $desk-start) {
            text-align: center;
        }
    }

    .col.price {
        font-family: $base-font-family;

        @include media-query(max, $desk-start) {
            @include font-size(20px);
            &:before {
                @include font-size(16px);
            }
        }
    }

    .field.qty {
        margin-bottom: 0;
        justify-content: center;

        .label {
            display: none;
        }

        .input-text {
            border-radius: $base-radius;
            border: 1px solid $grey;
        }

        .control {
            display: block;
        }
    }

    .col.subtotal {
        @include media-query(max, $desk-start) {
            @include font-size(20px);
            &:before {
                @include font-size(16px);
            }
        }
        @include media-query(min, $desk-start) {
            text-align: right;
            padding-right: 0;
        }
    }

    .item-actions {
        z-index: 0;
        @include media-query(min, $desk-start) {
            td {
                padding: 0;
                border-bottom: 0;
            }
        }
    }

    .actions-toolbar {
        text-align: right;
        margin-bottom: $half-spacing;

        @include media-query(min, $desk-start) {
            margin-right: -5px;
        }

        .action {
            display: inline-block;
            text-decoration: underline;
            color: $grey-lt;
            padding: $half-spacing/2;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

//-------------------------
// The Cart summary. Top on
// mobile, right on desktop
//-------------------------
.cart-summary {
    margin-top: -$base-spacing;
    margin-left: -$base-spacing;
    margin-right: -$base-spacing;
    margin-bottom: $base-spacing;

    padding: $base-spacing;
    background: $basket-summary-bg;

    @include media-query(min, $desk-start) {
        margin-left: 0;
        margin-right: 0;
        float: right;
        width: 30%;
        margin-top: 0;
        border-radius: $double-radius;
    }

    .summary.title {
        display: none;
    }

    .table-caption {
        display: none;
    }

    .totals.sub,
    .totals-tax {
        .price {
            font-weight: normal;
            font-family: $base-font-family;
        }
    }
}

.cart-discount {
    @include media-query(min, $desk-start) {
        width: 65%;
        float: left;
    }
}

//----------------------
// Cart totals
//----------------------
.cart-totals {
    th,
    td {
        padding: 0;
    }

    .price {
        white-space: nowrap;
    }

    .totals {
        @include media-query(min, $desk-start) {
            margin-bottom: $base-spacing;
        }

        th {
            width: 100%;
            padding-right: $base-spacing;
            text-align: left;
        }

        td {
            //width: 30%;
            text-align: right;
        }

        td,
        th {
            border-bottom: 0;
            @include font-size(18px);
            font-weight: normal;
        }
    }
}

//----------------------
// Discount/voucher block
//----------------------
.block.discount {
    margin-bottom: $base-spacing;

    @include media-query(min, $desk-start) {
        padding-left: 0;
        padding-right: 0;
        float: left;
        width: 100%;
    }

    .action.primary {
        width: 100%;
    }

    .title {
        cursor: pointer;
        position: relative;
        padding-right: $base-spacing;
        @include font-size(16px);

        &:focus {
            outline: 0;
        }

        &:after {
            display: none;
        }

        .svg-icon {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }

        > strong {
            position: relative;

            &:before {
                content: ' ';
                position: absolute;
                width: 100%;
                bottom: -4px;
                height: 2px;
                background: $theme-color-one;
                display: block;
            }

            &:after {
                -webkit-font-smoothing: antialiased;
                line-height: 16px;
                color: #8f8f8f;
                content: '\e622';
                font-family: 'luma-icons', serif;
                margin: 0 0 0 10px;
                vertical-align: middle;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }
        }
    }

    &.active {
        .title {
            .svg-icon {
                transform: translateY(-50%) scaleY(-1);
            }

            > strong {
                &:after {
                    transform: scaleY(-1);
                }
            }
        }
    }

    .content {
        padding-top: $base-spacing;
        padding-bottom: $base-spacing;
        max-width: 400px;
    }
}

.checkout-methods-items {
    @include list-reset;
    margin-bottom: $base-spacing;
    @include media-query(min, $desk-start) {
        max-width: 240px;
        margin: 0 auto;
    }

    .action.primary {
        @include button;
        @include button--bold;
        width: 100%;
        @include media-query(max, $desk-start) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
