/*================================================================================
INLINE
================================================================================*/

/*
 * Abbreviations
 */
abbr {
    color: $grey;
}
abbr[title],
dfn[title] {
    border-bottom: 1px dotted $silver;
    cursor: help;
}

/*
 * Highlighting
 */
mark,
.mark {
    //padding: 0 2px;
    //background: #ffc;
}
ins {
    padding: 0 2px;
    background: $smoke;
}
del {
    text-decoration: line-through;
}

/*
 * Code
 */
pre,
kbd,
code,
samp {
    @include font-size($mono-font-size);
    font-family: $mono-font-family;
}
samp {
    background-color: $smoke;
    padding: 5px;
}
kbd {
    margin: 0 2px;
    border: 1px solid $silver;
    padding: 5px;
    background: $white;
    color: $grey;
    font-size: $mono-font-size;
    line-height: 90%;
    font-family: inherit;
    border-radius: $base-radius;
}

/*
 * Subs and sups
 */
sub {
    font-size: smaller;
    line-height: 90%;
    vertical-align: sub;
}
sup {
    font-size: smaller;
    line-height: 90%;
    vertical-align: super;
}
