/*========================================
COOKIES BANNER
========================================*/

.message.global.noscript,
.message.global.cookie {
    padding: 12px 20px 12px 25px;
    display: block;
    background: #ffee9c;
    border-color: #d6ca8e;
    color: #333;
    margin: 0;
}

.message.global.noscript a,
.message.global.cookie a {
    color: #1979c3;
}

.message.global.noscript a:hover,
.message.global.cookie a:hover {
    color: #006bb4;
}

.message.global.noscript a:active,
.message.global.cookie a:active {
    color: $theme-color-one;
}

.message.global.cookie {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 3;
}

.message.global.cookie .actions {
    margin-top: 10px;

    button {
        display: inline-block;
        border: 1px solid #00477f;
        padding: 9px 19px;
        width: auto;
        vertical-align: middle;
        background: #00477f;
        color: #fff;
        font-family: $sans;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1;
        text-align: center;
        cursor: pointer;
        -webkit-transition: border 0.3s ease, background 0.3s ease,
            color 0.3s ease;
        transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
        text-decoration: none;
        border-radius: 20px;
    }
}
