.order-products-toolbar {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;

    p {
        margin-bottom: 0;
    }
}

.page-title-wrapper .order-status,
.account .page-main .page-title-wrapper .order-status {
    border: 1px solid $silver-lt;
    padding: $half-spacing/2 $half-spacing;
    display: inline-block;
    margin-bottom: $half-spacing;
}

.account .page-main .page-title-wrapper .order-date .label {
    display: none;
}

.order-links {
    display: none;
}

.order-details-items {
    padding: $base-spacing;
    border: 1px solid $silver-lt;
    margin-bottom: $base-spacing * 2;
    .order-title {
        @include block-title;
    }
    .product-item-name {
        @include font-size(18px);
    }
    .items-qty {
        @include list-reset;
        display: inline-block;
    }
}

.block-order-details-view .box-content .payment-method .title {
    font-weight: normal;
}

.order-actions-toolbar .action.print {
    display: none;
    margin: 0;
}

.table-order-items {
    tbody {
        tr {
            .block-dashboard-orders & {
                border-bottom: none;
            }
            border-bottom: 1px solid $silver-lt;
        }
    }
}
