@media only screen and (max-width: 639px) {
  #braintree-three-d-modal .bt-modal-frame {
    width: 100%;
  }
}

#braintree-three-d-modal {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 100000;
  visibility: visible;
}

#braintree-three-d-modal .bt-modal-frame {
  height: 420px;
  width: 440px;
  margin: auto;
  background-color: #ffffff;
  z-index: 2;
  border-radius: 6px;
}

#braintree-three-d-modal .bt-modal-body {
  height: 380px;
  margin: 20px;
  background-color: white;
  border: 1px solid lightgray;
}

#braintree-three-d-modal .bt-mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.75;
}

#braintree_expirationDate {
  width: 90px;
}

.checkout-payment-method .credit-card-types.braintree-credit-card-types img {
  width: 30px;
  -webkit-filter: none;
  -webkit-transition: none;
}

.braintree-card-control {
  position: relative;
}

.braintree-credit-card-selected {
  display: none;
}

.braintree-paypal-logo.oneclick-container {
  width: 100%;
}

#payment_form_braintree_ach_direct_debit .field > .fields .field .label {
  font-weight: 600;
}

#payment_form_braintree_ach_direct_debit .field > .fields .field.required > .label:after {
  content: '*';
  color: #e02b27;
  font-size: 1.8rem;
  margin: 0 0 0 5px;
}

#payment_form_braintree_ach_direct_debit .field > .fields .field .control {
  margin: 10px 10px 0 0;
}

#payment_form_braintree_ach_direct_debit .field > .fields .field .control input[type='text']::placeholder,
#payment_form_braintree_ach_direct_debit .field > .fields .field .control input[type='number']::placeholder {
  color: lightgrey;
}

#payment-method-braintree-lpm .primary {
  width: 100%;
}

#payment-method-braintree-lpm .primary .action {
  margin-bottom: 12px;
  width: 100%;
}

#payment-method-braintree-lpm .primary .action img {
  height: 40px;
}

#payment-method-braintree-cc-vault .field.required .label {
  font-weight: 600;
}

#payment-method-braintree-cc-vault .field.required .label:after {
  content: '*';
  color: #e02b27;
  font-size: 1.8rem;
  margin: 0 0 0 5px;
}

#payment-method-braintree-cc-vault .field .hosted-control {
  background: #ffffff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  height: 32px;
  padding: 0 9px;
  vertical-align: baseline;
  box-sizing: border-box;
  position: relative;
  width: 5rem;
}

#payment-method-braintree-cc-vault .field .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) ~ .hosted-error {
  height: auto;
  opacity: 1;
}

#payment-method-braintree-cc-vault .field .hosted-error {
  clear: both;
  color: #e02b27;
  font-size: 1.2rem;
  height: 0;
  margin-top: 7px;
  opacity: 0;
}

.braintree-paypal-fieldset {
  display: none;
}

.payment-method-braintree .hosted-control {
  border: 1px solid #c2c2c2;
  height: 32px;
  padding: 8px 15px;
  max-width: 300px;
}

.payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) ~ .hosted-error {
  height: auto;
  opacity: 1;
}

.payment-method-braintree .hosted-error {
  clear: both;
  color: #e02b27;
  font-size: 1.2rem;
  height: 0;
  margin-top: 7px;
  opacity: 0;
}

.payment-method-braintree .cvv {
  max-width: 200px;
  position: relative;
}

.payment-method-braintree .cvv .field-tooltip {
  top: 10px;
  right: 30px;
}

.checkout-methods-items > li {
  text-align: center;

  &:nth-child(2) {
    margin-top: 8px;

    > div {
      max-width: 500px;
      margin: 0 auto;
    }
  }
}

.checkout-methods-items li.item > div:empty {
  display: none;
}

.checkout-methods-items .item .action.primary.checkout,
.checkout-methods-items .item .braintree-paypal-mini-cart-container .action-braintree-paypal-logo.button-loaded,
.checkout-methods-items .item .braintree-applepay-minicart,
.checkout-methods-items .item .googlepay-minicart-logo,
.payment-method #braintree-google-checkout-btn {
  height: 35px;
  border-radius: 40px;
  box-shadow: 0 2px 10px #ccc !important;
  overflow: hidden;
  transition: all 0.3s ease;
  min-width: 150px;
  max-width: 500px;
  cursor: pointer;
}

.braintree-applepay-minicart.cart {
  margin-bottom: 8px;
}

.checkout-methods-items .item .braintree-applepay-minicart,
.checkout-methods-items .item .googlepay-minicart-logo button,
.payment-method .braintree-googlepay-button.long {
  height: 100%;
  width: 100%;
  background-origin: content-box;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 0;
}

.checkout-methods-items .item .braintree-paypal-mini-cart-container .action-braintree-paypal-logo.button-loaded {
  background-color: #ffc439;
}

.checkout-methods-items .item .braintree-applepay-minicart {
  background-color: #181818;
}

.checkout-methods-items .item .googlepay-minicart-logo,
.payment-method .braintree-googlepay-button {
  background-color: #101010;
}

.checkout-methods-items .item .googlepay-minicart-logo button,
.payment-method .braintree-googlepay-button.long {
  background-image: url(https://www.gstatic.com/instantbuy/svg/dark/en.svg);
  padding: 0.45em;
  background-color: #101010;
}

.payment-method .braintree-googlepay-button.long {
  padding: 0.45em 1em;
}

@media (min-width: 764px) {
  .payment-method .braintree-googlepay-button.long {
    min-width: 250px;
  }
}

@media (min-width: 340px) and (max-width: 539px) {
  .checkout-methods-items .item .action.primary.checkout,
  .checkout-methods-items .item .braintree-paypal-mini-cart-container .action-braintree-paypal-logo.button-loaded,
  .checkout-methods-items .item .braintree-applepay-minicart,
  .checkout-methods-items .item .googlepay-minicart-logo {
    height: 45px;
  }

  .checkout-methods-items .item .googlepay-minicart-logo button {
    padding: 0.65em;
  }
}

@media (min-width: 540px) and (max-width: 899px) {
  .checkout-methods-items .item .action.primary.checkout,
  .checkout-methods-items .item .braintree-paypal-mini-cart-container .action-braintree-paypal-logo.button-loaded,
  .checkout-methods-items .item .braintree-applepay-minicart,
  .checkout-methods-items .item .googlepay-minicart-logo {
    font-size: 1.2em;
    height: 55px;
  }

  .checkout-methods-items .item .googlepay-minicart-logo button {
    padding: 0.65em;
  }
}

body > .loading-mask:not(#checkout-loader) {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999997;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

body > .loading-mask:not(#checkout-loader) p {
  display: none;
}

.payment-method .paypal-buttons-layout-horizontal,
.payment-method #braintree-google-checkout-btn {
  border-radius: 40px;
  overflow: hidden;
  max-width: 250px;
}

.block-order-details-view .block-content {
  display: grid;
  grid-gap: 1.5em;
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .block-order-details-view .block-content {
    grid-template-columns: 2fr 1fr 1fr;
  }
}

.table-paypal-review-items .col {
  vertical-align: middle;
}

.table-paypal-review-items .col.item {
  display: flex;
  gap: 1.5em;
}

.table-paypal-review-items .product-item-photo {
  max-width: 100px;
}

.table-paypal-review-items .product-item-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.table-paypal-review-items th.mark {
  text-align: right;
}

.table-paypal-review-items .table-caption {
  display: none;
}

.paypal-review-title strong {
  font-size: 1.5rem;
  color: #de5e28;
  font-weight: 900;
  line-height: 1;
  font-family: Museo Sans Rounded, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.paypal-review-items .paypal-review-title .action.edit {
  display: inline-block;
  border: 2px solid #00477f;
  padding: 8px 16px;
  width: auto;
  vertical-align: middle;
  color: #00477f;
  font-family: Proxima Nova Soft, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  border-radius: 20px;
  -webkit-transition: border .3s ease, background .3s ease, color .3s ease;
  transition: border .3s ease, background .3s ease, color .3s ease;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 1em;
}

form#order-review-form {
  margin-left: auto;
}

#review-buttons-container .primary {
  text-align: right;
}

#review-buttons-container .primary .primary {
  font-size: 1.25rem;
  padding: 0.5em 2em;
}

.paypal-review-items .paypal-review-title .action.edit:hover {
  opacity: 0.7;
}

select#shipping-method {
  max-width: 400px;
}

.action.checkout.primary.no-checkout:disabled:hover {
  cursor: not-allowed;
  border-color: #002a4c;
  background: #00477f;
  color: #fff;
  text-decoration: none;
}
