#remember-me-box {
    .tooltip {
        &.wrapper {
            position: relative;

            &:hover .tooltip.content {
                display: block;
            }
        }

        &.toggle svg {
            color: #2b73c1;
            width: 1.2em;
            height: 1.2em;
            cursor: pointer;

            &:hover {
                color: #194674;
            }
        }

        &.content {
            position: absolute;
            top: -2.5em;
            left: 0;
            display: none;
            background: #efefef;
            width: max-content;
            max-width: 300px;
            padding: 0.5em 1em;
            font-size: 0.9em;
        }
    }
}
