/*========================================
PRICE
========================================*/

.price-box {
  margin-bottom: $half-spacing;

  p {
    margin-bottom: 0;
  }
}

.price-label {
}

.price {
  font-weight: bold;
  font-family: $sans-title;
}

// Normal
.regular-price {
}

// Special
.product-info-price {

  .special-price {
    .price-final_price {
      .price {
        color: #d73232;
      }
    }
  }

  .old-price {
    display: block;

    .price-label {
      display: none;
    }

    .price {
      font-family: $sans;
      font-weight: normal;
      text-decoration: line-through;
      font-size: 1em;
    }

    /*.price:before {
      content: 'Was ';
      line-height: 0.9em;
      display: block;
    }*/
  }

  .save-price {
    display: block;

    .price-label {
      display: none;
    }

    .price {
      //text-decoration: line-through;
      font-family: $sans;
      font-weight: normal;
      color: darkgreen;
      //color: $price-saved-color;
    }

    /*.price:before {
      content: 'Save ';
      line-height: 0.9em;
      display: block;
    }*/
  }

  .special-price {
    .price-label {
      display: none;
    }
  }
}

// Bundle
.price-from {
}

.price-to {
}

// Grouped
.minimal-price {
}

// Tiered
.minimal-price-link {
}
