body[class^='customer-account'] {
    //.page-main {
    //    padding: $base-spacing 0;
    //}

    .page-title {
        text-align: center;
    }
}

.form-container {
    max-width: $page-width;
    margin: 0 auto;

    form {
        @include media-query(min, $lap-start) {
            max-width: 25em;
            padding: $base-spacing;
            padding: $base-spacing;
            box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
        }

        margin: 0 auto;
    }
}

.actions-toolbar {
    .back {
        display: none;
    }
}

.form-create-account {
    .fieldset {
        br {
            display: none;
        }
    }
}
