/*================================================================================
SECTIONING
================================================================================*/

hr {
    clear: both;
    margin-bottom: $base-spacing;
    border: none;
    border-bottom: 1px solid $silver;
    padding-bottom: $half-spacing;
    height: 1px;
}
