.header-wrapper {
  padding: 0;

  @include media-query(min, $lap-start) {
    //padding: $half-spacing 0;
    height: auto;
  }

  @include media-query(min, $desk-start) {
    position: relative;
    //padding-top: $half-spacing;
    //padding-bottom: $half-spacing;
  }
}

// -----------------------------
// Wrapper for the entire header
// -----------------------------
.site-header {
  position: relative;
  @include clearfix;
}

// -------------------------------
// Container for logo + skip links
// -------------------------------
.controls {
  //@include clearfix;
  //position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

// ---------------------------------
// Container for the skip links only
// ---------------------------------
.controls__skip-links {
  padding: 15px 0;

  /*@include clearfix;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;

  @include media-query(min, $lap-start) {
      width: auto;
  }*/
}

.logo {
  display: block;
  font-size: 0;
  padding: 10px 5px;

  img {
    display: block;

    width: auto;
    max-height: 36px;

    @include media-query(min, $palm-start) {
      max-height: 40px;
    }

    @include media-query(min, $lap-start) {
      max-height: 45px;
    }

    @include media-query(min, $desk-start) {
      max-height: 50px;
    }
  }
}

.header.links {
  display: none;
  @include list-reset;
  @include media-query(min, $lap-start) {
    display: flex;
    justify-content: flex-end;
    > * {
      margin-left: $half-spacing;
    }
  }
}

.sales-order-print {
  .logo {
    display: block;
    float: none;
    margin: 0 auto;
    padding-top: $base-spacing;
  }

  .order-status {
    margin-top: $half-spacing;
  }

  .columns {
    padding-top: 0;
  }
}
