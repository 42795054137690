@charset "UTF-8";

/*========================================
NAVS
========================================*/

/**
 * Inline using floats
 */
.nav {
    @include clearfix();
    @include list-reset();

    > li {
        // float: left;

        + li {
            // margin-left: $base-spacing;
        }

        > a {
            // display: inline-block;
            display: block;
        }

        &.active > a {
            cursor: default;
        }
    }
}

/**
 * Vertical using block
 */
.nav--stacked {
    @extend .nav;

    > li {
        display: block;
    }
}

/**
 * Horizontal using inline-block
 */
.nav--inline {
    @extend .nav;

    > li {
        display: inline-block;

        > a {
            display: inline-block;
        }
    }
}

/**
 * Horizontal using float
 */
.nav--float {
    @extend .nav;

    > li {
        float: left;
    }
}

/**
 * Tabs
 */
.nav--tabs {
    @extend .nav--float;
    border-bottom: 1px solid $silver;
    text-align: center;

    > li {
        margin-bottom: -1px;

        + li {
            margin-left: $half-spacing/2;
        }

        > a {
            padding: $half-spacing $half-spacing $half-spacing - 1;
            border-radius: $base-radius $base-radius 0 0;
            text-decoration: none;

            &:hover {
                background: $smoke;
            }
        }

        &.active > a {
            border: 1px solid $silver;
            border-bottom: 1px solid white;
            padding: $half-spacing - 1px;
            background: none;
            color: inherit;
        }
    }
}

.tab__body {
    @include clearfix();
    margin-bottom: $base-spacing;
    @extend .hidden;

    > :last-child {
        margin-bottom: 0;
    }
}

/**
 * Full-width tabs
 */
.tabs--full {
    > li {
        + li {
            margin-left: 0;
        }
    }
}

.tabs-2up > li {
    width: 50%;
}

.tabs-3up > li {
    width: 33.33333%;
}

.tabs-4up > li {
    width: 25%;
}

.tabs-5up > li {
    width: 20%;
}

/**
 * Pills
 */
.nav--pills {
    @extend .nav--float;
    text-align: center;

    > li {
        + li {
            margin-left: $half-spacing/2;
        }

        > a {
            padding: $half-spacing;
            border-radius: $base-radius;
            text-decoration: none;

            &:hover {
                background: $smoke;
            }
        }

        &.active > a {
            border: 1px solid $silver;
            padding: $half-spacing - 1px;
            background: none;
            color: inherit;
        }
    }
}

/**
 * Pagination
 */
.nav--pages {
    @extend .nav--float;
    text-align: center;

    > li {
        border-left: 1px solid $silver;

        &:first-child {
            border-left: 0;
        }

        > a {
            padding: $half-spacing;
            text-decoration: none;

            &:hover {
                background: $smoke;
            }
        }

        &.active > a {
            background: none;
            color: inherit;
        }
    }

    .nav-prev {
    }

    .nev-next {
    }
}

/**
 * Breadcrumbs
 */
.nav--breadcrumb {
    @extend .nav--inline;
    overflow: hidden;

    > li {
        + li {
            margin-left: $half-spacing / 2;

            &:before {
                content: '/';
                display: inline-block;
                margin-right: $half-spacing / 2;
            }
        }
    }

    // @include media-query(palm) {
    //     > li {
    //         display: none;

    //         &:nth-last-child(2) {
    //             display: inline-block;
    //             float: none;
    //         }
    //         a {
    //             display: inline-block;
    //         }
    //     }
    //     > li + li {
    //         margin-left: 0;

    //         &:before {
    //             content: "←";
    //             float: left;
    //         }
    //     }
    // }
}
