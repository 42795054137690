.gallery-wrapper {
  position: relative;
  background: $gallery-bg;
  border: 2px solid $gallery-bg;
  border-bottom: 0;
  text-align: center;

  .gallery-zoom {
    @include button;
    padding: $half-spacing/2 $base-spacing/2;
    display: inline;
    position: relative;
    bottom: 0;
    font-size: 0.85em;
    z-index: 1;
    margin-bottom: 0;
  }
}

.gallery {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 1px;
  z-index: 1;
  overflow: hidden;

  .flickity-prev-next-button {
    background: none;
  }

  &.flickity-enabled {
    background: none;
  }

  .dot {
    background: $white;
    border-color: $grey-lt;

    &.is-selected {
      border-color: $gallery-selected-border-color;
    }
  }

  .flickity-page-dots {
    //bottom: $base-spacing;
    display: none;
    //@include media-query(min, $lap-start) {
    //}
  }
}

.gallery-cell {
  width: 100%;
  font-size: 0;
  height: 100%;
  transition: opacity 0.2s, transform 0.2s;
  opacity: 1;
  background: $white;
  padding: $base-spacing;

  &.is-selected {
    cursor: zoom-in;
  }

  img {
    width: 100%;
    display: block;

    &.no--border {
      border: 0;

      &.flickity-lazyloaded {
      }
    }
  }
}

.is-expanded {
  width: 50%;
}

.gallery-cell-image {
  display: block;
  max-height: 100%;
  margin: 0 auto;
  max-width: 100%;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

/* fade in lazy loaded image */
.gallery-cell-image.flickity-lazyloaded,
.gallery-cell-image.flickity-lazyerror {
  opacity: 1;
}

.flickity-prev-next-button {
}

.flickity-prev-next-button.previous {
  left: 0;
}

.flickity-prev-next-button.next {
  right: 0;
}

.gallery-thumbs {
  font-size: 0;
  padding: $half-spacing $half-spacing 0;
  background: $gallery-bg;
}

.gallery-thumb {
  display: inline-block;
  max-width: 50px;
  font-size: 0;
  margin-right: $half-spacing;
  margin-bottom: $half-spacing;
  position: relative;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid $gallery-selected-border-color;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &.is-selected {
    &:after {
      opacity: 1;
    }
  }
}

.gallery-videos {
  padding: 0 $half-spacing $half-spacing;
  background: $gallery-bg;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0.5em;
}

.gallery-video {
  display: flex;
  background: $white;
  max-height: 85px;

  &:last-child {
    margin-bottom: 0;
  }

  .has-mouse & {
    &:hover {
      .gallery-video__icon {
        transform: translateX(-50%) translateY(-50%) scale(1.1);
      }
    }
  }
}

.gallery-video__img {
  width: 100%;
  margin-bottom: 0;
  position: relative;
  display: block;
  height: 85px;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid $white;
  background-position: center center;

  img {
    width: 100%;
  }
}

.gallery-video__icon {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  background: $gallery-video-btn-bg;
  transition: transform 0.1s;
  transform: translateX(-50%) translateY(-50%);

  .svg-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    color: $white;
  }
}

.gallery-video__caption {
  width: 50%;
  padding: $half-spacing;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.action-skip-wrapper {
  display: none;
}
