/*========================================
VIEWER
========================================*/

.viewer {
    @include clearfix();

    label {
        display: inline;
        margin: 0 $half-spacing/2 0 0;
        font-weight: normal;
    }

    select {
    }
}
