/**
 * Checkout Page Styles
 */

.checkout-index-index {
  .header-wrapper {
    padding-bottom: 0;
  }

  //Hides the feefo widget on the checkout pages
  feefowidget-container-floating-service {
    display: none;
  }

  .container {
    //max-width: $desk-start;
    margin: 0 auto;
    padding: 0;
  }

  .columns {
    padding-top: $base-spacing;
  }

  .site-search {
    display: none;
  }

  .site-nav {
    display: none;
  }

  .page-top {
    display: none;
  }

  .minicart {
    display: none;
  }

  .controls__skip-links {
    display: none;
  }

  .logo {
    float: none;
    margin: 0 auto;
    max-width: 260px;
    height: auto;
  }

  .authentication-wrapper {
    position: relative;
    margin-bottom: $base-spacing;
    text-align: center;

    .modal-inner-wrap {
      text-align: left;

      .action.action-login {
        @include button-reset;
        @include button;
        width: 100%;
      }
    }
  }

  .authentication-dropdown {
    @include media-query(min, 768px - 15px) {
      left: calc(50% - 200px);
      max-width: 400px;
    }

    &._show {
      .block-customer-login {
        width: 100%;

        .actions-toolbar {
          display: flex;
          align-items: baseline;

          .primary {
            margin-bottom: 0;
            flex-shrink: 0;
          }

          div.secondary {
            padding-top: 0;

            .action {
              @include font-size(14px);
              margin-left: $half-spacing;
            }
          }
        }
      }
    }
  }

  .opc-progress-bar {
    display: none;

    @include media-query(min, 768px) {
      display: flex;
      justify-content: center;
    }
  }

  .action-auth-toggle {
    border: 0;
    outline: 0;
    box-shadow: 0 0 0 0;
    @include button;
  }
}

.checkout-onepage-success {

  .page-main {
    display: flex;
    flex-direction: column;
    margin: 1em .5em;
  }

  .checkout-success {
    text-align: center;
    margin-bottom: .5em;
    padding: .75em;
  }

  .registration {

    br {
      display: none;
    }

    div:last-of-type {
      background: $theme-color-one-light;
      margin-bottom: .5em;
      padding: .75em;
      border-radius: .5em;
      text-align: center;

      .action.primary {
        @include button;
      }
    }
  }
}
