.prescription-popup {
    .mfp-content {
        background: $white;
        max-width: 750px;
        margin: 0 auto;
        padding: $base-spacing $base-spacing * 2;
    }

    .number-list {
        @include list-reset;
        counter-reset: list-items;
        padding-top: $base-spacing;

        li {
            counter-increment: list-items;
            padding-left: $base-spacing * 3;
            position: relative;
            min-height: 60px;

            &:before {
                position: absolute;
                content: counter(list-items);
                top: 0;
                left: 0;
                line-height: 1;
                @include font-size(50px);
            }
        }
    }
}
