.tiles__title {
    @include font-size($h1-font-size);
    color: $theme-color-one;
    text-align: center;

    @include media-query(min, $desk-start) {
        padding-top: 0;
    }
}

.tiles__sub-title {
    @include font-size($lede-font-size);
    margin-bottom: $base-spacing;
    text-align: center;
}

.tiles__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 $base-spacing 0;
    border: $tile-border-color solid 2px;
    border-radius: $base-spacing;
}

.tiles__item--header {
    justify-content: center;
    align-items: center;
    font-weight: $heading-font-weight;
    text-align: center;
    margin-bottom: $base-spacing;
    @include font-size($h1-font-size);
}

.tiles__item {
    display: flex;
    width: 50%;
    border-right: $tile-border-color solid 2px;
    border-bottom: $tile-border-color solid 2px;

    @include media-query(max, $palm-start) {
        &:nth-child(even) {
            border-right: none;
        }

        &:nth-last-child(-n + 2) {
            border-bottom: none;
        }
    }

    @include media-query(min, $palm-start) {
        width: 33.3333%;
    }

    @include media-query-bracket($palm-start, $lap-start) {
        &:nth-child(3n + 3) {
            border-right: none;
        }

        &:nth-last-child(-n + 3) {
            border-bottom: none;
        }
    }

    @include media-query(min, $lap-start) {
        width: 16.6666%;
        border-bottom: none;

        &:last-child {
            border-right: none;
        }
    }
}

.tiles__link {
    position: relative;
    display: block;
    width: 100%;
    padding: $half-spacing;
    text-decoration: none;
    line-height: 1.25;
    transition: all ease 0.3s;
    border: 2px solid $white;
    border-radius: $base-spacing;
    overflow: hidden;

    &:hover {
        z-index: 1;
        cursor: pointer;
        transform: scale(1.1);
        transform-origin: center;
        background-color: $white;
        box-shadow: 0 6px 23px $silver;
        border: 2px solid $theme-color-one;
    }
}

.tiles__image {
    display: block;
    margin: 0 auto $half-spacing;
    width: 100%;
}

.tiles_meta {
    font-weight: bold;
    text-align: center;
    margin: 0;
    @include font-size($base-font-size);
}
