.wp-teasers {
    background-color: $cream-light;
    padding-bottom: 0;
}

.wp-teasers__container {
    @include media-query(min, $lap-start) {
        padding: 0 $half-spacing;
    }

    @include media-query(min, $desk-start) {
        padding: 0;
    }
}

.wp-teasers__intro {
    @include media-query(min, $desk-start) {
        padding: 0 $double-spacing * 3;
        margin-bottom: $base-spacing;
    }

    @include media-query(min, $wide-start) {
        padding: 0 $double-spacing * 5;
    }
}

.wp-teasers__title {
    color: $theme-color-one;
    font-size: $h2-font-size;
    text-align: center;
    margin: 0;
    @include media-query(min, $lap-start) {
        font-size: $h1-font-size;
    }

    @include media-query(min, $desk-start) {
        margin-bottom: $base-spacing;
    }
}

.wp-teasers__subtitle {
    text-align: center;
}

.wp-teasers__posts {
    width: 100%;

    @include media-query(min, $lap-start) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.wp-teasers__item {
    width: 100%;
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
        padding: 0 $half-spacing;
        width: 50%;
    }

    @include media-query-bracket($lap-start, $wide-start) {
        &:nth-child(3n),
        &:nth-child(4n) {
            margin-bottom: 0;
        }
    }

    @include media-query(min, $desk-start) {
        padding: 0 $base-spacing;
        margin-bottom: $double-spacing;
    }

    @include media-query(min, $wide-start) {
        padding: 0 $base-spacing;
        width: 25%;
        margin-bottom: 0;
    }
}

.teaser {
    position: relative;
    height: 100%;
    background: $white;
    border: 2px solid $cream;
    border-radius: $base-spacing;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus {
        transform: scale(1.02);
        border-color: $theme-color-one;
        text-decoration: none;

        .teaser__title a {
            color: $link-hover;
        }

        .teaser__summary a {
            color: $link-hover;
        }
    }
}

.teaser__image-link {
    display: block;
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    border-top-left-radius: $base-spacing;
    border-top-right-radius: $base-spacing;
}

.teaser__content {
    position: relative;
    padding: $base-spacing;
}

.post-area__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-decoration: none;
    /* Makes sure the link doesn't get underlined */
    z-index: 1;
    /* raises anchor tag above everything else in div */
    background-color: white;
    /*workaround to make clickable in IE */
    opacity: 0;
    /*workaround to make clickable in IE */
    filter: alpha(opacity=0);
    /*workaround to make clickable in IE */
}

.teaser__title {
    margin: 0;
    padding: 0;
    margin-bottom: $half-spacing;
    vertical-align: middle;
    a {
        color: $link;
        font-size: $h3-font-size;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $link-hover;
        }
    }
}

.teaser__summary {
    a {
        text-decoration: none;
        &:hover,
        &:focus {
            color: $link-hover;
        }
    }
}
