/*========================================
FLYOUT aka SUCKERFISH
========================================*/

.flyout,
.flyout--rev,
.flyout--alt {
    position: relative;
    // overflow: hidden;

    &:hover {
        // overflow: visible;
    }
}
.flyout__body {
    position: absolute;
    top: 100%;
    left: -9999px;
    z-index: 1;
    margin: 0;
    border: 1px solid $silver;
    padding: $half-spacing;
    // min-width: 200px;
    background: $white;
    white-space: nowrap;

    li {
        float: none;
        display: block;
    }
}
.flyout:hover > .flyout__body {
    left: 0;
}
.flyout--rev:hover > .flyout__body {
    left: auto;
    right: 0;
}
.flyout--alt:hover > .flyout__body {
    left: 0;
    top: auto;
    bottom: 100%;
}
