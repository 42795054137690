.page.messages {
    @include container;
    margin-bottom: 0;
}

.message {
    border-radius: .5em;
    margin-bottom: .75em;
    margin-top: 0;

    &.info,
    &.success,
    &.error,
    &.warning,
    &.notice {
        padding: $half-spacing;
    }

    &.info {
        background: rgba($yellow, 0.1);
        border: 1px solid rgba($yellow, 0.5);
    }
    &.warning,
    &.notice {
        background: rgba($blue, 0.1);
        border: 1px solid rgba($blue, 0.5);
    }
    &.error {
        background: rgba($failure, 0.1);
        border: 1px solid rgba($failure, 0.3);
    }
    &.success {
        background: rgba($success, 0.1);
        border: 1px solid rgba($success, 0.3);
    }

    > [data-bind='html: message.text'] {
    }
}

.cart.item.message {
    margin: 0;
    margin-bottom: $half-spacing;
    padding: $half-spacing/2 $half-spacing;

    @include font-size(14px);

    &.notice {
        background: rgba($yellow, 0.1);
        border: 1px solid rgba($yellow, 0.5);
    }
    &.error {
        background: rgba($failure, 0.1);
        border: 1px solid rgba($failure, 0.3);
    }
}
