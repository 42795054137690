.page-hero {

  .container {
    color: $theme-color-one;
    text-align: center;
    //background-color: $page-hero-bg;
    z-index: 0;
    //border-radius: .75em;
    position: relative;
    //overflow: hidden;

    > :last-child {
      margin-bottom: 0;
    }
  }

  @include media-query(min, $lap-start) {
    padding-top: $page-hero-spacing / 2;
    padding-bottom: $page-hero-spacing / 2;
  }

  .page-title-wrapper {
    padding-top: 0;
  }

  .page-title {
    color: $theme-color-one;
    font-size: $page-hero-font-size;
    margin-bottom: $base-spacing / 2;

    @include media-query(min, $lap-start) {
      font-size: 1.5em;
    }
  }
}

.category-description {
  max-width: 650px;
  margin: 0 auto;
  padding: 0 1em;
}

.page-hero--category {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: .75em;

  > .container {
    padding: .5em;
    margin: 0 .75em 0;

    .category-description {

      > div {
        position: relative;

        > p:first-of-type {
          font-size: 0.9em;
          text-overflow: ellipsis;
          margin-right: 1em;
        }

        &:not(.active) > p:first-of-type {
          max-width: calc(100% - 8px);
          overflow: hidden;
          white-space: nowrap;
        }
      }

      > div:has(p) {
        &:hover {
          cursor: pointer;
        }

        &:after {
          content: ' ';
          width: 20px;
          height: 20px;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #1a4b78;
          -webkit-mask-image: url(../img/svg/ic_expand_more.svg);
          mask-image: url(../img/svg/ic_expand_more.svg);
        }
      }
    }
  }
}

//----------------------------------------------
// Alt layout is when there's an offset overlay
//----------------------------------------------
.page-hero--promo {
  padding-bottom: 0;
  margin-bottom: $base-spacing;

  &:after {
    content: ' ';
    width: 100%;
    height: 40px;
    background: $white;
    position: absolute;
    z-index: 1;
    display: block;
    bottom: 0;
  }
}

//----------------------------------------------
// Alt Background when a 2 tone is required
//----------------------------------------------
$bg-blue-alt: #062553;
.page-hero--2tone {
  background: $blue;
  @include media-query(min, $lap-start) {
    background: linear-gradient(
                    to bottom,
                    $blue,
                    $blue 65%,
                    $bg-blue-alt 65%
    );
  }
  @include media-query(min, $desk-start) {
    background: linear-gradient(
                    to bottom,
                    $blue,
                    $blue 57%,
                    $bg-blue-alt 57%
    );
  }
}

.page-hero--crowd {
  background-color: $blue;

  background-image: url('../img/daylong-crowd.png');
  background-repeat: no-repeat;
  background-size: 800px, auto;
  background-position: top center;
  padding-top: 80px;
  @include media-query(min, $lap-start) {
    background-size: contain, auto;
    background-image: url('../img/daylong-crowd.png'),
    linear-gradient(to bottom, $blue, $blue 10%, $bg-blue-alt 10%);
    padding-top: 140px;
  }
  @include media-query(min, $desk-start) {
    background-image: url('../img/daylong-crowd.png'),
    linear-gradient(to bottom, $blue, $blue 18%, $bg-blue-alt 18%);
    padding-top: 200px;
  }
}

//----------------------
//
//----------------------
.page-hero__lede {
  p {
    color: $white;
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
      @include font-size(20px);
    }
  }

  a {
    color: $yellow;

    &:hover,
    &:focus {
      color: $yellow-lt;
    }

    &:active {
      color: $yellow-dk;
    }
  }
}

//----------------------
//
//----------------------
.page-hero__scroller {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 10px;
  display: block;

  > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: .5em;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.page-hero__links {
  overflow: hidden;
  max-height: 30px;

  a {
    display: inline-block;
    padding: .25em 1em;
    background: $theme-color-four-light;
    border-radius: 1em;
    font-size: 0.9em;
    font-weight: bold;
    text-decoration: none;
    color: $theme-color-four-dark;

    &:hover,
    &:focus,
    &:active {
      color: lighten($theme-color-one, 20%);
    }
  }
}

.category-description {
  a {
    display: inline-block;
    color: $theme-color-two;

    &:hover,
    &:focus,
    &:active {
      color: lighten($theme-color-one, 20%);
    }
  }
}
