/*========================================
HEADINGS
========================================*/

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    padding-top: $half-spacing;
    color: $heading-color;
    font-weight: $heading-font-weight;
    line-height: $heading-line-height;
    font-family: $heading-font-family;
    -webkit-font-smoothing: antialiased;

    small {
        font-size: inherit;
        font-weight: normal;
    }
}

h1,
.h1 {
    @include font-size($h1-font-size);
    color: $theme-color-one;
}

h2,
.h2 {
    @include font-size($h2-font-size);
    color: $theme-color-one;
}

h3,
.h3 {
    @include font-size($h3-font-size);
    color: $theme-color-one;
}

h4,
.h4 {
    @include font-size($h4-font-size);
    color: $theme-color-one;
}

h5,
.h5 {
    @include font-size($h5-font-size);
    color: $theme-color-one;
}

h6,
.h6 {
    @include font-size($h6-font-size);
    color: $theme-color-one;
}
