.review-items {
    @include list-reset;
    margin-bottom: 0;

    @include media-query(min, $desk-start) {
        padding-top: $base-spacing;
    }
}

.review-item {
    margin-bottom: $base-spacing;
    border-bottom: 1px solid $cream;
    padding-bottom: $base-spacing;

    &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.review-title {
    @include font-size(18px);
    margin-bottom: $half-spacing/2;
    font-weight: bold;
}

.review-ratings {
    margin-bottom: $half-spacing/2;

    @include media-query(min, $desk-start) {
        margin-bottom: $base-spacing;
    }

    .rating-summary {
        display: flex;
        align-items: center;
    }

    .rating-label {
        margin-right: $half-spacing;
        @include font-size(14px);
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: bold;
    }
}

.review-content {
    margin-bottom: $half-spacing/2;

    @include media-query(min, $desk-start) {
        margin-bottom: $base-spacing;
    }
}

.review-details {
    @include clearfix;
    @include font-size(14px);
    color: $grey-lt;
    > * {
        float: left;
    }
}

.review-author {
    margin-bottom: 0;
    margin-right: $half-spacing;
}

.review-date {
}
