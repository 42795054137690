/*========================================
MEDIA
========================================*/

.media {
    @include clearfix();
    display: block;
}
.media > a {
    display: block;
}
.media__img {
    float: left;
    margin-right: $base-spacing;
}
.media__img--rev {
    float: right;
    margin-left: $base-spacing;
}
.media__img--palm {
    @include media-query(palm) {
        float: left;
        margin-right: $base-spacing;
    }
}
.media__img--lap {
    @include media-query(lap) {
        float: left;
        margin-right: $base-spacing;
    }
}
.media__img--desk {
    @include media-query(desk) {
        float: left;
        margin-right: $base-spacing;
    }
}
.media__img img,
.media__img--rev img,
.media__img--palm img,
.media__img--lap img,
.media__img--desk img {
    display: block;
}
.media__body {
    overflow: hidden;
}
.media__body,
.media__body > :last-child {
    margin-bottom: 0;
}
