.product-item-photo {
  display: block;
}

.product-image-container {
  img {
    display: block;
    width: 100%;
  }
}

.product-image-wrapper {
  display: block;
}

.product-item-name {
  display: block;
  margin-bottom: $half-spacing;
}

.product-item-link {
  @include font-size(18px);
  text-decoration: none;
  display: block;
}

.info-tool-tip {
  // is the functionality for this fone
  @include font-size(14px);
  color: $white;
  background: $link;
  text-decoration: none;
  padding: 0 5px;
  border-radius: 50px;
  font-weight: normal;
  margin-left: 5px;

  &:hover,
  &:focus {
    background: lighten($blue, 20%);
    color: $white;
  }

  &:active {
    background: darken($blue, 30%);
  }
}

.product-bullets {
  @include list-reset;
  font-weight: bold;

  .svg-icon {
    height: 18px;
    width: 18px;
  }
}

.page-products {

    h3 {
        font-size: 1.225em;
    }

    dl.faq {
        dt {
            font-size: 1.1rem;
            color: $theme-color-one;
            padding: 10px 0;
            font-weight: 900;
            line-height: 1;
            font-family: Museo Sans Rounded, Helvetica, Arial, sans-serif;
            display: block;

            &:after {
                content: '';
            }
        }
    }
}
