.product-tile {
    display: block;
    text-decoration: none;
    overflow: hidden;
    text-align: center;
    margin-bottom: $base-spacing;
    // styling
    background: $pale-blue;
    border: 2px solid $pale-blue;
    border-radius: $double-radius;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus {
        transform: scale(1.02);
        text-decoration: none;
    }

    @include media-query(min, $desk-start) {
        margin-bottom: $double-spacing;
    }

    .product-reviews-summary {
        text-align: center;
        color: $black;
        justify-content: center;
        margin-bottom: $base-spacing;
        @include media-query(min, $lap-start) {
            margin-bottom: $half-spacing;
        }
        @include media-query(min, $desk-start) {
            margin-bottom: -$half-spacing;
        }
    }

    // interaction
    .has-mouse & {
        &:hover,
        &:focus {
            background: $blue;
            border-color: $blue;

            .product-tile__title {
                color: $white;
            }

            .product-tile__price {
                color: $white;
            }

            .product-tile__cta {
                color: $white;
            }
        }
    }
}

.product-tile--plain {
    background: $white;
    border-color: lighten($theme-color-two, 20%);

    .has-mouse & {
        &:hover,
        &:focus {
            background: $white;
            border-color: lighten($theme-color-two, 10%);

            .product-tile__title {
                color: $link-hover;
                text-decoration: none;
            }

            .product-tile__price {
                color: $black;
            }

            .product-tile__cta {
                color: $blue;
            }
        }
    }
}

//-----------------------------------
// for when you only want a text-link
// note: this uses a hidden-dummy image to m
// maintain height and can be rewritten to
// use flexbox etc...
//-----------------------------------
.product-tile--hidden {
    background: $white;
    position: relative;

    .product-tile__img {
        opacity: 0;
    }

    .product-tile__price {
        opacity: 0;
    }

    .product-tile__title {
        opacity: 0;
    }

    .product-tile__cta {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        font-weight: bold;
        @include font-size(18px);
        margin-bottom: 0;
        padding-left: $base-spacing;
        padding-right: $base-spacing;
    }
}

//-------------------------
// The figure + img wrapper
//-------------------------
.product-tile__img {
    display: block;
    padding: $base-spacing $base-spacing * 2;
    background: $white;
    margin-bottom: 0;

    .product-image-container {
        img {
            border: 0;
        }
    }

    img {
        margin-left: auto;
        margin-right: auto;
    }
}

//-------------------------
// The title for each product-tile
//-------------------------
.product-tile__title {
    font-weight: 700;
    margin: .5em 0;
    text-decoration: none;
    font-size: 1em;
    line-height: 1.2em;

    @include hover {
        text-decoration: underline;
    }
}

.product-tile__summary {
    color: $black;
    padding-left: $half-spacing;
    padding-right: $half-spacing;
}

//------------------------
// The price for each product-tile
//------------------------
.product-tile__price {
    color: $black;
    padding-left: $half-spacing;
    padding-right: $half-spacing;

    .price {
        color: $black;
    }

    .save-price {
        .price {
            color: $theme-color-one;
        }
    }

    @include media-query(min, $page-width) {
        .price-box {
            display: flex;
            justify-content: center;

            > * {
                margin-left: $half-spacing;
                white-space: nowrap;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

.product-tile__cta {
}

.product__alternatives {
    width: 66%;
    margin: 0 auto;

    @include media-query(min, $desk-start) {
        width: auto;
    }

    @include media-query(min, $lap-start) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.product__alternative-items {
    width: 100%;
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
        padding: 0 $half-spacing;
        width: 50%;
    }

    @include media-query-bracket($lap-start, $wide-start) {
        &:nth-child(3n),
        &:nth-child(4n) {
        }
    }

    @include media-query(min, $desk-start) {
        padding: 0 $base-spacing;
        margin-bottom: $double-spacing;
    }

    @include media-query(min, $desk-start) {
        padding: 0 $base-spacing;
        width: 25%;
    }
}

.product-tile.product-tile--plain {
    height: 100%;
}
