$pswp__background-color: $white;
$pswp__placeholder-color: $white;
$pswp__assets-path: '../img/photoswipe/'; // path to skin assets folder (preloader, PNG and SVG sprite)

@import '../../../../node_modules/photoswipe/src/css/main';
@import '../../../../node_modules/photoswipe/src/css/default-skin/default-skin';

.pswp__img--placeholder {
    //background: url($preloader) no-repeat center center;
}

.pswp__top-bar {
    //background: $pink !important;
}

.pswp__button {
    &:before {
        //background-color: $pink;
    }
}

.pswp__caption__center {
    text-align: center;
    color: $white;
}
