.featured-tile {
    display: flex;
    flex-wrap: wrap;
}

.featured-tile__item {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: $base-spacing + $half-spacing;
    border: 2px solid $cream;
    background-color: #fcf7f2;
    border-radius: $base-radius * 4;
    overflow: hidden;

    @include media-query(min, $lap-start) {
        flex-direction: column;
        width: calc(50% - #{$half-spacing});
        margin-right: $base-spacing;

        &:last-child {
            margin-right: 0;
        }
    }

    @include media-query(min, $desk-start) {
        flex-direction: row;
        width: calc(50% - #{$base-spacing});
        margin-right: $base-spacing * 2;
    }

    .product-flag {
        position: absolute;
        top: $half-spacing;
        left: $half-spacing;
        border-radius: $base-spacing;
        border: 2px solid $white;
        padding: 6px 14px 4px;
        @include font-size($small-font-size);
        letter-spacing: 0.05em;
        z-index: 1;

        @include media-query(min, $lap-start) {
            @include font-size($base-font-size);
        }
    }

    transition: all ease 0.3s;
    &:hover {
        transform: scale(1.05);
        transform-origin: center;
        box-shadow: 0 6px 23px $silver;
        border-color: $theme-color-one;
    }
}

.featured-tile__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.featured-tile__image {
    display: flex;
    align-items: center;
    position: relative;
    width: 35%;
    margin: 0;
    background-image: url('../img/tile-bg.svg');
    background-repeat: no-repeat;
    background-position: bottom right -1px;
    background-size: contain;

    @include media-query-bracket($lap-start, $desk-start) {
        background-image: url('../img/tile-bg-bottom.svg');
        background-repeat: no-repeat;
        background-position: bottom -1px left;
        background-size: 100%;
    }

    img {
        margin: 0 auto;
    }

    @include media-query(min, $lap-start) {
        width: 100%;
        padding-left: 0;
    }

    @include media-query(min, $desk-start) {
        padding-left: $half-spacing;
        width: 35%;
    }

    a {
        display: block;

        &:hover,
        &:focus {
            cursor: pointer;
        }

        @include media-query-bracket($lap-start, $desk-start) {
            max-width: 50%;
            margin: 0 auto;
        }
    }
}

.featured-tile__content {
    width: 65%;
    padding: $half-spacing;
    background-color: $white;
    position: relative;

    @include media-query(min, $palm-start) {
        padding-left: $base-spacing;
    }

    @include media-query(min, $lap-start) {
        width: 100%;
        flex-grow: 1;
        padding-right: $base-spacing;
        padding-bottom: $base-spacing;
    }

    @include media-query(min, $desk-start) {
        padding: $base-spacing;
        width: 65%;
    }
}

.featured-tile__price {
    background-color: $white;

    .price-box {
        margin-bottom: ($half-spacing / 2);

        @include media-query(min, $palm-start) {
            margin-bottom: $half-spacing;
        }
    }

    .price {
        @include font-size($mono-font-size);

        @include media-query(min, $palm-start) {
            @include font-size($h3-font-size);
        }
    }
}

.featured-tile__brand-image {
    display: block;
    max-width: 60px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 5px;
    margin-bottom: 3px;

    @include media-query(min, $palm-start) {
        max-width: 80px;
    }
}

.featured-tile__title {
    margin-bottom: ($half-spacing / 2);
    color: $blue-lt;
    line-height: 1.3;
    @include font-size($mono-font-size);

    @include media-query(min, $palm-start) {
        margin-bottom: $half-spacing;
        @include font-size($h3-font-size);
    }

    a {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.featured-tile__desc {
    @include font-size($small-font-size);

    @include media-query(min, $palm-start) {
        @include font-size($mono-font-size);
    }

    @include media-query(min, $lap-start) {
        @include font-size($base-font-size);
    }
}

.featured-tile__spec {
    @include font-size($small-font-size);
    list-style: none;
    margin-left: 0;
    margin-bottom: 15px;

    @include media-query(min, $palm-start) {
        @include font-size($mono-font-size);
    }

    @include media-query(min, $lap-start) {
        @include font-size($base-font-size);
    }

    li {
        &:before {
            content: '– ';
        }
    }
}

.featured-tile__more {
    background-color: $yellow;
    border-color: $yellow;
    color: $blue;
    margin-bottom: 15px;
    .svg-icon {
        width: $half-spacing;
        height: $half-spacing;
    }
}

//=====================================================================
// Category page featured product
//=====================================================================

.featured-tile__item--full {
    width: 100%;
    border-color: $sky-lt;
    background-color: $sky-lt;
    flex-direction: column;

    @include media-query(min, $lap-start) {
        flex-direction: row;
    }

    .featured-tile__image {
        width: 100%;
        background-image: url('../img/tile-bg-bottom.svg');
        background-repeat: no-repeat;
        background-position: bottom -1px left;
        background-size: 100%;

        @include media-query(min, $lap-start) {
            background-image: url('../img/tile-bg.svg');
            background-repeat: no-repeat;
            background-position: bottom right -1px;
            background-size: contain;
            width: 30%;
        }

        @include media-query(min, $desk-start) {
            width: 35%;
        }

        a {
            max-width: 50%;
            margin: 0 auto;

            @include media-query(min, $lap-start) {
                max-width: 100%;
            }
        }
    }

    .featured-tile__content {
        width: 100%;
        padding-bottom: 0;

        @include media-query(min, $lap-start) {
            width: 50%;
            padding-bottom: $half-spacing;
        }

        @include media-query(min, $desk-start) {
            //width: 65%;
        }
    }

    .featured-tile__title {
        line-height: 1.3;
        @include font-size($h3-font-size);

        @include media-query(min, $desk-start) {
            @include font-size($h2-font-size);
        }
    }

    .featured-tile__desc {
        @include font-size($mono-font-size);

        @include media-query(min, $lap-start) {
            @include font-size($base-font-size);
        }
    }

    .featured-tile__spec {
        @include font-size($mono-font-size);

        @include media-query(min, $lap-start) {
            @include font-size($base-font-size);
        }
    }

    .featured-tile__price {
        width: 100%;
        padding: 0 $half-spacing $half-spacing;

        @include media-query(min, $palm-start) {
            padding: 0 $base-spacing $half-spacing;
        }

        @include media-query(min, $lap-start) {
            width: 20%;
            padding: $half-spacing $base-spacing $half-spacing;
        }

        @include media-query(min, $desk-start) {
            padding: $base-spacing $base-spacing $half-spacing;
        }

        .price-box {
            @include media-query(min, $lap-start) {
                padding-top: $half-spacing;
                text-align: right;
            }
        }

        .price {
            line-height: 1.3;
            @include font-size($h3-font-size);

            @include media-query(min, $desk-start) {
                @include font-size($h2-font-size);
            }
        }

        .product-reviews-summary {
            @include media-query(min, $lap-start) {
                justify-content: flex-end;
            }
        }
    }
}
