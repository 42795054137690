// -------------------------------------
// User nav is the dynamically populated
// Account navigation
// -------------------------------------
.user-nav {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    //top: 100%;
    z-index: 50;
    padding-top: 0;
    margin-top: 4em;

    > .container {
        background: $white;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
        padding-left: 0;
        padding-right: 0;
    }

    .user-nav--open & {
        opacity: 1;
        visibility: visible;

        @include media-query(min, $lap-start) {
            top: 50px;
            max-width: $page-width;
            left: 50%;
            transform: translateX(-50%);

            > .container {
                color: $blue;
                width: 300px;
                border: 1px solid $silver-lt;
                position: absolute;
                //right: $base-spacing * 1.5;

                right: 79px;
                top: -54px;
            }
        }
    }
}

.user-nav__items {
    @include list-reset;
    @include media-query(min, $lap-start) {
        margin-bottom: 0;
        @include mage-arrow;
    }
}

.user-nav__link {
    display: block;
    padding: $half-spacing $base-spacing;

    @include hover {
        text-decoration: none;
    }
}
