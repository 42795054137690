// ---------------------------
// .skip is an element generally used to
// toggle another element. They are in the
// Header section such as the burger button,
// search button, account nav dropdown etc etc
// ---------------------------
.skip {
  //display: block;
  text-align: center;
  color: $black;
  //width: $base-spacing * 2; // 60px;
  //height: $base-spacing * 2; // 60px;
  text-decoration: none;
  //position: relative;
  //float: left;
  //margin-left: $half-spacing;

  display: flex;
  position: relative;

  @include media-query(min, $lap-start) {
    width: auto;
    height: auto;
  }

  svg {
    color: $skip-svg-color;
  }

  &:active,
  &:focus,
  &:hover {
    //color: $white;
  }

  &:focus {
    //color: $blue;
  }

  &.active {
    .icon-badge {
      @include media-query(max, $lap-start) {
        opacity: 0;
        visibility: hidden;
      }
    }

    .svg-icon {
      &:first-of-type {
        opacity: 0;
        visibility: hidden;
      }

      &:last-of-type {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .svg-icon {
    width: 25px;
    height: 25px;

    &:last-of-type {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }

    /*@include media-query(max, $lap-start) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    width: $base-spacing + 4px;
    height: $base-spacing + 4px;

    &:last-of-type {
        opacity: 0;
        visibility: hidden;
        @include media-query(min, $lap-start) {
            position: absolute;
            right: 14px;
            top: 14px;
            height: 22px;
            width: 22px;
        }
    }*/
  }
}

.controls__skip-links {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 1em;
}

// -------------------------
// Hamburger menu on mobile
// -------------------------
.skip--site-nav {
  @include media-query(min, $lap-start) {
    display: none;
  }
}

// -------------------------
// Hamburger menu on mobile
// -------------------------
.skip--site-search {
  @include media-query(min, $desk-start) {
      display: none;
  }
}

// -------------------------
// User icon on mobile
// -------------------------
.skip--user-nav {
  /*@include media-query(min, $lap-start) {
      display: none;
  }*/
}

// ---------------------------------
// The icon badge sits with the
// basket icon and contains a number
// ---------------------------------
.icon-badge {
  background: $skip-icon-badge-bg;
  color: $white;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  vertical-align: center;
  border-radius: 50%;
  right: 0;
  top: 0;
  @include font-size(14px);
  margin: auto;
  text-align: center;

  @include media-query(min, $lap-start) {
    width: 30px;
    height: 30px;
    line-height: 32px; // offset for font padding
    top: 0;
    right: 0;
    @include font-size(18px);
  }
}

.skip--minicart {
  position: relative;

  @include media-query(min, $lap-start) {
    padding-right: 38px;
    height: 30px;
    .basket-label {
      line-height: 30px;
    }
  }

  .svg-icon {
    /*@include media-query(min, $lap-start) {
        position: absolute;
        right: calc(100% + #{$half-spacing});
        left: auto;
        height: 24px;
        width: 24px;
        top: 48%; // eyeballing position
        transform: translateY(-50%);
    }

    @include media-query(max, $lap-start) {
        &:first-child {
            width: 34px;
            height: 34px;
        }
    }

    &:last-of-type {
        @include media-query(min, $lap-start) {
            right: calc(100% + #{$half-spacing});
        }
    }*/
  }
}

.minicart-icons {
  position: absolute;
  display: block;
}

/*.basket-label {
  color: $skip-text-color;
  display: none;

  @include media-query(min, $lap-start) {
      display: inline-block;
  }
}*/
