/*================================================================================
GROUPING
================================================================================*/

/*
 * Common styles aka THE LEAGUE OF EXTRAORDINARY TAGS
 */
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
p,
blockquote,
address,
ul,
ol,
dl,
table,
fieldset,
figure,
figcaption,
details,
pre {
    margin-bottom: $half-spacing;
}
