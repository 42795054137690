//-------------------------------
// Shop by links - homepage
//-------------------------------
.shop-by {
    text-align: center;
}

.shop-by__title {
    display: block;

    @include media-query(min, $desk-start) {
        display: inline-block;
        text-align: center;
    }
}

.shop-by__link {
    margin: 0 4px;
    display: inline-block;
}
