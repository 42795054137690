@font-face {
    font-family: 'luma-icons';
    src: url('../fonts/icons/Luma-Icons.eot');
    src: url('../fonts/icons/Luma-Icons.eot?#iefix') format('embedded-opentype'),
        url('../fonts/icons/Luma-Icons.woff2') format('woff2'),
        url('../fonts/icons/Luma-Icons.woff') format('woff'),
        url('../fonts/icons/Luma-Icons.ttf') format('truetype'),
        url('../fonts/icons/Luma-Icons.svg#luma-icons') format('svg');
    font-style: normal;
}

.modals-overlay {
    z-index: 899;
}

.modal-slide,
.modal-popup {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
}

.modal-slide._show,
.modal-popup._show {
    visibility: visible;
}

.modal-slide._show .modal-inner-wrap,
.modal-popup._show .modal-inner-wrap {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-slide .modal-inner-wrap,
.modal-popup .modal-inner-wrap {
    background-color: #fff;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
    opacity: 1;
    pointer-events: auto;
}

.modal-slide {
    left: 44px;
    z-index: 900;
}

.modal-slide._show .modal-inner-wrap {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.modal-slide .modal-inner-wrap {
    height: 100%;
    overflow-y: auto;
    position: static;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform, visibility;
    transition-property: transform, visibility;
    transition-timing-function: ease-in-out;
    width: auto;
}

.modal-slide._inner-scroll .modal-inner-wrap {
    overflow-y: visible;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.modal-slide._inner-scroll .modal-header,
.modal-slide._inner-scroll .modal-footer {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}

.modal-slide._inner-scroll .modal-content {
    overflow-y: auto;
}

.modal-slide._inner-scroll .modal-footer {
    margin-top: auto;
}

.modal-slide .modal-header,
.modal-slide .modal-content,
.modal-slide .modal-footer {
    padding: 0 $base-spacing $base-spacing;
}

.modal-slide .modal-header {
    padding-bottom: 2.1rem;
    padding-top: 2.1rem;
}

.modal-popup {
    z-index: 900;
    left: 0;
    overflow-y: auto;
}

.modal-popup._show .modal-inner-wrap {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.modal-popup .modal-inner-wrap {
    margin: 5rem auto;
    max-width: 910px;
    width: 90%;
    @include media-query(min, $lap-start) {
        width: 75%;
    }
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    transition-duration: 0.2s;
    -webkit-transition-property: -webkit-transform, visibility;
    transition-property: transform, visibility;
    transition-timing-function: ease;
}

.modal-popup._inner-scroll {
    overflow-y: visible;
}

.ie10 .modal-popup._inner-scroll,
.ie9 .modal-popup._inner-scroll {
    overflow-y: auto;
}

.modal-popup._inner-scroll .modal-inner-wrap {
    max-height: 90%;
}

.ie10 .modal-popup._inner-scroll .modal-inner-wrap,
.ie9 .modal-popup._inner-scroll .modal-inner-wrap {
    max-height: none;
}

.modal-popup._inner-scroll .modal-content {
    overflow-y: auto;
}

.modal-content {
    .actions-toolbar {
    }
}

.modal-popup .modal-header,
.modal-popup .modal-content,
.modal-popup .modal-footer {
    padding-left: $base-spacing;
    padding-right: $base-spacing;

    button {
        &.action-dismiss {
            @include button-override($blue, $white);
        }

        &.action-accept {
            @include button-override($blue, $white);
        }
    }
}

.modal-popup .modal-header,
.modal-popup .modal-footer {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}

.modal-popup .modal-header {
    padding-bottom: $half-spacing;
    padding-top: $base-spacing;
}

.modal-popup .modal-footer {
    margin-top: auto;
    padding-bottom: $base-spacing;
    padding-top: $base-spacing;
}

.modal-popup .modal-footer-actions {
    text-align: right;
}

.modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
    position: absolute;
    right: 0;
    top: 0;
}

.modal-custom .action-close:focus,
.modal-popup .action-close:focus,
.modal-slide .action-close:focus,
.modal-custom .action-close:active,
.modal-popup .action-close:active,
.modal-slide .action-close:active {
    background: none;
    border: none;
}

.modal-custom .action-close:hover,
.modal-popup .action-close:hover,
.modal-slide .action-close:hover {
    background: none;
    border: none;
}

.modal-custom .action-close.disabled,
.modal-popup .action-close.disabled,
.modal-slide .action-close.disabled,
.modal-custom .action-close[disabled],
.modal-popup .action-close[disabled],
.modal-slide .action-close[disabled],
fieldset[disabled] .modal-custom .action-close,
fieldset[disabled] .modal-popup .action-close,
fieldset[disabled] .modal-slide .action-close {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

.modal-custom .action-close > span,
.modal-popup .action-close > span,
.modal-slide .action-close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.modal-custom .action-close:before,
.modal-popup .action-close:before,
.modal-slide .action-close:before {
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 14px;
    color: $blue;
    content: '\e616';
    font-family: 'luma-icons';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}

.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
    color: inherit;
}

.modal-custom .action-close:active:before,
.modal-popup .action-close:active:before,
.modal-slide .action-close:active:before {
    color: inherit;
}

.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
    color: #1a1a1a;
}

.modal-custom .action-close {
    margin: 25px;
}

.modal-popup .modal-title {
    border-bottom: 1px solid #c1c1c1;
    font-weight: 300;
    padding-bottom: 10px;
    font-size: 26px;
    margin-bottom: 0;
    min-height: 1em;
    word-wrap: break-word;
}

.modal-popup .action-close {
    padding: 20px;
}

.modal-slide .action-close {
    padding: $base-spacing;
}

.modal-slide .page-main-actions {
    margin-bottom: -12.9rem;
    margin-top: 2.1rem;
}

.modals-overlay {
    background: rgba(15, 28, 42, 0.8);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

body._has-modal-custom .modal-custom-overlay {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 899;
}

.modal-content {
    .block-title {
        @include font-size(20px);
        margin-bottom: $half-spacing;
    }
    .block-authentication {
        > .block {
            margin-bottom: $base-spacing * 1.5;
        }
        @include clearfix;
        .block-new-customer,
        .block-customer-login {
            @include media-query(min, $desk-start) {
                width: 50%;
                float: left;
            }
        }
    }
    .form-login {
        .actions-toolbar {
            display: flex;
            align-items: center;

            > * {
                margin-right: $half-spacing;
            }

            .primary {
                .action {
                    @include button;
                }
            }
        }
    }
}
