.related-products {
  padding: .75em;

  .container {
    background: $related-bg;
    max-width: 1440px;
    border-radius: 0.5em;
    padding: 1.5em;
  }

  .section-title {
    padding-top: 0;
    color: $related-title-color;
  }

  .products-wrapper {
    display: grid;
    gap: 1.5em;

    @include media-query(min, $lap-start){
      grid-template-columns: 1fr 1fr;
    }

    @include media-query(min, $desk-start){
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .product {
    background: white;
    border-radius: .5em;
    transition: all .5s;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 6px 23px #ccc;

      h3 {
        color: $link-hover;
      }
    }

    a {
      text-decoration: none;
      color: #1a1a1a;
    }

    h3 {
      font-family: $sans-title;
      color: $theme-color-four-dark;
      font-weight: bold;
      font-size: 1.1em;
      padding: 0;
      margin: 0;
    }
  }

  .product-image img {
    max-height: 300px;
    width: auto;
    margin: 0 auto;
  }

  .product-content {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em;
  }

  .split-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price-box {
      margin: 0;
    }
  }
}
