// ----------------------
// Cream banner just below
// hero on product page
// ----------------------
.product-banner {
  //background: $product-icon-bar-bg;
  //padding-top: $half-spacing;
  //padding-bottom: $half-spacing;
  padding: 0;
  margin-bottom: 0.25em;

  > .container {
    padding: 0;
    //padding-left: $base-spacing;
    //padding-right: $base-spacing;
  }

  // layout tweaks for banner-items on product page
  .banner-item {
    @include media-query(min, $lap-start) {
      justify-content: center;
      //min-height: 40px;
      margin: 0;
    }

    @include media-query(min, $desk-start) {
      width: auto;
      //margin: 0 $half-spacing;
    }
  }

  // increase size of icons on product page
  .banner-item__icon {
    width: 30px;
    height: 30px;
  }
}

// -----------------------------
// Layout tweaks to banner-items
// on product page only
// -----------------------------
.product-banner__items {
  @include list-reset;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 1;
  gap: 0.25em 0.5em;
  justify-content: space-between;

  @include media-query(min, $lap-start) {
    display: flex;
    flex-wrap: wrap;
  }
  /*@include media-query(min, $desk-start) {
    justify-content: center;
  }*/
}

// ---------------------------------
// Generic banner item (icon + text)
// ---------------------------------
.banner-item {
  //width: 50%;
  font-size: 0.9em;
  color: $theme-color-one;

  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;

  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;

  @include media-query(min, $lap-start) {
    font-size: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    font-size: 0.9em;
    margin-bottom: 0;
  }
}

// ----------------------
// The SVG icon beside the text
// ----------------------
.banner-item__icon {
  display: block;
  width: 30px;
  height: 30px;
  margin-right: $half-spacing/2;

  border-radius: 50%;
  background: $theme-color-one;
  color: $white;
  position: relative;

  .svg-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 60%;
    height: 60%;
  }
}

//--------------------------
// The reviews section in the
// product banner
//--------------------------
.banner-item {
  .product-reviews-summary {
    position: relative;
    margin-bottom: 0;

    .rating-result {
      width: 120px;
      height: 25px;

      &:before {
        width: 120px;
        height: 25px;
      }

      > span {
        &:before {
          width: 120px !important;
          height: 25px !important;
        }
      }
    }
  }

  a:not(.info-tool-tip) {
    display: contents;
    text-decoration: none;
  }

  // The loading variant
  .product-reviews-summary--loading {
    > * {
      opacity: 0.1;
    }

    &:after {
      position: absolute;
      content: ' ';
      width: 100%;
      height: 100%;
      display: block;
      background: url(../img/opc-ajax-loader.gif) no-repeat center center;
      background-size: 20px;
    }
  }

  .reviews-actions {
    margin-left: $half-spacing;
    @include font-size(16px);
  }

  @include media-query(min, $page-width) {
    .review-stars {
      .svg-icon {
        width: 28px;
        height: 28px;
      }
    }
  }
}
