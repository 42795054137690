.page-top {
    display: none;
    padding-top: 0;
    padding-bottom: 0;
    background: $page-top-bg;
    height: 38px;

    /*@include media-query(min, $lap-start) {
        display: block;
        > .container {
            display: flex;
            width: 100%;
        }
    }*/
}

.top-nav {
    display: flex;
    @include list-reset;
    color: $blue-lt;
    margin: auto;
    width: 50%;
    padding: $half-spacing/1.5 0;
}

.top-nav--left {
    .svg-icon {
        position: relative;
        top: -1px;
    }
}

.top-nav--right {
    justify-content: flex-end;

    .top-nav__item {
        margin-left: $half-spacing;
    }
}

.top-nav__item {
    white-space: nowrap;
}

.top-nav__items {
}

.top-nav__link {
    display: inline-block;
    padding: 0 2px;
    color: $page-top-link-color;

    @include hover {
        text-decoration: none;
    }

    .svg-icon {
        @include font-size(12px);
        margin-left: $half-spacing;
    }

    &.active {
        .svg-icon {
            transform: scaleY(-1);
        }
    }
}
