// ----------------------
// Account page styling
// ----------------------
.account {
    @include media-query(max, $desk-start) {
        .columns {
            display: flex;
            flex-direction: column;
        }

        .sidebar {
            width: 100%;
            margin-bottom: $base-spacing;
            position: relative;
        }

        .column.main {
            order: 2;
        }
    }

    .block {
        margin-bottom: $base-spacing;

        &:last-child {
            margin-bottom: 0;

            .box {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .block-title {
            margin-bottom: $half-spacing;
            @include block-title;
            @include media-query(min, $lap-start) {
                padding-bottom: $half-spacing;
            }
        }
    }

    .box {
        margin-bottom: $base-spacing;
    }

    .box-title {
        margin-bottom: $half-spacing;
        display: inline-block;
        color: darken($theme-color-two, 25%);
    }

    .page-title-wrapper {
        margin-bottom: $base-spacing;
    }

    .column.main .action,
    .column.main .block:not(.widget) .block-title .action.view,
    .column.main .block:not(.widget) .block-title .action.edit {
        @include button;
    }
}

// ----------------------
// Overrides
// ----------------------
.account .column.main .block:not(.widget) {
    .block-content {
        @include clearfix;
        @include media-query(min, $lap-start) {
            .box {
                &:nth-child(1) {
                    float: left;
                    width: 48%;
                }

                &:nth-child(2) {
                    width: 48%;
                    float: right;
                }
            }
        }
    }

    &.block-order-details-view {
        .block-content {
            @include media-query(min, $lap-start) {
                display: flex;
                flex-wrap: wrap;
                > * {
                    width: 50% !important;
                }
            }
        }

        .box {
            @include media-query(min, $lap-start) {
                float: none !important;
                //width: 100%;
            }
        }
    }

    .block-title > .action {
        @include font-size(16px);
        font-weight: normal;
        margin-left: $half-spacing/2;

        //margin-top: 5px;
    }
}

.block-collapsible-nav {
    width: 100%;

    .page-layout-2columns-left & {
        margin-bottom: 0;
    }

    @include media-query(min, $lap-start) {
        position: relative;
    }
}

.block-collapsible-nav-title {
    @include block-collapsible-nav-title;
    @include media-query(min, $lap-start) {
        border-radius: $double-radius $double-radius 0 0;
    }
}

.block-collapsible-nav-content {
    @include block-collapsible-nav-content;
    display: none;
    border-bottom-left-radius: $double-radius;
    border-bottom-right-radius: $double-radius;

    &.active {
        display: block;
    }

    .nav {
        .item > a {
            display: block;
            padding: $half-spacing/2 0;
        }

        .item.current {
            text-decoration: none;
            color: lighten($theme-color-one, 10%);
        }
    }
}

.table-order-items {
    .action {
        margin-right: $half-spacing;
    }
}

.table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
}

.actions-toolbar {
    .primary {
        .primary {
            //@include button-override($blue, $white);
        }
    }
}

.fieldset > .field.required > .label:after,
.fieldset > .fields > .field.required > .label:after,
.field.required > .label:after {
    content: '*';
    color: $red;
    font-size: 1.2em;
    margin: 0 0 0 5px;
}

.fieldset > .field.choice .label,
.fieldset > .fields > .field.choice .label {
    font-weight: normal;
    margin: 0;
    display: inline;
    position: relative;
    top: 1px;
}

.actions-toolbar > .secondary .action.back {
    display: none;
}

.account .legend > span {
    font-size: 1.5em;
    color: $theme-color-one;
    padding-top: 10 $base-spacing;
    font-weight: 900;
    line-height: 1;
    font-family: $heading-font-family;
    -webkit-font-smoothing: antialiased;
}

.actions-toolbar {
    .primary {
        .primary {
            @include button;
        }
    }
}

.order-details-items.ordered .table-order-items {

    .product-item-name {
        font-size: 0.8em;

        @include media-query(min, $lap-start) {
            font-size: 1em;
        }

        @include media-query(min, $desk-start) {
            font-size: 1.125em;
        }
    }

}
