.downloads {
    margin-top: -($base-spacing * 4);

    @include media-query(min, $wide-start) {
        margin-top: -($base-spacing * 9);
    }
}

.downloads__container {
    z-index: 2;
    &:before,
    &:after {
        display: none;
    }
    @include media-query(min, $desk-start) {
        display: flex;
    }
}

.downloads__item {
    display: flex;
    z-index: 3;
    position: relative;
    width: 100%;
    margin-bottom: $base-spacing;
    padding: $half-spacing;

    color: $white;
    border: 2px solid $white;
    border-radius: 10px;
    background-color: $blue;
    box-shadow: -2px 2px 6px rgba($black, 0.5);

    &:last-child {
        margin-bottom: 0;
        margin-right: 0;
    }

    @include media-query(min, $desk-start) {
        width: calc(50% - #{$base-spacing});
        margin-right: $base-spacing * 2;
        margin-bottom: 0;
    }
}

.downloads__content {
    flex-grow: 1;
    padding-right: $half-spacing;

    @include media-query(min, $palm-start) {
        padding: $half-spacing;
    }

    @include media-query(min, $desk-start) {
        padding: $half-spacing;
    }
}

.downloads__title {
    color: $white;
    @include font-size($h3-font-size);
    line-height: 1.2;
    padding-top: ($half-spacing / 2);

    @include media-query(min, $lap-start) {
        @include font-size(22px);
    }
}

.downloads__desc {
    display: none;

    @include media-query(min, $palm-start) {
        display: block;
        @include font-size($mono-font-size);
    }

    @include media-query(min, $lap-start) {
        @include font-size($base-font-size);
    }
}

.downloads__link {
    .svg-icon {
        width: 10px;
        height: 10px;
    }
}

.downloads__image {
    position: relative;
    flex-shrink: 0;
    align-self: center;
    float: right;
    width: 21%;
    padding-bottom: 28%;
    background-size: cover;
    background-position: center;
    border: 2px solid $white;
    box-shadow: -2px 2px 6px rgba($black, 0.5);
    border-radius: 10px;
    transition: all 0.2s ease-in-out;

    &:hover {
        transform-origin: center;
        transform: scale(1.1);
    }

    @include media-query(min, $lap-start) {
        width: 15%;
        padding-bottom: 20%;
    }

    @include media-query(min, $desk-start) {
        width: 30%;
        padding-bottom: 40%;
    }

    @include media-query(min, $wide-start) {
        width: 36%;
        padding-bottom: 48%;
        border: 2px solid $white;
        margin-top: -$base-spacing;
        margin-bottom: -$base-spacing;
        margin-right: -($half-spacing + 2px);
    }

    img {
        width: 100%;
    }
}

.downloads__button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -$half-spacing;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 35px;
    height: 35px;
    background-color: $theme-color-one;
    border-radius: 100%;
    border: 1px solid $white;

    @include media-query(min, $lap-start) {
        width: 50px;
        height: 50px;
        border: 2px solid $white;
    }

    .svg-icon {
        color: $white;
        width: 1.5em;
        height: 1.5em;
    }
}

.image__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
