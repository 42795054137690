// -------------------------------
// a Wrapper is a horizontal slice
// Of the entire site
// -------------------------------
.wrapper {
    clear: both;
    width: 100%;
    padding: $base-spacing 0;
    @include media-query(min, $desk-start) {
        padding: ($base-spacing * 2) 0;
    }
}

// ----------------------
//
// ----------------------
.container {
    @include clearfix();
    margin: 0 auto;
    padding-right: $half-spacing;
    padding-left: $half-spacing;
    width: auto;
    max-width: $page-width;
    list-style: none;

    @include media-query(min, $lap-start) {
        padding-right: $base-spacing;
        padding-left: $base-spacing;
    }

    @include media-query(min, ($page-width + 2 * $base-spacing)) {
        //width: $page-width;
    }
}

.wide-grid {
    @include clearfix;
    margin-left: -$half-spacing;
    @include media-query(min, $lap-start) {
        margin-left: -$base-spacing;
    }
    @include media-query(min, $desk-start) {
        margin-left: -$base-spacing * 2;
    }
}

.wide-grid--4up {
    > * {
        float: left;
        width: 50%;
        padding-left: $half-spacing;

        &:nth-child(2n + 1) {
            clear: both;
        }

        @include media-query(min, $lap-start) {
            padding-left: $base-spacing;
        }

        @include media-query(min, $desk-start) {
            &:nth-child(2n + 1) {
                clear: none;
            }
            &:nth-child(4n + 1) {
                clear: both;
            }
            width: 25%;
            padding-left: $base-spacing * 2;
        }
    }
}

.grid {
    width: 100%;
    @include clearfix;
}

.grid__cell {
    float: left;
    width: 100%;
    padding-left: $base-spacing;
}

.desk-2up {
    .grid__cell {
        @include media-query(min, $desk-start) {
            width: 50%;
        }
    }
}
.desk-3up {
    .grid__cell {
        @include media-query(min, $desk-start) {
            width: 33.333%;
        }
    }
}
