// ---------------------
// Main nav wrapper
// ---------------------

$nav-wrapper-bg: #de5e28 !default;
$nav-link-bg: #de5e28 !default;
$nav-link-text-color: white !default;
$nav-link-active-bg: white !default;
$nav-link-active-text: lighten($nav-link-bg, 5%) !default;
$nav-divider-color: #d1e6f4 !default;

$subnav-wrapper-bg: white !default;
$subnav-link-bg: white !default;
$subnav-link-text-color: $theme-color-one;
$subnav-link-active-bg: white !default;

.site-nav {
  //background: $nav-wrapper-bg;
  text-align: left;
  padding: 0;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  margin-bottom: .25em;
  //float: left;
  @include clearfix();

  position: sticky;
  top: -2px;
  z-index: 6;

  > .container {
    padding-left: 0;
    padding-right: 0;
  }

  @include media-query(min, $lap-start) {
    text-align: center;
    box-shadow: none;
    float: none;
  }

  @include media-query(min, $lap-start) {
    opacity: 1;
    visibility: visible;
  }
}

.site-nav__items {
  margin-bottom: 0;
  margin-top: 2px;
  @include list-reset;
  @include clearfix;
  display: block;
  background: $nav-link-bg;
  border-radius: 0.5em 0.5em 0 0;
  border: 0;

  &:not(:has(li.active)) {
    border-radius: 0.5em;
  }

  @include media-query(min, $lap-start) {
    letter-spacing: 1px;
    position: relative;
  }

  //@include media-query(min, $page-width) {
   // border-left: 2px solid darken($nav-link-bg, 5%);
   // border-right: 2px solid darken($nav-link-bg, 5%);
  //}
}

.site-nav__item {
  display: block;
  width: 25%;
  float: left;
  user-select: none;
  border-left: 2px solid darken($nav-link-bg, 5%);
  //background-color: $nav-link-bg;

  &:first-child {
    border-left: 0;
  }

  &:last-child {
    border-bottom: 0;
    @include media-query(min, $lap-start) {
      position: relative;
    }
  }

  &.active {
    .nav-expand {
      .svg-icon {
        transform: scaleY(-1);
      }
    }
  }

  &:not(.active) {
    .nav-link:not(.sale):hover {
      background: darken($nav-link-bg, 5%);
      border-radius: 0.5em 0 0 0.5em;
    }
  }

  .nav-link.sale {
    background-color: #d73232;
    border-radius: 0 0.5em 0.5em 0;
    overflow: hidden;

    &:active, &:focus, &:hover {
      color: white;
    }

    &:hover {
      background-color: #ed4646;
    }
  }
}

.site-nav__item--with-children {
  cursor: pointer;

  &.active {
    .nav-link {
      background: $nav-link-active-bg;
      color: $nav-link-active-text;
    }
  }
}

.subnav__item.view-all a {
  position: relative;
  font-weight: $heading-font-weight;

  &:after {
    content: '';
    position: absolute;
    top: 12px;
    margin-left: $half-spacing / 2;
    width: $half-spacing;
    height: $half-spacing;
    background-image: url('../img/caret-right.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.subnav {
  visibility: hidden;
  opacity: 0;
  height: 0;
  left: 0;
  width: 100%;
  background: $subnav-wrapper-bg;
  position: absolute;
  top: 100%;

  @include media-query(min, $lap-start) {
    &.singular {
      left: initial;
      width: auto;
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;
    height: auto;
  }
}

.subnav__content {
  @include container;
  margin: 0 auto;
  text-align: center;
  color: $subnav-link-text-color;
  background: $subnav-wrapper-bg;
  padding: $half-spacing;
  border: solid 2px $nav-link-bg;
  border-top: none;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
  margin: 0 -2px;

  max-height: calc(95vh - 75px);
  overflow-y: auto;

  @include media-query(min, $lap-start) {
    padding: $half-spacing;
  }

  @include media-query(min, $desk-start) {
    padding: $base-spacing;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.subnav__items {
  @include list-reset;
  @include clearfix;
  text-align: left;

  // On palm, only show 3 links in level 2 nav
  /*@include media-query(max, $lap-start) {
    &.level2 {
      .subnav__item {
        &:nth-child(n + 4) {
          display: none;
        }

        &:last-child {
          display: block;
        }
      }
    }
  }*/
}

.subnav__item--alt {
  &:nth-child(2) {
    border-top: solid 2px darken($nav-divider-color, 5%);
    border-bottom: solid 2px darken($nav-divider-color, 5%);
  }

  @include media-query(min, $lap-start) {
    width: 33.33333%;
    float: left;
    &:nth-child(2),
    &:nth-child(3) {
      padding-left: $half-spacing;
    }

    &:nth-child(2) {
      border: none;
      border-right: solid 2px darken($nav-divider-color, 5%);
      border-left: solid 2px darken($nav-divider-color, 5%);
    }
  }

  @include media-query(min, $desk-start) {
    &:nth-child(2),
    &:nth-child(3) {
      padding-left: $base-spacing;
    }
  }

  .subnav__link {
    padding-left: $base-spacing;

    @include media-query(min, $lap-start) {
      padding-left: $half-spacing;
    }
  }

  .subnav__link--heading {
    padding-left: $half-spacing;
  }
}

.subnav__link {
  display: block;
  //padding: $half-spacing/2 $half-spacing;
  position: relative;
  color: $subnav-link-text-color;
  text-decoration: none;
  font-weight: normal;
  padding: 5px;
  line-height: 1.1em;

  .has-mouse & {
    &:hover {
      text-decoration: underline;
    }
  }
}

.subnav__link--heading {
  font-weight: $heading-font-weight;
  @include media-query(min, $lap-start) {
    @include font-size(16px);
  }

  @include media-query(min, $desk-start) {
    @include font-size(18px);
  }
}

.nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0;
  position: relative;
  text-decoration: none;
  text-align: center;
  color: $nav-link-text-color;
  font-family: $sans-title;
  height: 100%;
  min-height: 40px;
  //@include font-size(12px);
  font-size: .8em;
  @include antialias;

  .has-mouse & {
    &:hover {
      //background: darken($nav-link-bg, 5%);
      //color: $nav-link-text-color;
    }
  }

  .active & {
    .has-mouse & {
      &:hover {
        //background: $nav-link-active-bg;
        //color: darken($nav-link-bg, 5%);
      }
    }
  }

  @include media-query(min, 540px) {
    flex-direction: row;
  }

  @include media-query(min, 540px) {
    font-size: 0.85em
  }

  @include media-query(max, $lap-start) {
    cursor: pointer;
  }

  @include media-query(min, $lap-start) {
    //@include font-size(16px);
    font-size: 0.95em;
    text-align: center;

    border: 0;
    white-space: nowrap;
  }

  @include media-query(max, $desk-start) {
    //padding-top: 10px;
    //line-height: 1.2;
  }

  @include media-query(min, $desk-start) {
    font-size: 1.05em;
  }
}

// ----------------------
// Down arrow when a nav
// item has children
// ----------------------
.nav-expand {
  //height: 10px;
  //text-align: center;
  //position: absolute;
  //left: 50%;
  //transform: translateX(-50%);
  //bottom: $half-spacing;
  display: contents;

  @include media-query(max, 540px) {
    .svg-icon {
      position: absolute;
      bottom: 1px;
    }
  }

  @include media-query(min, 540px) {
    .svg-icon {
      width: 20px;
      height: 20px;
    }
  }

  @include media-query(min, $lap-start) {
    .svg-icon {
      width: 20px;
      height: 20px;
    }
  }

  @include media-query(min, $desk-start) {
    //width: 30px;
    //height: 20px;
    //position: relative;
    //left: $half-spacing;
    //bottom: auto;
    //top: auto;
    //display: inline-block;

    .svg-icon {
      //position: absolute;
      //display: block;
      //top: 0;
      //left: 0;
      width: 30px;
      height: 30px;
    }
  }
}

body {
  overflow: initial;
}

#main-nav {
  position: sticky;
  top: -2px;
  z-index: 4;
}
