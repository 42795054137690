.prescription {
    background-color: $pale-blue;
    text-align: center;
}

.prescription__container {
    padding: $base-spacing 0;
    background-color: $white;
    border-radius: 20px;
    border: 2px solid #fcf7f2;
    width: calc(100% - #{$base-spacing});
    max-width: 1160px;

    @include media-query(min, $lap-start) {
        padding: ($base-spacing * 2) 0;
        width: calc(100% - (#{$base-spacing} * 2));
    }
}

.prescription__title {
    @include font-size($h1-font-size);
    color: $blue;
    text-align: center;

    @include media-query(min, $desk-start) {
        padding-top: 0;
    }
}

.prescription__desc {
    @include font-size($lede-font-size);
    margin-bottom: $base-spacing;
}

.prescription__steps {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-left: 0;
    margin-bottom: $base-spacing;
    width: 100%;
}

.prescription__step {
    position: relative;
    margin-right: ($base-spacing * 2);
    text-align: center;
    font-weight: bold;

    @include media-query(min, $lap-start) {
        margin-right: ($double-spacing * 2);
    }

    &:first-child {
        &:after {
            right: -40px;
            @include media-query(min, $lap-start) {
                right: -61px;
            }
        }
    }

    &:nth-child(2) {
        &:after {
            right: -30px;
            @include media-query(min, $lap-start) {
                right: -48px;
            }
        }
    }

    &:last-child {
        margin-right: 0;

        &:after {
            display: none;
        }
    }

    &:after {
        content: '';
        position: absolute;
        top: $base-spacing;
        width: $base-spacing;
        height: $base-spacing;

        background-image: url('../img/caret-right.svg');
        background-repeat: no-repeat;
        background-size: contain;
        &:hover::after {
            background-color: $link-hover;
        }

        @include media-query(min, $lap-start) {
            top: $base-spacing + $half-spacing;
        }
    }
}

.prescription__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto $half-spacing;
    width: ($base-spacing * 3);
    height: ($base-spacing * 3);
    border-radius: 100%;
    color: $blue-lt;
    background-color: $pale-blue;

    @include media-query(min, $lap-start) {
        width: ($base-spacing * 4);
        height: ($base-spacing * 4);
    }

    .svg-icon {
        width: 2em;
        height: 2em;
        @include media-query(min, $lap-start) {
            width: 3em;
            height: 3em;
        }
    }
}

.prescription__icon-text {
    font-size: $small-font-size;
    text-align: left;
    white-space: nowrap;
    text-align: center;

    @include media-query(min, $palm-start) {
        font-size: $base-font-size;
    }
}

.prescription__link {
    font-weight: bold;
    margin-top: $base-spacing;

    .svg-icon {
        width: $half-spacing;
        height: $half-spacing;
    }
}
