.hero {
    position: relative;
    width: 100%;
    max-height: 900px;
    overflow: hidden;
    margin-bottom: $base-spacing;
}

.hero__spacer {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    background-size: cover;
    background-position: center;

    @include media-query(min, $palm-start) {
        padding-bottom: 75%;
    }

    @include media-query(min, 1700px) {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0 0;
    }
}

.hero__border {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
}

.hero__border--top {
    top: 0;
}

.hero__border--bottom {
    top: 100%;
    transform: translateY(-100%);
}

.hero__img {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.hero__container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
}

.hero__content {
    color: $blue;
    text-shadow: 1px 1px 2px $white, -1px -1px 2px $white;

    @include media-query-bracket($palm-start, $desk-start) {
        max-width: 600px;
    }

    @include media-query(min, $desk-start) {
        padding-left: calc(50% + #{$half-spacing});
    }

    @include media-query(min, $wide-start) {
        padding-left: calc(50% + #{$base-spacing});
    }
}

.hero__title {
    color: $blue;
    @include font-size($h2-font-size);
    line-height: 1.2;

    @include media-query(min, $palm-start) {
        @include font-size($h1-font-size);
    }

    @include media-query(min, $wide-start) {
        @include font-size($large-font-size);
    }
}

.hero__desc {
    @include font-size(20px);

    @include media-query(min, $desk-start) {
        @include font-size($h2-font-size);
    }
}

.logo--hero {
    display: none;
    width: 100%;

    @include media-query(min, 750px) {
        display: block;
        margin-top: $double-spacing * 2;
    }
    @include media-query(min, 1700px) {
        margin-top: $double-spacing * 3;
    }

    img {
        width: 350px;
        margin: 0 auto;

        @include media-query(min, $wide-start) {
            width: 450px;
        }
    }

    .hero__logo-subtitle {
        color: $blue;
        @include font-size($h2-font-size);
        text-align: center;

        @include media-query(min, $wide-start) {
            @include font-size($h1-font-size);
        }
    }
}
