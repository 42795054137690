.minicart-wrapper {
    .checkout {
        display: inline-block;
        border: 1px solid $theme-color-one;
        padding: ($half-spacing - 1px) ($base-spacing - 1px);
        width: auto;
        vertical-align: middle;
        background: $theme-color-one;
        color: $white;
        font-family: $sans;
        font-weight: bold;
        @include font-size($base-font-size);
        line-height: 1;
        text-align: center;
        cursor: pointer;
        border-radius: $button-radius;
        transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
        text-decoration: none;
        box-shadow: inset 0px -2px 0px 0px darken($theme-color-one, 10%);

        &:hover,
        &:focus {
            border-color: lighten($theme-color-one, 10%);
            background: lighten($theme-color-one, 10%);
            color: $white;
        }

        &:active {
            border-color: lighten($theme-color-one, 20%);
            background: lighten($theme-color-one, 20%);
        }
    }
}
