/*================================================================================
FORMS
================================================================================*/

label,
input,
textarea,
button,
select,
option {
    cursor: pointer;
}

form {
    > :last-child {
        // margin-bottom: 0;
    }
}

fieldset {
    margin-bottom: $base-spacing;
    border: none;
}

legend {
    margin-bottom: $half-spacing;
    padding-top: $half-spacing;
}

.field {
    @include clearfix();
    margin-bottom: $half-spacing;
    list-style: none;
}

.options {
    list-style: none;
    margin: 0;
}

label {
    display: inline;
    margin-bottom: $half-spacing / 2;
    font-weight: bold;

    &.check {
        position: relative;
        padding-left: $base-spacing;
        input {
            position: absolute;
            left: 0;
            top: $half-spacing/3;
        }
        font-weight: normal;
    }

    .small {
        font-weight: normal;
    }
}

.options label,
label.check {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    white-space: nowrap;
}

abbr[title='Required'],
abbr[title='required'] {
    border: 0;
    color: $failure;
    font-weight: bold;
    @include font-size($base-font-size);
    line-height: 0;
}

.input-box,
.input-combined,
.input-search,
.input-overlay {
    display: block;

    .caption {
        display: block;
        margin-bottom: 0;
    }
}

.form__actions {
    margin-top: $base-spacing;
    margin-bottom: $half-spacing;
}

/*
 * Basic Inputs
 */
button,
input,
select,
textarea {
    outline: none;
    vertical-align: middle;
    line-height: $base-line-height;
}

.input-text,
select,
textarea {
    background: $white;
    border: 1px solid $silver;
    padding: $half-spacing / 2;
    max-width: 100%;
    width: 100%;
    color: inherit;
    border-radius: $base-radius;
    box-shadow: inset 0 1px 0 rgba(black, 0.1);

    &:focus {
        outline: none;
        border-color: $link;
        box-shadow: inset 0 0 3px rgba($link, 0.5);
    }

    @include media-query(palm) {
        @if $border-box == true {
            width: 100%;
        } @else {
            width: 90%;
        }
    }
}

.input-text {
    height: ($base-font-size) + ($half-spacing * 2);
}

select.input-text {
    height: ($base-font-size * 1) + ($half-spacing * 2);
}

textarea,
textarea.input-text {
    @if $border-box == true {
        min-width: 100%;
    } @else {
        min-width: 90%;
    }
    height: auto;
}

input[type='radio'],
input[type='checkbox'] {
    margin-right: $half-spacing / 2;
}

[disabled] {
    background: $smoke;
    cursor: default;
}

/**
 * Newsletter Checkbox
 */
.form-login .fieldset .newsletter-field {
    margin-bottom: -20px;
    @include media-query(min, $checkout-tablet-start) {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}
.newsletter-input {
    box-shadow: none;
    width: 1em;
    height: auto;
}

/**
 * Special Inputs
 */

.input--full {
    @if $border-box == true {
        width: 100%;
        max-width: 100%;
    } @else {
        width: 90%;
        max-width: 90%;
    }
}

.input--small {
    width: 8em !important;
}

.options {
    list-style: none;
    margin: 0;

    label {
        display: inline-block;
        margin-bottom: 0;
        font-weight: normal;
    }
}

.input-combined {
    * + * {
        margin-left: $half-spacing;
    }
    .caption {
        margin-left: 0;
    }

    @include media-query(palm) {
        * + * {
            margin: $half-spacing 0 0;
        }
        .input--small {
            display: block;
        }
    }
}

.input-search {
    position: relative;
    margin: 0;
    display: flex;
    width: 100%;

    .input-text {
        flex-grow: 1;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    input[type='submit'],
    .button {
        box-sizing: content-box;
        top: 0;
        border: 0;
        //color: $link;
        line-height: 1;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &.icon {
            flex-shrink: 0;
            padding: 0;
            width: $button-height;
            height: $button-height;
            right: 0;
        }

        &:hover,
        &:focus {
            //color: $link-hover;
        }
    }
}

.input--rounded {
    position: relative;

    .search-icon {
        position: absolute;
        top: 8px;
        left: 12px;

        .svg-icon {
            height: 22px;
            width: 22px;
            color: $sky;
        }
    }

    .input-text {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        padding-left: $base-spacing * 2;
        height: 40px;
        height: 40px;
    }

    .button {
        color: $white;
        padding-left: 0;
        padding-right: 0;
        width: 100px;
        @include media-query(min, $lap-start) {
            width: 120px;
        }
    }
}

.lt-ie8 .input-search input[type='submit'] {
    $size: ($base-font-size * $base-line-height) + $base-spacing;
    width: $size;
    height: $size;
}

.input-overlay {
    position: relative;
    display: flex;

    .input-text {
        flex-grow: 1;
        text-align: center;
        border-radius: 0;
    }

    .overlay {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .overlay--left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .button {
        flex-shrink: 0;
        height: $button-height;
        width: $button-height;
        padding: 0;
    }

    + .button--full {
        margin: $half-spacing 0 0;
    }
}

.input-qty {
    .button {
    }

    [data-action='subtract'] {
    }

    [data-action='add'] {
    }

    .input-text {
        text-align: center;
        max-width: 3em;
    }
}

/*
 * Errors
 */
.error {
    label {
        color: $failure;
    }
    .input-text,
    select,
    textarea {
        border-color: $failure;
        border-radius: $base-radius $base-radius 0 0;
    }
}

.error__message,
.validation-advice {
    display: block;
    padding-top: $half-spacing/2;
    color: $failure;
}

/**
 * Stacked Fields
 */
.form--stacked {
    .input-box {
        max-width: 24em;
    }
    .input-text {
        @if $border-box == true {
            width: 100%;
        } @else {
            width: 90%;
        }
    }

    @include media-query(min, $lap-start) {
        .input-combined,
        .input-search,
        .input-overlay {
            max-width: none;
        }
        .input-combined {
            .input-text {
                width: auto;
            }
        }
    }
}

/**
 * Aligned Fields
 */
.form--aligned {
    @include media-query(min, $lap-start) {
        label {
            float: left;
            margin-bottom: 0;
            padding-top: $half-spacing/2;
            width: 24%;
        }
        label.check,
        .options label {
            float: none;
            width: auto;
            padding-top: 0;
        }

        .input-box,
        .input-combined,
        .input-search,
        .input-overlay {
            margin-left: 25%;
            max-width: 24em;
        }
        .input-text {
            @if $border-box == true {
                width: 100%;
            } @else {
                width: 90%;
            }
        }
        .input-combined,
        .input-search,
        .input-overlay {
            max-width: none;
        }
        .input-combined {
            .input-text {
                width: auto;
            }
        }

        .form__actions {
            padding-left: 25%;
        }
        .options {
            padding-top: $half-spacing/2;
        }
    }
}

.lt-ie8 .form--aligned {
    .input-box {
        margin-left: 0;
    }
}

.input-text--square {
    border-radius: 0;
    display: inline-block;
}

@mixin page-form {
    @include media-query(min, $lap-start) {
        max-width: 25em;
        padding: $base-spacing;
        border: 1px solid $silver-lt;
        padding: $base-spacing * 1.5;
        //box-shadow: 0 0 3px rgba(0,0,0,.1);
        margin: 0 auto;
    }
}

.form {
    max-width: $form-max-width;
}

.page-form {
    @include page-form;
}

@mixin form--stacked {
    .field {
        max-width: 24em;
    }
    .input-box {
        max-width: 24em;
    }
    .input-text {
        width: 100%;
    }

    @include media-query(min, $lap-start) {
        .input-combined,
        .input-search,
        .input-overlay {
            max-width: none;
        }
        .input-combined {
            .input-text {
                width: auto;
            }
        }
    }
}

/**
 * Stacked Fields
 */
.form--stacked {
    @include form--stacked;
}

.mage-error {
    &[generated='true'] {
        color: $failure;
    }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.newsletter-subscribe-notice {
    font-style: italic;
    font-size: $base-font-size - 2;
    padding: 10px 20px;
}
