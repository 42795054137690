.site-usp {
    display: block;
    clear: both;
    background: $usp-bg;
}

.site-usp__container {
    padding: 0;
}

.site-usp__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
}

.site-usp__item {
    width: 25%;

    //@include media-query (min, $lap-start) {
    //    width: 25%;
    //}

    &:nth-child(2) {
        //    display: none;
        //
        //    @include media-query (min, $lap-start) {
        //        display: inline-block;
        //    }

        .svg-icon {
            width: 40px;
            height: 24px;
        }
    }
}

.site-usp__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: $half-spacing 0;
    text-align: center;
    text-decoration: none;
    color: $usp-text-color;

    //&:hover {
    //    cursor: pointer;
    //    text-decoration: underline;
    //    color: $link-hover;
    //}
    .svg-icon {
        display: inline-block;
        color: $usp-text-color;
        width: 24px;
        height: 24px;
        padding-right: 5px;
    }
}

.site-usp__meta {
    display: block;
    @include font-size($micro-font-size);
    line-height: 1.2;

    @include media-query(min, $lap-start) {
        @include font-size($small-font-size);

        br {
            display: none;
        }
    }

    @include media-query(min, $desk-start) {
        @include font-size($base-font-size);
        line-height: $base-line-height;
        display: inline-block;
    }
}
