/*========================================
LISTING
========================================*/

.listing {
    &--grid {
    }
    &--list {
    }
}

.listing-item {
    .image {
        img {
            display: block;
        }
    }

    .details {
    }

    .title {
    }

    .ratings {
    }

    .description {
    }

    .price-box {
    }

    .actions {
    }

    .out-of-stock {
        font-weight: bold;
        color: red;
    }

    .add-to {
        margin-top: $half-spacing;

        li {
        }

        a {
        }
    }

    .listing--grid & {
        .description,
        .actions {
            display: none;
        }
    }

    .listing--list & {
        margin-bottom: $half-spacing;

        .image {
            float: left;
            margin-right: $base-spacing;
        }

        .details {
            overflow: hidden;
        }
    }
}
