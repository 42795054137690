.swatch-opt {
    .mage-error {
        position: relative;
        margin-bottom: $base-spacing;
        padding: $half-spacing;
        line-height: $base-line-height;
        background: rgba($failure, 0.1);
        border: 1px solid rgba($failure, 0.3);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            transform: translateY(-100%);
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $failure;
        }
    }
}

.swatch-attribute {
    margin-bottom: $half-spacing / 2;
    position: relative;
}

.swatch-attribute-options {
    outline: 0;
    @include clearfix();
}

// ------------------------------
// Swatches on the product page
// ------------------------------
.swatch-option {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    float: left;
    margin-right: $half-spacing;
    margin-bottom: $half-spacing;
    border: 1px solid $grey-lt;
    border-radius: $base-radius;
    @include unselectable;

    &:hover {
        cursor: pointer;
    }

    &.selected {
        position: relative;
        border-color: $blue-dk;
        box-shadow: 0 0 0 1px $blue-dk inset;
    }

    &.text {
        width: auto;
        padding-left: $base-spacing;
        padding-right: $base-spacing;
    }

    &.color,
    &.image {
        position: relative;
        box-shadow: 0 0 0 2px $white inset;
        width: 40px !important;
        height: 40px !important;

        &.selected {
            border: 0;
            box-shadow: 0 0 0 2px $blue-dk inset, 0 0 0 4px $white inset;
        }

        &.disabled {
            border: 0;

            &:before {
                content: ' ';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: $white;
                opacity: 0.8;
                z-index: 2;
            }
        }
    }

    &.image {
        background-size: 32px 32px !important;

        &.disabled {
            &:before {
                content: ' ';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(#e6e6e6, 0.95);
                border-radius: $base-radius;
                z-index: 1;
                display: block;
            }
        }
    }

    &.disabled {
        cursor: not-allowed;
        background: $silver-lt;
        position: relative;

        &:after {
            content: 'Not Available';
            position: absolute;
            top: -100%;
            left: 0;
            white-space: nowrap;
            display: none;
            max-width: 140px;
            max-height: 100%;
            min-height: $base-spacing;
            line-height: $base-spacing;
            min-width: $base-spacing;
            padding: 5px;
            background: $white;
            border: 1px solid $grey-lt;
        }

        .has-mouse & {
            &:hover {
                &:after {
                    display: block;
                }
            }
        }
    }
}

// ------------------------------
// The text + ruler link at the
// side of the size swatches
// ------------------------------
.swatch-option-extra {
    display: block;
    float: left;
    height: $base-spacing * 2;
    line-height: $base-spacing * 2;
    margin-left: $half-spacing;

    .svg-icon {
        margin-right: 7px;
        @include font-size(30px);
        position: relative;
        top: -1px;
    }
}

// ----------------------
// Hover-over tooltip on
// product page swatches
// ----------------------
.swatch-option-tooltip {
    .has-mouse & {
        max-width: 140px;
        max-height: 100%;
        min-height: $base-spacing;
        min-width: $base-spacing;
        position: absolute;
        padding: 5px;
        background: $white;
        border: 1px solid $grey-lt;
        display: none;
        z-index: 999;
        text-align: center;

        .image {
            display: block;
            height: 130px;
            width: 130px;
            /* margin: 0 0 5px; */
            margin: 0 auto;
        }

        .title {
            max-width: 140px;
            min-height: $base-spacing;
            max-height: 200px;
            color: rgb(40, 40, 40);
            text-align: center;
            display: block;
            overflow: hidden;
        }
    }
}

.swatch-attribute-label {
    font-weight: bold;
    margin-right: $half-spacing;
}

// ------------------------------------------
// Product swatches within the product grid
// ------------------------------------------
.swatches {
    position: absolute;
    bottom: 0;
    padding: 2px;
    @include list-reset();
    @include clearfix;
    @include media-query(min, $lap-start) {
        margin-left: -2px;
    }
}

// ----------------------
// Individual swatch
// ----------------------
.swatches__swatch {
    width: 20px;
    height: 20px;
    padding: 0;
    margin-right: 2px;
    margin-bottom: 0;
    float: left;

    &:nth-child(n + 6) {
        display: none;
    }

    @include media-query(min, $lap-start) {
        height: auto;
        float: left;
        &:nth-child(n + 6) {
            display: block;
        }
    }
}

// ----------------------
// Swatch image
// ----------------------
.swatch__hex,
.swatch__img {
    display: block;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
}

.swatch__img[alt]:-moz-broken {
    display: none;
}

.swatches__view-more {
    display: block;
    float: left;
    padding: 0 5px 0 3px;
    line-height: 20px;
    @include font-size(12px);
    font-weight: bold;

    @include media-query(min, $lap-start) {
        display: none;
    }
}
