form.minisearch {
    max-width: none;
    margin: 0 auto;
}

.site-search {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 7;
    background: $white;
    width: 100%;
    //box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    padding: $half-spacing;

    /*@include media-query(min, $lap-start) {
        position: relative;
        opacity: 1;
        visibility: visible;
        box-shadow: 0 0 0 0;
        background: none;
        padding-left: $base-spacing;
        padding-right: $base-spacing;
    }*/

    @include media-query(min, $desk-start) {
        position: absolute;
        top: 16px;
        width: 50%;
        left: calc(50% + #{$base-spacing * 2}); // offset for logo + visual
        transform: translateX(-50%);
        max-width: 560px;
        padding: 0;

        opacity: 1;
        visibility: visible;
    }

    .input-search {
        .button {
            flex-shrink: 0;
            font-family: Museo Sans Rounded, Helvetica, Arial, sans-serif;
        }
    }

    .site-search--open & {
        opacity: 1;
        visibility: visible;
    }
}

.search-autocomplete {
    position: fixed;
    top: 40px;
    left: -50%;
    width: 180% !important;

    @media only screen and (max-width: 900px) {
        left: 0;
        width: 100% !important;
    }

    .smile-elasticsuite-autocomplete-result {
        padding: 10px;
        background: #ffffff;
        box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
        border: 2px solid $theme-color-one;
        border-top: none;
        border-radius: 0 0 8px 8px;
    }

    .autocomplete-list {
        dd {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            overflow: hidden;
            margin-bottom: 5px;
            color: #074369;

            &.selected {
                cursor: pointer;
                background: #d1e6f4;
                text-decoration: underline;
            }

            img {
                margin: 5px 5px 0;
            }

            .product-image-box {
                float: left;
                width: 10%;
            }

            .product-item {
                float: left;
                width: 90%;
            }

            .product-primary {
                display: flex;
                width: 70%;
                align-items: center;
            }

            .product-secondary {
                width: 30%;
            }

            .price-box {
                margin-bottom: 0;
            }

            .f-fix {
                display: flex;
            }
        }

        .autocomplete-list-title {
            color: #074369;
            font-weight: 900;
            font-size: 1.125em;

            &.title-term ~ dd {
                display: inline;
                margin: 0;
            }
        }
    }
}
