/**
 * SITE FOOTER
 */

.site-footer {
  color: $footer-text-color;
  background: $footer-bg;

  max-width: 1200px;
  margin: 0 auto;
  border-radius: 0.5em 0.5em 0 0;
  padding: 1em 1em 0 1em;

  > .container {
    @include media-query(min, $desk-start) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.footer-offering {
  margin-left: 0;
  list-style: none;
  text-align: center;
  border-radius: $base-spacing;
  border: 2px solid $footer-border-color;
  font-family: $sans-title;
  letter-spacing: 1px;

  @include media-query(min, $lap-start) {
    display: flex;
    margin: 0 0 $base-spacing 0;
    @include font-size($base-spacing);
  }

  > * {
    padding: $half-spacing;

    &:nth-child(1),
    &:nth-child(2) {
      border-color: $footer-border-color;
      border-bottom: 2px solid $footer-border-color;
    }

    @include media-query(min, $lap-start) {
      width: 33.33333%;
      position: relative;
      padding: $half-spacing;
      padding-top: $half-spacing * 1.3;
      padding-bottom: $half-spacing * 1.3;

      &:nth-child(2) {
        border-left: 2px solid;
        border-right: 2px solid;
        border-color: $footer-border-color;
      }

      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: 0;
      }
    }
  }

  a {
    color: $footer-text-color;
    cursor: pointer;
    border-radius: $base-spacing;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &:active {
      //background: $blue-dk;
    }
  }

  .svg-icon {
    height: 15px;
    width: 15px;

    @include media-query(min, $lap-start) {
      height: 24px;
      width: 24px;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }
}

.footer-nav {
  list-style: none;
  margin: $base-spacing auto;
  text-align: center;

  display: flex;
  width: 100%;
  justify-content: center;

  @include media-query(max, 700px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .footer-nav__item:first-of-type {
      grid-column: span 2;
    }
  }
}

.footer-nav__item {
  position: relative;

  @include media-query(min, $lap-start) {
    &:after {
      content: ' ';
      height: 10px;
      width: 1px;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background: white;
      opacity: 0;
    }
  }

  &:first-child {
    &:after {
      opacity: 1;
    }

    @include media-query(min, $lap-start) {
      margin-right: $base-spacing;
      &:after {
        right: -12px;
      }
    }
  }

  &:last-child {
    &:after {
      opacity: 1;
      right: auto;
      left: -8px;
    }

    @include media-query(min, $lap-start) {
      margin-left: $base-spacing;
    }
  }
}

.footer-nav__link {
  display: block;
  color: $footer-text-color;
  padding: $half-spacing $base-spacing;
  border-radius: $base-spacing;
  text-decoration: none;
  cursor: pointer;

  @include media-query(min, $lap-start) {
    padding-left: $half-spacing;
    padding-right: $half-spacing;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
    color: $footer-text-color;
  }
}

.footer-divide {
  color: $footer-border-color;
}

.footer-bar {
  background: $footer-bar-bg;
  height: $base-spacing;
  width: 100%;
  display: block;
  max-width: 1200px;
  margin: 0 auto;
}

.copyright-info {
  margin-bottom: 0;

  display: block;
  color: $footer-copyright-text-color;
  padding: $half-spacing $base-spacing;
  border-radius: $base-spacing;
  text-decoration: none;

  @include media-query(min, $lap-start) {
    padding-left: $half-spacing;
    padding-right: $half-spacing;
  }
}
