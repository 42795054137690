.social {
    display: flex;
    justify-content: center;
}

.social__icon {
    display: block;
    width: 40px;
    height: 40px;
    background: $blue-lt;
    color: $white;
    border-radius: 100%;
    position: relative;
    margin-left: 4px;
    margin-right: 4px;

    .has-mouse & {
        &:hover,
        &:focus {
            color: $white;
            background: darken($blue-lt, 5%);
        }
    }

    .svg-icon {
        @include font-size(20px);
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
    }
}
