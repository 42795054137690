/*========================================
QUOTES
========================================*/

blockquote {
    margin-left: $base-spacing;
    margin-right: $base-spacing;
}
blockquote + figcaption:before {
    content: '-';
}
