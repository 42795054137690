/*========================================
ICONS
========================================*/
svg {
    display: inline-block;
    fill: currentColor !important;
    vertical-align: middle;
    line-height: 1em;

    &.svg-icon {
        height: 1em;
        width: 1em;
    }
}
